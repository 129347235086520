import './App.sass';
import { useEffect, useState } from "react";
import DIN276AssetProperties from "./object-properties/DIN276AssetProperties";
import { userAPI } from "./UserAPI";
import { Dropdown } from '@fluentui/react/lib/Dropdown';

export default function TestDIN276() {
    const [metadata, setMetadata] = useState([]);
    const [selectedType, setSelectedType] = useState() as any;
    const [asset, setAsset] = useState({}) as any;

    useEffect(() => {

        const call = async () => {
            const newMetadata = await userAPI.getDIN276Metadata();

            //console.log('Metadata >>>', newMetadata);
            setMetadata(newMetadata);
        };

        call();
    }, []);

    return <div className="paddingXL">
        <div>
            <Dropdown
                label="DIN 276 Typen"
                placeholder="Bitte auswählen ..."
                options={metadata
                    .sort((x: any, y: any) => {
                        const xs = x.code.split('.');
                        const ys = y.code.split('.');

                        if (Number(xs[0]) !== Number(ys[0])) {
                            return Number(xs[0]) - Number(ys[0]);
                        } else {
                            return Number(xs[1]) - Number(ys[1]);
                        }
                    }).map((type: any) => {
                        console.log('Code >>>', type.code);

                        return {
                            key: `${type.code}`,
                            text: `${type.code} (${type.name})`,
                        }
                    })}
                selectedKey={selectedType ? selectedType.code : undefined}
                onChange={(e: any, item: any) => setSelectedType(metadata.find((x: any) => x.code === item.key))}
                styles={{
                    dropdown: { width: 400 },
                }}
            />
        </div>
        {selectedType
            ?
            <div className='marginTopL displayFlex gapM'>
                <DIN276AssetProperties readonly type={selectedType.code} properties={asset} setProperties={setAsset}></DIN276AssetProperties>
                <DIN276AssetProperties type={selectedType.code} properties={asset} setProperties={setAsset}></DIN276AssetProperties>
            </div>
            :
            <></>
        }
    </div>;
};