import '../App.sass';
import { CSSProperties, FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { ActionButton, Checkbox, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, FontIcon, Label, mergeStyles, Pivot, PivotItem, Position, PrimaryButton, Rating, SpinButton, TextField, Toggle } from '@fluentui/react';
import { userAPI } from '../UserAPI';
import Section from '../components/Section';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { dataTableStyles } from '../styles';
import SignatureCanvas from 'react-signature-canvas'
import ReactApexChart from 'react-apexcharts';
import Metric from '../components/Metric';
import { useCustomerTheme } from '../Theme';
import dayjs from "dayjs";
import { DocumentSlot } from '../components/DocumentSlot';
import { authenticationAtom } from '../State';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import update from 'immutability-helper'
import { Checklist, EntityQuery, is, or, Query, Role, RoleType } from '@the-real-insight/tri-model';

interface Properties {
    processError: (error: any) => void;
}

const style: CSSProperties = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    backgroundColor: 'white',
    cursor: 'move',
}

const selectedStyle: CSSProperties = {
    border: '1px solid gray',
    padding: '0.5rem 1rem',
    backgroundColor: '#F4F4F4',
    cursor: 'move',
}

export interface CardProps {
    id: string
    text: string,
    type: string,
    moveField: (id: string, to: number) => void,
    findField: (id: string) => { index: number }
    select: (id: string) => void
}

export interface CheckItemTypeProps {
    type: string,
    text: string,
}

const CheckItemType: FC<CheckItemTypeProps> = memo(function Card({
    type,
    text,
}) {
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.TYPE,
            item: { type },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
            },
        }),
        [],
    );
    const opacity = isDragging ? 0 : 1

    return (
        <div ref={(node) => drag(node)} className="width400 marginTopS" style={{ ...style, opacity }}>
            <div className="displayFlex">
                <div className="flexGrow1 displayFlex">
                    {text}
                </div>
                <div className="width150 displayFlex justifyContentStart">
                    {(() => {
                        switch (type) {
                            case 'text': return <div>
                                <TextField multiline disabled value={'Das ist ein längerer Text'}></TextField>
                            </div>;
                            case 'integer': return <div>
                                <TextField disabled value={'1234'}></TextField>
                            </div>;
                            case 'decimal': return <div>
                                <TextField disabled value={'123.567'}></TextField>
                            </div>;
                            case 'OKNotOK': return <div>
                                <Toggle onText="OK" offText="Not OK" onChange={() => { }} />
                            </div>;
                            case 'DoneNotDone': return <div>
                                <Toggle onText="Done" offText="Not Done" onChange={() => { }} />
                            </div>;
                            case 'rating': return <div>
                                <Rating
                                    readOnly
                                    defaultRating={3}
                                    allowZeroStars
                                    max={5}
                                />
                            </div>;
                        }
                    })()}
                </div>
            </div>
        </div>
    )
});

interface DropZoneProperties {
    onDrop: (item: any) => void;
}

export default function ProcessManagementPanel(properties: Properties) {
    const { t } = useTranslation();
    const { theme } = useCustomerTheme();
    const navigate = useNavigate();
    const [colors] = useState([theme.secondary, theme.tertiary, theme.primary, theme.secondaryLight, theme.tertiaryLight, theme.primaryLight]);
    const [tab, setTab] = useState('productData') as any;
    const [tasks, setTasks] = useState([]) as any;
    const [processDefinitions, setProcessDefinitions] = useState([]) as any;
    const [startProcessDefinition, setStartProcessDefinition] = useState() as any;
    const [selectedTask, setSelectedTask] = useState() as any;
    const [selectedChecklist, setSelectedChecklist] = useState() as any;
    const [selectedField, setSelectedField] = useState() as any;
    const [roles, setRoles] = useState([]) as any;
    const [startProcessDialogOpen, setStartProcessDialogOpen] = useState(false);
    const [signatureDialogOpen, setSignatureDialogOpen] = useState(false);
    const [authentication] = useAtom(authenticationAtom) as any;
    const textEntry = useRef() as any;

    interface DraggableProperties {
        children?: any;
        item?: any;
        type: string
    }

    const Draggable = (properties: DraggableProperties) => {
        const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
            type: properties.type,
            item: properties.item,
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            })
        }))

        return <>
            <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
                <div ref={drag} >
                    {properties && properties.children
                        ?
                        (properties.children)
                        :
                        <></>
                    }
                </div>
            </div>
        </>;
    }

    const DropZone = (properties: DropZoneProperties) => {
        const [{ isOver }, drop] = useDrop(
            () => ({
                accept: [ItemTypes.CARD, ItemTypes.TYPE],
                hover(dragItem: any) {
                    if (!dragItem.id) {
                    } else {
                    }
                },
                drop: (item: any) => {
                    properties.onDrop(item);
                },
                collect: (monitor) => ({
                    isOver: monitor.isOver(),
                }),
            }), []);

        return <div ref={drop} style={{ height: isOver ? '40px' : '5px' }}></div>
    }

    const findField = useCallback(
        (index: string) => {
            const field = selectedChecklist.fields.filter((field: any) => `${field.index}` === index)[0];

            return {
                field,
                index: selectedChecklist.fields.indexOf(field),
            }
        },
        [selectedChecklist],
    )

    const moveField = useCallback(
        (id: string, atIndex: number) => {
            const { field, index } = findField(id);

            setSelectedChecklist({
                ...selectedChecklist, fields: update(selectedChecklist.fields, {
                    $splice: [
                        [index, 1],
                        [atIndex, 0, field],
                    ],
                })
            });
        },
        [findField, setSelectedChecklist, selectedChecklist],
    )

    const totalSeries = {
        labels: ['Aktiv', 'Überfällig'],
        series: [244, 12]
    }

    const taskColumns = [{
        name: 'Type',
        width: '80px',
        cell: (task: any) => <div>
            <div>{(() => {
                switch (task.definition.type) {
                    case 'case': return <FontIcon iconName="DocumentManagement" className="iconClass" style={{ ...iconClass, color: theme.secondary }} />
                    case 'checklist': return <FontIcon iconName="CheckList" className="iconClass" style={{ ...iconClass, color: theme.tertiary }} />
                    case 'process': return <FontIcon iconName="Settings" className="iconClass" style={{ ...iconClass, color: theme.primary }} />
                }
            })()}</div>
        </div>,
        sortable: true,
    }, {
        name: 'Aufgabe',
        selector: (task: any) => task.name,
        width: '300px',
        cell: (task: any) => <span className="textS">{task.name}</span>,
        sortable: true,
    }, {
        name: 'Niederlassung/Gebäude/Fläche',
        selector: (task: any) => task.due.getTime(),
        width: '300px',
        cell: (task: any) => <div>
            <div className="textS">{task.data.site.name}</div>
            <div className="textS">{task.data.site.address.street} {task.data.site.address.streetNumber}, {task.data.site.address.postalCode} {task.data.site.address.city}</div>
        </div>,
        sortable: true,
    }, {
        name: 'Angelegt',
        selector: (task: any) => task.due.getTime(),
        width: '150px',
        cell: (task: any) => <div>
            <div className="textS">{task.created.toLocaleDateString()}</div>
            <div className="textS">{task.created.toLocaleTimeString()}</div>
        </div>,
        sortable: true,
    }, {
        name: 'Fällig',
        selector: (task: any) => task.created.getTime(),
        width: '150px',
        cell: (task: any) =>
            <div>
                <div className="textS">{task.due ? task.due.toLocaleDateString() : ''}</div>
                <div className="textS">{task.due ? task.due.toLocaleTimeString() : ''}</div>
            </div>,
        sortable: true,
    }, {
        name: 'Zust. Rolle/Abteilung',
        selector: (task: any) => task.role,
        width: '200px',
        cell: (task: any) => <span className="textS">{task.role ? task.role : '-'}</span>,
        sortable: true,
    }];

    const iconClass = {
        fontSize: 20,
        height: 20,
        width: 20,
        margin: '0 10px',
    };

    const scheduleColumns = [{
        name: 'Status',
        width: '150px',
        cell: (process: any) => <div><ActionButton
            onClick={() => {
            }}>
            <span className='textS'>Deaktivieren</span>
        </ActionButton></div>,
        sortable: true,
    }, {
        name: 'Type',
        width: '80px',
        cell: (process: any) => <div>
            <div>{(() => {
                switch (process.type) {
                    case 'case': return <FontIcon iconName="DocumentManagement" className="iconClass" style={{ ...iconClass, color: theme.secondary }} />
                    case 'checklist': return <FontIcon iconName="CheckList" className="iconClass" style={{ ...iconClass, color: theme.tertiary }} />
                    case 'process': return <FontIcon iconName="Settings" className="iconClass" style={{ ...iconClass, color: theme.primary }} />
                }
            })()}</div>
        </div>,
        sortable: true,
    }, {
        name: 'Prozess',
        selector: (process: any) => process.name,
        width: '300px',
        cell: (process: any) => <div>
            <div className="textS">{(() => {
                switch (process.origin) {
                    case 'general': return <div className="textS" style={{ color: theme.primary }}>Allgemein</div>
                    case 'vdma': return <div className="textS" style={{ color: theme.primary }}>VDMA 24186</div>
                    case 'internal': return <div className="textS" style={{ color: theme.primary }}>Intern</div>
                }
            })()}</div>
            <div className="textS">{process.name}</div>
        </div>,
        sortable: true,
    }, {
        name: 'Objekte',
        width: '200px',
        cell: (process: any) => <div>{(process.data || []).map((object: any) => <div key={object.name} className="textS">{object.name}</div>)}
        </div>,
        sortable: true,
    }, {
        name: 'Periode (d)',
        selector: (process: any) => process.period,
        width: '150px',
        cell: (process: any) => <span>{process.period || '-'}</span>,
        right: true,
        sortable: true,
    }, {
        name: 'Zust. Rolle/Abteilung',
        selector: (process: any) => process.role,
        width: '200px',
        cell: (process: any) => <span className="textS">{process.role
            ?
            <span>{process.role.name} {process.role.scope ? ` (${process.role.scope})` : ''}</span>
            :
            <span>-</span>}</span>,
        sortable: true,
    }, {
        name: 'Rolle/Abteilung Prüfer',
        selector: (process: any) => process.role,
        width: '200px',
        cell: (process: any) => <span className="textS">{process.roleCheck
            ?
            <span>{process.roleCheck.name} {process.roleCheck.scope ? ` (${process.roleCheck.scope})` : ''}</span>
            :
            <span>-</span>}</span>,
        sortable: true,
    }];

    const selectTask = (task: any) => {
        setSelectedTask(task);

        if (task) {
        }
    };

    useEffect(() => {
        const call = async () => {
            try {
                const newRoles = await userAPI.query(new Query(new EntityQuery(Role.type, [], undefined,
                    or(is('type').equal(RoleType.role), is('type').equal(RoleType.parameterizableRole)))));

                setRoles(newRoles);
            } catch (error) {
                properties.processError(error);
            }
        };

        call();
    }, []);

    useEffect(() => {
        const call = async () => {
            try {
                const newProcessDefinitions = await userAPI.getProcessDefinitions(authentication.customer);

                console.log('Process definitions >>>', newProcessDefinitions);

                setProcessDefinitions(newProcessDefinitions);

                const newTasks = await userAPI.getOpenTasks(authentication.customer);

                console.log('Open Tasks >>>', newTasks);

                setTasks(newTasks);
            } catch (error) {
                console.log(error);
                properties.processError(error);
            }
        };

        call();
    }, [selectedTask]);

    const setParams = (value: any) => {
        const url = new URL(window.location.href);

        url.searchParams.set('tab', value.key.substring(1));
        window.history.pushState(null, '', url.toString());

        setTab(value.key.substring(1));
    }

    const isChecklistComplete = (checklist: Checklist) => {
        return checklist.name && checklist.name.trim().length > 0
            && checklist.role;
    }

    return <><div>
        {selectedTask
            ?
            <div>
                <div className='displayFlex'>
                    <div className='flexGrow1 displayFlex flexDirectionColumn'>
                        <div className='displayFlex'>
                            <div className='textHeader'>{selectedTask.name}</div>
                        </div>
                        <div className='marginTopM displayFlex gapM'>
                            <div className='displayFlex alignItemsCenter'>
                                <FontIcon aria-label="Train" iconName="MapPin" style={{
                                    fontSize: 40,
                                    height: 40,
                                    width: 40,
                                    margin: '0 20px',
                                    color: theme.secondary,
                                }} />
                                <div>
                                    <div className="fontWeightBold">{selectedTask.data.site.name}</div>
                                    <div>{selectedTask.data.site.address.street} {selectedTask.data.site.address.streetNumber}, {selectedTask.data.site.address.postalCode} {selectedTask.data.site.address.city}</div>
                                </div>
                                <ActionButton iconProps={{ iconName: 'OpenInNewWindow' }}
                                    onClick={() => {
                                        navigate(`/entity?selectedEntityType=Building&selectedEntity=${selectedTask.data.site.building._id}`);
                                    }}>
                                </ActionButton>
                            </div>
                            {selectedTask.data.asset
                                ?
                                <div className='displayFlex'>
                                    <FontIcon aria-label="Train" iconName="HardDriveGroup" style={{
                                        fontSize: 40,
                                        height: 40,
                                        width: 40,
                                        margin: '0 20px',
                                        color: theme.tertiary,
                                    }} />
                                    <div>
                                        <div className="fontWeightBold">{selectedTask.data.asset.id}</div>
                                        <div>{selectedTask.data.asset.schemaCategory} {selectedTask.data.asset.categoryName}</div>
                                    </div>
                                    <ActionButton iconProps={{ iconName: 'OpenInNewWindow' }}
                                        onClick={() => {
                                            navigate(`/entity?selectedEntityType=Asset&selectedEntity=${selectedTask.data.asset._id}`);
                                        }}>
                                    </ActionButton>
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </div>
                    <div className='displayFlex gapM'>
                        <PrimaryButton onClick={async () => {
                            await userAPI.deactivateTask(authentication.customer, selectedTask);
                            setSelectedTask(null);
                        }}>
                            {t('global.close')}
                        </PrimaryButton>
                        <PrimaryButton onClick={async () => {
                            await userAPI.deactivateAndSaveTask(authentication.customer, selectedTask);
                            setSelectedTask(null);
                        }}>
                            Speichern und schließen
                        </PrimaryButton>
                        <DefaultButton onClick={async () => {
                            if (false) {
                                setSignatureDialogOpen(true);
                            } else {
                                await userAPI.completeTask(authentication.customer, selectedTask);
                                setSelectedTask(null);
                            }
                        }}>
                            Abschließen
                        </DefaultButton>
                    </div>
                </div>
                <div className='marginTopL displayFlex gapM'>
                    <div className='flexGrow1'>
                        <Section title='Historie'>
                            <div>
                                <div className='displayFlex gapS'>
                                    <div className='width70 textAlignRight'>{selectedTask.created.toLocaleDateString()}</div>
                                    <div className='width100 textAlignRight'>{selectedTask.created.toLocaleTimeString()}</div>
                                    <div className='width150'></div>
                                    <div className='flexGrow1'>Angelegt</div>
                                </div>
                                {(selectedTask.history || []).filter((item: any) => item.action === 'comment').map((item: any) => <div className='marginTopXS displayFlex gapS'>
                                    <div className='width70 textAlignRight'>{item.timestamp.toLocaleDateString()}</div>
                                    <div className='width100 textAlignRight'>{item.timestamp.toLocaleTimeString()}</div>
                                    <div className='width150'><a>{item.user.firstName} {item.user.lastName}</a></div>
                                    <div className='flexGrow1'>{item.content}</div>
                                </div>)}
                            </div>
                        </Section>
                        {selectedTask.definition.type === 'unstructured'
                            ?
                            <div>
                            </div>
                            :
                            <Section title='Checks' className='marginTopM'>
                                <div>
                                    {selectedTask.definition.fields.map((field: any) =>
                                        <div className='marginTopS displayFlex'>
                                            <div className='flexGrow1 displayFlex'>
                                                {field.label}
                                            </div>
                                            <div className='width300'>
                                                {(() => {
                                                    switch (field.type) {
                                                        case 'text': return <div>
                                                            <TextField multiline onChange={() => { }}></TextField>
                                                        </div>;
                                                        case 'integer': return <div>
                                                            <TextField onChange={() => { }}></TextField>
                                                        </div>;
                                                        case 'decimal': return <div>
                                                            <TextField onChange={() => { }}></TextField>
                                                        </div>;
                                                        case 'OKNotOK': return <div>
                                                            <Toggle onText="OK" offText="Not OK" onChange={() => { }} />
                                                        </div>;
                                                        case 'DoneNotDone': return <div>
                                                            <Toggle onText="Done" offText="Not Done" onChange={() => { }} />
                                                        </div>;
                                                        case 'rating': return <div>
                                                            <Rating
                                                                readOnly
                                                                defaultRating={3}
                                                                allowZeroStars
                                                                max={5}
                                                            />
                                                        </div>;
                                                    }
                                                })()}
                                            </div>
                                        </div>)}
                                </div>
                            </Section>
                        }
                        <Section title='Kommentar' className='marginTopM'>
                            <div className='marginTopM'><TextField multiline></TextField></div>
                        </Section>
                    </div>
                    <div className="width400 displayFlex flexDirectionColumn">
                        <div>
                            <div>
                                <ActionButton iconProps={{ iconName: 'FollowUser' }}
                                    onClick={() => {
                                    }}>
                                    Aufgabe delegieren
                                </ActionButton>
                            </div>
                            <div>
                                <ActionButton style={{ color: 'red' }} iconProps={{ iconName: 'Delete', color: 'red' }}
                                    onClick={() => {
                                    }}>
                                    Aufgabe abbrechen
                                </ActionButton>
                            </div>
                        </div>
                        {selectedTask.definition.requiredDocuments && selectedTask.definition.requiredDocuments.length > 0
                            ?
                            <Section title='Benötigte Dokumente' className='marginTopM'>
                                {selectedTask.definition.requiredDocuments.map((document: any) => (<div>
                                    <div className='marginTopS marginBottomS textSubHeading'>{document.name}</div>
                                    <DocumentSlot className='width100 height150'></DocumentSlot>
                                </div>))}
                            </Section>
                            :
                            <></>
                        }
                        <Section title='Weitere Dokumente' className='marginTopM'>
                            <DocumentSlot className='width100 height150'></DocumentSlot>
                        </Section>
                    </div>
                </div>
            </div>
            :
            <>
                {selectedChecklist
                    ?
                    <div>
                        <div className='displayFlex'>
                            <div className='marginTopM flexGrow1'></div>
                            <div className='displayFlex gapM'>
                                {selectedChecklist._id
                                    ?
                                    <>
                                        <DefaultButton onClick={() => {
                                            setSelectedChecklist(null);
                                        }}>
                                            {t('global.close')}
                                        </DefaultButton>
                                        <PrimaryButton disabled={!isChecklistComplete(selectedChecklist)} onClick={async () => {
                                            await userAPI.updateChecklist(authentication.customer, selectedChecklist);

                                            setSelectedChecklist(null);
                                        }}>
                                            {t('global.save')}
                                        </PrimaryButton>
                                        <PrimaryButton disabled={!isChecklistComplete(selectedChecklist)} onClick={async () => {
                                            await userAPI.updateChecklist(authentication.customer, selectedChecklist);

                                            setSelectedChecklist(null);
                                        }}>
                                            Speichern & neue Version
                                        </PrimaryButton>
                                    </>
                                    :
                                    <>
                                        <DefaultButton onClick={() => {
                                            setSelectedChecklist(null);
                                        }}>
                                            {t('global.close')}
                                        </DefaultButton>
                                        <PrimaryButton disabled={!isChecklistComplete(selectedChecklist)} onClick={async () => {
                                            await userAPI.createChecklist(authentication.customer, selectedChecklist);

                                            setSelectedChecklist(null);
                                        }}>
                                            {t('global.create')}
                                        </PrimaryButton>
                                    </>}
                            </div>
                        </div>
                        <div className='marginTopXL displayFlex alignItemsStart gapXL'>
                            <div>
                                <div>
                                    <TextField label={'Name'} value={selectedChecklist.name} onChange={(_: any, name: string | undefined) => {
                                        setSelectedChecklist({ ...selectedChecklist, name })
                                    }} style={{ width: 300 }}></TextField>
                                </div>
                                <div className='marginTopM'>
                                    <TextField label={'Description'} multiline value={selectedChecklist.description} onChange={(_: any, description: string | undefined) => {
                                        setSelectedChecklist({ ...selectedChecklist, description })
                                    }} style={{ width: 300 }}></TextField>
                                </div>

                            </div>
                            <div>
                                <div>
                                    <Dropdown
                                        label="Rolle Start"
                                        options={[{ key: '__none', text: 'Kein manueller Start' }, ...roles.filter((role: any) => !selectedChecklist.role || role.name !== selectedChecklist.role).map((role: any) => ({ key: role.name, text: role.name }))]}
                                        selectedKey={selectedChecklist.roleStart || '__none'}
                                        styles={{
                                            dropdown: { width: 250 },
                                        }}
                                        onChange={(_: any, item: any) => {
                                            if (item.key === '__none') {
                                                setSelectedChecklist({ ...selectedChecklist, roleStart: undefined });
                                            } else {
                                                setSelectedChecklist({ ...selectedChecklist, roleStart: item.key });
                                            }
                                        }}
                                    />
                                </div>
                                <div className='marginTopM'>
                                    <Dropdown
                                        label="Rolle Ausführung"
                                        options={roles.map((role: any) => ({ key: role.name, text: role.name }))}
                                        selectedKey={selectedChecklist.role}
                                        styles={{
                                            dropdown: { width: 250 },
                                        }}
                                        onChange={(_: any, item: any) => {
                                            setSelectedChecklist({ ...selectedChecklist, role: item.key });
                                        }}
                                    />
                                </div>
                                <div className='marginTopM'>
                                    <Dropdown
                                        label="Rolle Überprüfung"
                                        selectedKey={selectedChecklist.roleCheck || '__none'}
                                        options={[{ key: '__none', text: 'Keine Überprüfung' }, ...roles.filter((role: any) => !selectedChecklist.role || role.name !== selectedChecklist.role).map((role: any) => ({ key: role.name, text: role.name }))]}
                                        styles={{
                                            dropdown: { width: 250 },
                                        }}
                                        onChange={(_: any, item: any) => {
                                            if (item.key === '__none') {
                                                setSelectedChecklist({ ...selectedChecklist, roleCheck: undefined });
                                            } else {
                                                setSelectedChecklist({ ...selectedChecklist, roleCheck: item.key });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='marginLeftL'>
                                <div>
                                    <SpinButton
                                        label="Wiederkehr"
                                        labelPosition={Position.top}
                                        value={selectedChecklist.recurrence ? `${selectedChecklist.recurrence}` : '0'}
                                        min={0}
                                        max={5000}
                                        step={0.5}
                                        onChange={(_: any, value: any) => {
                                            setSelectedChecklist({ ...selectedChecklist, recurrence: Number(value) });
                                        }} />
                                </div>
                            </div>
                            <div className='marginLeftL'>
                                <div>
                                    <Checkbox label="Signatur bei Abschluß erforderlich" checked={selectedChecklist.signatureRequired} onChange={(_: any, signatureRequired?: boolean) => setSelectedChecklist({ ...selectedChecklist, signatureRequired })} />
                                </div>
                                <div className='marginTopS'>
                                    <Checkbox label="Ergänzen von Photos und Dokumenten möglich" checked={selectedChecklist.allowsAdditionalDocuments} onChange={(_: any, allowsAdditionalDocuments?: boolean) => setSelectedChecklist({ ...selectedChecklist, allowsAdditionalDocuments })} />
                                </div>
                            </div>
                        </div>
                        <div className='marginTopXL displayFlex gapS'>
                            <div className='flexGrow1'>
                                <div className='textSubHeading'>Palette Listenelemente</div>
                                <div className='height20 displayFlex alignItemsBottom textS'>Gewünschtes Element auf Checkliste ziehen</div>
                                <div className="marginTopS">
                                    <CheckItemType type="text" text="Textfeld"></CheckItemType>
                                    <CheckItemType type="integer" text="Ganzzahl-Feld"></CheckItemType>
                                    <CheckItemType type="decimal" text="Dezimalzahl-Feld"></CheckItemType>
                                    <CheckItemType type="OKNotOK" text="OK/Nicht OK-Auswahl"></CheckItemType>
                                    <CheckItemType type="DoneNotDone" text="Erledigt/nicht erledigt-Auswahl"></CheckItemType>
                                    <CheckItemType type="rating" text="Rating"></CheckItemType>
                                </div>
                            </div>
                            <div className='flexGrow1'>
                                <div className='textSubHeading'>Checkliste</div>
                                <div className='height20 displayFlex alignItemsBottom textS'></div>
                                <div className="marginTopS borderNeutralDashed paddingS">
                                    <DropZone onDrop={(item: any) => {
                                        // TODO Need better type marker

                                        if (!item.text) {
                                            const newField = { type: item.type, text: 'Neues Feld' };
                                            const newFields = [newField, ...selectedChecklist.fields];

                                            setSelectedChecklist({ ...selectedChecklist, fields: newFields });
                                            setSelectedField(newField);

                                            setTimeout(() => {
                                                if (textEntry.current) {
                                                    textEntry.current.focus();
                                                    textEntry.current.select();
                                                }
                                            }, 0);
                                        }
                                    }}></DropZone>
                                    {selectedChecklist.fields.map((field: any, index: number) => (
                                        <div>
                                            <div className='displayFlex gapXS alignItemsCenter'>
                                                <ActionButton iconProps={{ iconName: 'Delete' }}
                                                    onClick={() => {
                                                        if (field === selectedField) {
                                                            setSelectedField(null);
                                                        }

                                                        setSelectedChecklist({ ...selectedChecklist, fields: selectedChecklist.fields.filter((otherField: any) => otherField !== field) })
                                                    }}>
                                                </ActionButton>
                                                <Draggable type={ItemTypes.CARD} item={field}>
                                                    <div className='width400 marginTopS displayFlex' style={field === selectedField ? selectedStyle : style} onClick={() => setSelectedField(field)}>
                                                        <div className='flexGrow1 displayFlex'>
                                                            {field.text}
                                                        </div>
                                                        <div className='width150 displayFlex flexAlignStart'>
                                                            {(() => {
                                                                switch (field.type) {
                                                                    case 'text': return <div>
                                                                        <TextField multiline onChange={() => { }}></TextField>
                                                                    </div>;
                                                                    case 'integer': return <div>
                                                                        <TextField onChange={() => { }}></TextField>
                                                                    </div>;
                                                                    case 'decimal': return <div>
                                                                        <TextField onChange={() => { }}></TextField>
                                                                    </div>;
                                                                    case 'OKNotOK': return <div>
                                                                        <Toggle onText="OK" offText="Not OK" onChange={() => { }} />
                                                                    </div>;
                                                                    case 'DoneNotDone': return <div>
                                                                        <Toggle onText="Done" offText="Not Done" onChange={() => { }} />
                                                                    </div>;
                                                                    case 'rating': return <div>
                                                                        <Rating
                                                                            readOnly
                                                                            defaultRating={3}
                                                                            allowZeroStars
                                                                            max={5}
                                                                        />
                                                                    </div>;
                                                                }
                                                            })()}
                                                        </div>
                                                    </div>
                                                </Draggable>
                                            </div>
                                            <DropZone onDrop={(item: any) => {
                                                // TODO Need better type marker

                                                if (!item.text) {
                                                    const newFields = [...selectedChecklist.fields];
                                                    const newField = { type: item.type, text: 'Neues Feld' };

                                                    newFields.splice(index + 1, 0, newField);

                                                    console.log('New fields >>>', newFields);

                                                    setSelectedChecklist({ ...selectedChecklist, fields: newFields });
                                                    setSelectedField(newField);

                                                    setTimeout(() => {
                                                        if (textEntry.current) {
                                                            textEntry.current.focus();
                                                            textEntry.current.select();
                                                        }
                                                    }, 0);
                                                }
                                            }}></DropZone>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='width400'>
                                <div className='textSubHeading'>Listeneintrag</div>
                                <div className='height20 displayFlex alignItemsBottom textS'></div>
                                {selectedField
                                    ?
                                    <div className="marginTopS">
                                        <div>
                                            <TextField componentRef={textEntry} label="Text" value={selectedField.text} onChange={(_: any, text: string | undefined) => {
                                                const newSelectedField = { ...selectedField, text };
                                                const index = selectedChecklist.fields.indexOf(selectedField);
                                                const newFields = [...selectedChecklist.fields];

                                                newFields[index] = newSelectedField;

                                                setSelectedField(newSelectedField);
                                                setSelectedChecklist({ ...selectedChecklist, fields: newFields });
                                            }} />
                                        </div>
                                        <div className='marginTopM'>
                                            <Checkbox label="Info mit Beschreibung anzeigen" onChange={() => { }} />
                                        </div>
                                        <div className='marginTopM'>
                                            <TextField label="Beschreibung" value={selectedField.description} multiline />
                                        </div>
                                    </div>
                                    :
                                    <div>Kein Listeneintrag ausgewählt</div>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className='displayFlex'>
                            <div className='flexGrow1'></div>
                            <DefaultButton onClick={() => {
                                setStartProcessDialogOpen(true);
                            }}>
                                Prozess starten
                            </DefaultButton>
                        </div>
                        <Pivot className="marginTopM" aria-label="" onLinkClick={setParams} selectedKey={tab}>
                            <PivotItem id="project" headerText={'Gesamtstatus'}>
                                <div className="marginTopM displayFlex alignItemsCenter gapL">
                                    <div className="displayFlex flexDirectionColumn">
                                        <div className="textL fontWeightBold">Gesamt</div>
                                        <ReactApexChart options={{ colors, labels: totalSeries.labels }} series={totalSeries.series} type="pie" width={500} />
                                    </div>
                                </div>
                                <div className='marginTopM displayFlex gapM'>
                                    <Metric title={'Gesamtanzahl Aufgaben'} value={'255'} color={theme.tertiary}></Metric>
                                    <Metric title={'Überfällige Aufgaben'} value={'12'} color={theme.secondary}></Metric>
                                </div>
                            </PivotItem>
                            <PivotItem id="project" headerText={'Aufgaben'}>
                                <div className='marginTopL borderNeutral'>
                                    <DataTable
                                        data={tasks}
                                        columns={taskColumns}
                                        customStyles={dataTableStyles}
                                        selectableRows
                                        selectableRowsSingle
                                        onSelectedRowsChange={({ selectedRows }) => {
                                            if (selectedRows && selectedRows.length > 0) {
                                                selectTask(selectedRows[0]);
                                            } else {
                                                selectTask(null);
                                            }
                                        }}
                                        pagination
                                        paginationComponentOptions={{
                                            rowsPerPageText: 'Zeilen pro Seite',
                                            rangeSeparatorText: 'von',
                                            selectAllRowsItem: true,
                                            selectAllRowsItemText: 'Todos',
                                        }}
                                        paginationPerPage={10}
                                    />
                                </div>
                            </PivotItem>
                            <PivotItem id="project" headerText={'Prozessdefinitionen & Planung'}>
                                <div className='marginTopM'>
                                    <ActionButton disabled={roles.length === 0} iconProps={{ iconName: 'AddToShoppingList' }}
                                        onClick={() => {
                                            setSelectedChecklist(new Checklist({ name: 'Neue Checkliste', role: roles[0].name }));
                                        }}>
                                        Checkliste anlegen
                                    </ActionButton>
                                    <div className='marginTopS borderNeutral'>
                                        <DataTable
                                            data={processDefinitions}
                                            columns={scheduleColumns}
                                            customStyles={dataTableStyles}
                                            selectableRows
                                            selectableRowsSingle
                                            onSelectedRowsChange={({ selectedRows }) => {
                                                // TODO Distinguish BP types

                                                setSelectedChecklist(selectedRows[0]);
                                            }}
                                            pagination
                                            paginationComponentOptions={{
                                                rowsPerPageText: 'Zeilen pro Seite',
                                                rangeSeparatorText: 'von',
                                                selectAllRowsItem: true,
                                                selectAllRowsItemText: 'Todos',
                                            }}
                                            paginationPerPage={10}
                                        />
                                    </div>
                                </div>
                            </PivotItem>
                        </Pivot>
                    </div>
                }
            </>
        }
    </div>
        <Dialog
            hidden={!signatureDialogOpen}
            onDismiss={() => setSignatureDialogOpen(false)}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Abschluß unterzeichnen',
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <div className='paddingM'>
                <div className='borderNeutral'>
                    <SignatureCanvas penColor='green'
                        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} />
                </div>
            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton onClick={() => {
                    setSignatureDialogOpen(false);
                }} >Unterzeichnen und abschließen</PrimaryButton>
                <DefaultButton onClick={() => {
                    setSignatureDialogOpen(false);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>

        <Dialog
            hidden={!startProcessDialogOpen}
            onDismiss={() => setStartProcessDialogOpen(false)}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Prozess starten',
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <div className='paddingM'>
                <div className='height500' style={{ overflow: 'auto' }}>
                    {processDefinitions.map((processDefinition: any) => <div className={`paddingM ${processDefinition === startProcessDefinition ? 'borderNeutral' : ''}`} onClick={() => setStartProcessDefinition(processDefinition)}>
                        <div>{processDefinition.name}</div>
                    </div>)}
                </div>
            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton disabled={!startProcessDefinition} onClick={() => {
                    setStartProcessDialogOpen(false);
                }} >Starten</PrimaryButton>
                <DefaultButton onClick={() => {
                    setStartProcessDialogOpen(false);
                }} text={t('global.close')} />
            </DialogFooter>
        </Dialog>
    </>;
}