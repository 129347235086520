import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import '../App.sass';
import { useTranslation } from "react-i18next";
import { Dialog, DialogFooter, DialogType, TextField } from '@fluentui/react';
import { useState } from 'react';

interface Properties {
}

export const useConfirmationDialog = (timeout: number = 15000) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [text, setText] = useState() as any;
  const [keyword, setKeyword] = useState('');
  const [callback, setCallback] = useState() as any;
  const [keywordConfirmation, setKeywordConfirmation] = useState() as any;
  const { t } = useTranslation();

  const openConfirmationDialog = (title: string, keyword: string, callback: any, text?: string) => {
    setTitle(title);
    setText(text);
    setKeyword(keyword);
    setKeywordConfirmation(null);
    setCallback(() => callback);
    setConfirmationDialogOpen(true);
  }

  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(true);
  }

  const ConfirmationDialog = (properties: Properties) => {
    const { t } = useTranslation();

    return <Dialog
      hidden={!confirmationDialogOpen}
      onDismiss={() => setConfirmationDialogOpen(false)}
      maxWidth={600}
      minWidth={600}
      dialogContentProps={{
        type: DialogType.normal,
        title,
      }}
      modalProps={{
        isBlocking: true,
      }}
    >
      <div className='paddingS'>
        {text ?
          <div>{text}</div>
          :
          <></>
        }
        <div>Gib <span className='fontWeightBold'>{keyword}</span> zum Löschen ein.</div>
        <TextField className="marginTopS" value={keywordConfirmation} onChange={(_: any, keywordConfirmation: string | undefined) => setKeywordConfirmation(keywordConfirmation)}></TextField>
      </div>
      <DialogFooter className="marginTopL">
        <PrimaryButton disabled={keyword !== keywordConfirmation} onClick={async () => {
          await callback();
          setConfirmationDialogOpen(false);
        }} >{t('global.delete')}</PrimaryButton>
        <DefaultButton onClick={() => {
          setConfirmationDialogOpen(false);
        }} text={t('global.cancel')} />
      </DialogFooter>
    </Dialog>;
  };
  return {
    openConfirmationDialog, closeConfirmationDialog, ConfirmationDialog,
  };
}