import { useState, useEffect, useMemo } from "react";
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import Header from "./Header";
import BuildingProperties from "./object-properties/BuildingProperties";
import AssetProperties from "./object-properties/AssetProperties";
import { useLocation, useNavigate } from "react-router-dom";
import { userAPI } from "./UserAPI";
import { useTranslation } from 'react-i18next';
import LegalPersonProperties from "./object-properties/LegalPersonProperties";
import FloorProperties from "./object-properties/FloorProperties";
import ContentPanel from "./components/ContentPanel";
import { MessageBox, useMessagBox } from "./components/MessageBox";

export default function EntityPage() {
    const { t } = useTranslation();
    const { search } = useLocation();
    const navigate = useNavigate();
    const { processError, messageBox } = useMessagBox();
    const [entity, setEntity] = useState();
    const [loading, setLoading] = useState(true);
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    const entityType = searchParams.get("selectedEntityType");
    const entityId = searchParams.get("selectedEntity");

    useEffect(() => {
        const call = async () => {
            if (!entityType) {
                navigate('/chatbot');
            } else if (entityId) {
                console.log(`Retrieving ${entityType} with ID ${entityId}`);

                setLoading(true);
                // @ts-ignore                
                const newEntity = await userAPI.getEntityByID(entityType, entityId);

                console.log('Entity found >>>', newEntity);

                setEntity(newEntity);
                setLoading(false);
            }
        };

        call();
    }, [navigate, searchParams]);

    return <>
        {loading
            ?
            <div className="progress"><Spinner size={SpinnerSize.large} label={`${t('global.loading')} ...`} /></div>
            :
            <>
                <Header showHome title={entityType || 'Bla'}></Header>
                <ContentPanel>
                    <div>
                        {(() => {
                            // @ts-ignore
                            switch (entityType) {
                                case 'Asset':
                                    return <AssetProperties asset={entity} processError={processError}></AssetProperties>;
                                case 'Floor':
                                    return <FloorProperties floor={entity} processError={processError}></FloorProperties>;
                                case 'Building':
                                    return <BuildingProperties building={entity} processError={processError}></BuildingProperties>;
                                case 'LegalPerson':
                                    return <LegalPersonProperties legalPerson={entity} processError={processError}></LegalPersonProperties>;
                                default:
                                    return <></>;
                            }
                        })()}
                    </div>
                </ContentPanel>
            </>
        }
        <MessageBox messageBox={messageBox} ></MessageBox>
    </>;
}