import '../App.sass';
import { useEffect, useState } from 'react';
import InfoList from './InfoList';
import { userAPI } from '../UserAPI';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { Area, EntityQuery, Filter, Query, PVPlant, Battery, CHP, is, group, sum } from '@the-real-insight/tri-model';

class Properties {
    building: any;
}

const numberFormat = new Intl.NumberFormat('de');

export default function EnergyManagementProperties(properties: Properties) {
    const { t } = useTranslation();
    const [chartOptions, setChartOptions] = useState() as any;
    const [series, setSeries] = useState() as any;
    const [area, setArea] = useState() as any;
    const [pvPlants, setPVPlants] = useState([]) as any;
    const [batteries, setBatteries] = useState([]) as any;
    const [chps, setCHPs] = useState([]) as any;
    const [pvGroups, setPVGroups] = useState([]) as any;
    const [sumPV, setSumPV] = useState(0) as any;
    const [sumBattery, setSumBattery] = useState(0) as any;

    useEffect(() => {
        const process = async () => {

            console.log('PLZ', properties.building.address.postalCode);

            const pvPlants = await userAPI.query(new Query(new EntityQuery(PVPlant.type, [], undefined, is('postalCode').equal(properties.building.address.postalCode))));
            const batteries = await userAPI.query(new Query(new EntityQuery(Battery.type, [], undefined, is('postalCode').equal(properties.building.address.postalCode))));
            const chps = await userAPI.query(new Query(new EntityQuery(CHP.type, [], undefined, is('postalCode').equal(properties.building.address.postalCode))));

            console.log('PVs', pvPlants);
            console.log('Batteries', batteries);
            console.log('CHPs', chps);

            setPVPlants(pvPlants);
            setBatteries(batteries);
            setCHPs(chps);
            setPVGroups(group(pvPlants.map((pv: any) => pv.grossCapacity * 1000)));
            setSumPV(sum(pvPlants.map((pv: any) => pv.grossCapacity)));
            setSumBattery(sum(batteries.map((battery: any) => battery.usableStorageCapacity)));
            //const sumPv = sum(pvPlants.map((pv: any) => [pv.grossCapacity]));
        }

        process();
    }, [properties]);

    useEffect(() => {
        const process = async () => {
            const areas = await userAPI.query(new Query(new EntityQuery(Area.type, [], undefined,
                Filter.latLonContainedInBoundingBoxFilter(0.5 * (properties.building.minLat + properties.building.maxLat),
                    0.5 * (properties.building.minLon + properties.building.maxLon)))));

            if (areas.length > 0) {
                setArea(areas[0])
            }
        }

        process();
    }, [properties]);

    useEffect(() => {
        const call = async () => {
            let energyConsumptionSeries = await userAPI.queryTelemetry({ metric: 'energyConsumption' });
            let outsideTemperatureSeries = await userAPI.queryTelemetry({ metric: 'outsideTemperature' });
            // TODO Hack

            energyConsumptionSeries = energyConsumptionSeries.filter((obj1: any, i: number) => energyConsumptionSeries.findIndex((obj2: any) => (new Date(obj2.timestamp).getTime() === new Date(obj1.timestamp).getTime())) === i);
            outsideTemperatureSeries = outsideTemperatureSeries.filter((obj1: any, i: number) => outsideTemperatureSeries.findIndex((obj2: any) => (new Date(obj2.timestamp).getTime() === new Date(obj1.timestamp).getTime())) === i);

            setSeries([{
                name: t('buildingProperties.energyManagementProperties.energyConsumption'),
                data: energyConsumptionSeries.map((measurement: any) => measurement.value),
            }, {
                name: t('buildingProperties.energyManagementProperties.outsideTemperature'),
                data: outsideTemperatureSeries.map((measurement: any) => measurement.value),
            }]);
            setChartOptions({
                chart: {
                    height: 350,
                    type: 'area'
                },
                dataLabels: {
                    enabled: false
                },
                colors: ['#649aaa', '#576071'],
                stroke: {
                    curve: 'smooth',
                    width: [1, 1],
                },
                xaxis: {
                    type: 'string',
                    categories: energyConsumptionSeries.map((measurement: any) => `${new Date(measurement.timestamp).toLocaleDateString()}`)
                },
                yaxis: [{
                    seriesName: t('buildingProperties.energyManagementProperties.energyConsumption'),
                    min: 0,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#00288E'
                    },
                    labels: {
                        style: {
                            colors: '#00288E',
                        },
                        formatter: (val: number) => {
                            return `${val.toFixed(1)}`
                        },
                    },
                    title: {
                        div: t('buildingProperties.energyManagementProperties.energyConsumption') + ' (kwh)',
                        style: {
                            color: '#00288E',
                        }
                    }
                },
                {
                    seriesName: t('buildingProperties.energyManagementProperties.outsideTemperature'),
                    opposite: true,
                    min: 0,
                    max: 50,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#576071'
                    },
                    labels: {
                        style: {
                            colors: '#576071',
                        },
                        formatter: (val: number) => {
                            return `${val.toFixed(1)}`
                        },
                    },
                    title: {
                        div: t('buildingProperties.energyManagementProperties.outsideTemperature') + ' (°C)',
                        style: {
                            color: '#576071',
                        }
                    },
                },
                ],
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            })
        };

        call();
    }, []);

    return <div>
        <div className="headerL">Anlagen zur Erzeugung und Speicherung erneuerbarer Energie im Postleitzahlengebiet</div>
        <div className='marginTopM displayFlex gapM'>
            <InfoList list={[
                {
                    label: 'PV-Anlagen',
                    value: pvPlants.length > 0 ? pvPlants.length : '-',
                }, {
                    label: 'Batteriespeicher',
                    value: batteries.length > 0 ? batteries.length : '-',
                }, {
                    label: 'BHKW',
                    value: chps.length > 0 ? chps.length : '-',
                }]}></InfoList>
                <InfoList list={[
                {
                    label: 'Gesamtkapazität PV-Anlagen',
                    value: `${numberFormat.format(sumPV)} kW`,                    
                }, {
                    label: 'Gesamtkapazität Batteriespeicher',
                    value: `${numberFormat.format(sumBattery)} kW`,                    
                }, {
                    label: 'Gesamtkapazität BHKW',
                    value: '-',
                }]}></InfoList>
        </div>
        <div className='marginTopM displayFlex gapM'>
            <div className="flexGrow1">
                <div className="headerL">Energiequellen im Umkreis 1km²</div>
                <div className='marginTopM'>
                    <InfoList list={[
                        {
                            label: 'Gesamt',
                            value: area ? area.totalEnergySourceCount : '-',
                        },
                        {
                            label: 'Gas',
                            value: area ? area.gasEnergySourceCount : '-',
                        },
                        {
                            label: 'Öl',
                            value: area ? area.oilEnergySourceCount : '-',
                        },
                        {
                            label: 'Holz und Pellets',
                            value: area ? area.woodEnergySourceCount : '-',
                        },
                        {
                            label: 'Biomasse',
                            value: area ? area.bioMassGasEnergySourceCount : '-',
                        },
                        {
                            label: 'Solar/Geothermie/Wärmepumpe',
                            value: area ? area.solarGeoHeatPumpEnergySourceCount : '-',
                        },
                        {
                            label: 'Elektrizität',
                            value: area ? area.electricalEnergySourceCount : '-',
                        },
                        {
                            label: 'Kohle',
                            value: area ? area.coalEnergySourceCount : '-',
                        },
                        {
                            label: 'Keine',
                            value: area ? area.noSourceCount : '-',
                        },
                    ]}></InfoList>
                </div>
            </div>
            <div className="flexGrow1">
                <div className="headerL">Heizquellen im Umkreis 1km²</div>
                <div className='marginTopM'>
                    <InfoList list={[
                        {
                            label: 'Gesamt',
                            value: area ? area.heatingCount : '-',
                        },
                        {
                            label: 'Fernwärme',
                            value: area ? area.districtHeatingCount : '-',
                        },
                        {
                            label: 'Etagenheizung',
                            value: area ? area.storeyHeatingCount : '-',
                        },
                        {
                            label: 'Blockheizkraftwerk',
                            value: area ? area.blockHeatingCount : '-',
                        },
                        {
                            label: 'Zentralheizung',
                            value: area ? area.centralHeatingCount : '-',
                        },
                        {
                            label: 'Ofenheizung',
                            value: area ? area.ovenHeatingCount : '-',
                        },
                        {
                            label: 'Keine Heizung',
                            value: area ? area.noHeatingCount : '-',
                        },
                    ]}></InfoList>
                </div>
            </div>
        </div>
        <div className="marginTopM headerL">{t('buildingProperties.energyManagementProperties.pv')}</div>
        <div className='marginTopM'>
            <InfoList list={[
                {
                    label: t('buildingProperties.energyManagementProperties.buildingOrientation'),
                    value: '166°',
                },
                {
                    label: t('buildingProperties.energyManagementProperties.roofTilt'),
                    value: '22°',
                },
                {
                    label: t('buildingProperties.energyManagementProperties.sunHours'),
                    value: '8400 h',
                },
                {
                    label: t('buildingProperties.energyManagementProperties.installedPVUnits'),
                    value: '2',
                },
                {
                    label: t('buildingProperties.energyManagementProperties.totalPower'),
                    value: '1600 W',
                },
            ]}></InfoList>
        </div>       
        <div className="marginTopM headerL">{t('buildingProperties.energyManagementProperties.heatPump')}</div>
        <div className='marginTopM'>{t('global.noInformationAvailable')}</div>
        <div className="marginTopM headerL">{t('buildingProperties.energyManagementProperties.solarThermal')}</div>
        <div className='marginTopM'>{t('global.noInformationAvailable')}</div>
        <div className="marginTopM headerL">{t('buildingProperties.energyManagementProperties.districtHeat')}</div>
        <div className='marginTopM'>{t('global.noInformationAvailable')}</div>
        <div className="marginTopM headerL">{t('buildingProperties.energyManagementProperties.energyConsumption')}</div>
        {
            chartOptions && series && series.length && series[0].data.length > 0 && series[1].data.length > 0
                ?
                <>
                    <Chart options={chartOptions} series={series} type="area" height={400}/>
                </>
                :
                <div className='marginTopM'>{t('global.noInformationAvailable')}</div>
        }

    </div>;
}