import { DefaultButton, Dropdown, TextField } from '@fluentui/react';
import '../App.sass';
import { useEffect, useState } from 'react';
import { userAPI } from '../UserAPI';
import { useTranslation } from 'react-i18next';
import { MeasurementTypeI18N } from '@the-real-insight/tri-model';
import i18next from 'i18next';

interface Properties {
  className?: string;
  device: any;
  applyConfiguration: (configuration: any) => void;
  configurationOnly?: boolean
  hideIDField?: boolean
}

// TODO Locale
const numberFormat0 = new Intl.NumberFormat(i18next.language, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
const numberFormat1 = new Intl.NumberFormat(i18next.language, { minimumFractionDigits: 1, maximumFractionDigits: 1 });

export default function DeviceProperties(properties: Properties) {
  const { t } = useTranslation();
  const [catalog, setCatalog] = useState() as any;
  const [device, setDevice] = useState() as any;
  const [deviceType, setDeviceType] = useState(properties.device) as any;

  useEffect(() => {
    setDevice(properties.device);

    if (properties.device) {
      const call = async () => {
        const newCatalog = await userAPI.getDeviceTypeCatalog();

        setCatalog(newCatalog);
        setDeviceType(newCatalog[properties.device.manufacturer][properties.device.type]);
      };

      call();
    }
  }, [properties.device]);

  return <div>
    {catalog && deviceType
      ?
      <div className='displayFlex gapM'>
        <div>
          {properties.configurationOnly
            ?
            <></>
            :
            <>
              <div className='textHeader'>IoT Device {device._id} {i18next.language}</div>
              <div className='marginTopM marginBottomL'>
                <div>
                  <div className='textL'>State</div>
                  <div className='marginTopM width400'>
                    {deviceType.state.map((field: any) => <div>
                      {(() => {
                        switch (field.type) {
                          case 'enum': return <>
                            <div className="displayFlex marginBottomS">
                              <div className="displayFlex width200"><div className="textM fontWeightBold">{MeasurementTypeI18N.en[field.id]}:</div></div>
                              <div><div className="textM">{device[field.id] ? device[field.id] : '-'}{field.unit ? ` ${field.unit}` : ''}</div></div>
                            </div>
                          </>;
                          case 'decimal': return <>
                            <div className="displayFlex marginBottomS">
                              <div className="displayFlex width200"><div className="textM fontWeightBold">{MeasurementTypeI18N.en[field.id]}:</div></div>
                              <div><div className="textM">{device[field.id] ? numberFormat1.format(device[field.id]) : '-'}{field.unit ? ` ${field.unit}` : ''}</div></div>
                            </div>
                          </>;
                          case 'inteber': return <>
                          <div className="displayFlex marginBottomS">
                            <div className="displayFlex width200"><div className="textM fontWeightBold">{MeasurementTypeI18N.en[field.id]}:</div></div>
                            <div><div className="textM">{device[field.id] ? numberFormat0.format(device[field.id]) : '-'}{field.unit ? ` ${field.unit}` : ''}</div></div>
                          </div>
                        </>;
                        case 'boolean': return <>
                            <div className="displayFlex marginBottomS">
                              <div className="displayFlex width200"><div className="textM fontWeightBold">{MeasurementTypeI18N.en[field.id]}:</div></div>
                              <div><div className="textM">{device[field.id] ? device[field.id] : '-'}{field.unit ? ` ${field.unit}` : ''}</div></div>
                            </div>
                          </>;
                          case 'string':
                          default: return <>
                            <div className="displayFlex marginBottomS">
                              <div className="displayFlex width200"><div className="textM fontWeightBold">{MeasurementTypeI18N.en[field.id]}:</div></div>
                              <div><div className="textM">{device[field.id] ? device[field.id] : '-'}{field.unit ? ` ${field.unit}` : ''}</div></div>
                            </div>
                          </>;
                        }
                      })()}
                    </div>)}
                  </div>
                </div>
              </div>
            </>
          }
          <div className='displayFlex gapM'>
            <div>
              <div className='textL'>Configuration</div>
              <div className='marginTopM width250'>
                {deviceType.configuration.filter((field: any) => properties.hideIDField ? field.id !== 'id' : true)
                  .map((field: any) => <div className='marginBottomS'>
                    {(() => {
                      switch (field.type) {
                        case 'enum': return <>
                          <Dropdown
                            label={field.id}
                            placeholder="Bitte auswählen ..."
                            options={[...field.values.map((x: any) => {
                              return {
                                key: x,
                                text: x,
                              }
                            })]}
                            selectedKey={device[field.id]}
                            onChange={(e: any, item: any) => {
                              const newAsset = { ...device } as any;

                              newAsset[field.id] = item.key;

                              setDevice(newAsset)
                            }}
                            styles={{
                              dropdown: { width: 200 },
                            }}
                          />
                        </>;
                        case 'number': return <>
                          <TextField type="number" label={field.id} value={device[field.id]} suffix={field.unit} onChange={(e: any, value: any) => {
                            const newAsset = { ...device } as any;

                            newAsset[field.id] = value;

                            setDevice(newAsset)
                          }} />
                        </>;
                        case 'decimal': return <>
                          <TextField type="number" label={field.id} value={device[field.id]} suffix={field.unit} onChange={(e: any, value: any) => {
                            const newAsset = { ...device } as any;

                            newAsset[field.id] = value;

                            setDevice(newAsset)
                          }} />
                        </>;
                        case 'boolean': return <>
                          <TextField type="number" label={field.id} value={device[field.id]} suffix={field.unit} onChange={(e: any, value: any) => {
                            const newAsset = { ...device } as any;

                            newAsset[field.id] = value;

                            setDevice(newAsset)
                          }} />
                        </>;
                        case 'string':
                        default: return <>
                          <TextField label={field.id} value={device[field.id]} suffix={field.unit} onChange={(e: any, value: any) => {
                            const newAsset = { ...device } as any;

                            newAsset[field.id] = value;

                            setDevice(newAsset)
                          }} />
                        </>;
                      }
                    })()}
                  </div>)}
              </div>
            </div>
            <div className='flexGrow1 displayFlex flexAlignStart justifyContentEnd'>
              <DefaultButton
                onClick={() => {
                  const configuration: any = {};

                  deviceType.configuration.forEach((field: any) => configuration[field.id] = device[field.id]);

                  properties.applyConfiguration(configuration);
                }}>
                {t('global.apply')}
              </DefaultButton>
            </div>
          </div>
        </div>
      </div>
      :
      <></>}
  </div>;
}
