import { Asset, Building, Cardinality, Checklist, Directionality, Entity, Measurement, Partnership, Query, Role, Site, Task, getEntityType } from "@the-real-insight/tri-model";
import { configuration } from "./Configuration";
import { ApplicationError, UnauthorizedError } from "./general/Errors";
import { AuthError } from "@microsoft/office-js-helpers";

const baseUrl = configuration.serverUrl;
let din276MetaData: any = null;

const getResourceURL = (url: string) => {
    return `${baseUrl}${url}`;
}

const getDIN276Metadata = async () => {
    if (din276MetaData) {
        return din276MetaData;
    }

    const response = await fetch(`${baseUrl}/metadata/din276`, {
        credentials: 'include',
        method: 'GET', headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    if (response.status === 200) {
        return din276MetaData = response.json();
    } else {
        throw new Error('Unspecified error.');
    }
};

const getPlans = async () => {
    const response = await fetch(`${baseUrl}/plans`, {
        credentials: 'include',
        method: 'GET', headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    if (response.status === 200) {
        return response.json();
    } else {
        throw new Error('Unspecified error.');
    }
};

const getCustomer = async (customerId: string) => {
    const response = await fetch(`${baseUrl}/customers/${customerId}`, {
        credentials: 'include',
        method: 'GET', headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    return response.json();
};

const getUsers = async () => {
    const response = await fetch(`${baseUrl}/users`, {
        credentials: 'include',
        method: 'GET', headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    return response.json();
};

const getBridges = async (query: any) => {
    const response = await fetch(`${baseUrl}/bridges`, {
        credentials: 'include',
        method: 'POST', body: JSON.stringify(query), headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    return response.json();
};

const getBuildings = async (query: any) => {
    const response = await fetch(`${baseUrl}/buildings`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    return response.json();
};

const submitRequest = async (client: string, agent: string, profile: string, input: string, context: any) => {
    const response = await fetch(`${baseUrl}/submit`, {
        credentials: 'include',
        method: 'POST', body: JSON.stringify({
            client, agent, profile, input,
            context,
        }),
        headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });
    const data = await response.json();

    data.resources = data.resources ? data.resources.map((resource: any) => {
        resource.queries = (resource.queries || []).map((query: any) => Query.unmarshal(query));

        return resource;
    }) : undefined;

    return data;
};

const triggerEvent = async (id: string) => {
    const response = await fetch(`${baseUrl}/admin/trigger`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ id }),
        headers: {
            'Content-Type': 'application/json',
            //"authorization": "Bearer " + authentication.access_token
        }
    });

    return response.json();
};

const checkEmail = async (email: string, firstName: string, lastName: string) => {
    const response = await fetch(`${baseUrl}/registration/checkEmail`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ email, firstName, lastName }),
        headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    if (response.status === 200) {
        const result: any = await response.json();

        if (result.error) {
            throw new ApplicationError(result.error)
        } else {
            return result;
        }
    } else {
        throw 'Not authenticated.';
    }
};

const checkUser = async (username: string) => {
    const response = await fetch(`${baseUrl}/checkUser`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ email: username }),
        headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    if (response.ok) {
        const result: any = await response.json();

        console.log(result);

        if (result.error) {
            throw new ApplicationError(result.error)
        } else {
            return result;
        }
    } else {
        if (response.status === 401) {
            throw new UnauthorizedError();
        }

        throw new Error();
    }
};


const validateEmail = async (email: string, validationCode: string) => {
    const response = await fetch(`${baseUrl}/registration/validateEmail`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ email, validationCode }),
        headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    if (response.ok) {
        const result: any = await response.json();

        if (result.error) {
            throw new ApplicationError(result.error)
        } else {
            return result;
        }
    } else {
        throw Error('Server error.');
    }
};

const registerAndOrder = async (user: any, customer: any, legalPerson: any, plans: any, paymentMethod: any) => {
    console.log('Plans >>>', plans);
    const response = await fetch(`${baseUrl}/registration/registrationAndOrder`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ user, customer, legalPerson, plans, paymentMethod }),
        headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    if (response.ok) {
        return response.json();
    } else {
        if (response?.status === 401) {
            throw new UnauthorizedError();
        } else {
            throw Error('Server error.');
        }
    }
};

const login = async (email: string, password: string) => {
    const response = await fetch(`${baseUrl}/login`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ email, password }),
        headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    if (response.ok) {
        const result: any = await response.json();

        if (result.error) {
            throw new ApplicationError(result.error)
        } else {
            return result;
        }
    } else {
        if (response.status === 401) {
            throw new UnauthorizedError();
        }

        throw new Error();
    }
};

const checkAuthentication = async () => {
    const response = await fetch(`${baseUrl}/authentication`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    console.log('Response status >>>', response.status);

    if (response.status === 200) {
        return true;
    } else {
        return false;
    }
};

const sendOTP = async () => {
    const response = await fetch(`${baseUrl}/otp`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    if (response.status === 200) {
        return response.json();
    } else {
        throw 'Not authenticated.';
    }
};

const checkOTP = async (otp: string) => {
    const response = await fetch(`${baseUrl}/otp/${otp}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    if (response.status === 200) {
        return response.json();
    } else {
        throw 'Not authenticated.';
    }
};

const updateCredentials = async (userId: string, password: string, mobileNumber: string) => {
    const response = await fetch(`${baseUrl}/users/${userId}/updateCredentials`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ password, mobileNumber }),
        headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    return response.json();
};

const logout = async () => {
    const response = await fetch(`${baseUrl}/logout`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    return response.json();
};

const getCustomerSettings = async (id: string) => {
    const response = await fetch(`${baseUrl}/customers/${id}/settings`, {
        credentials: 'include',
        method: 'GET', headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    return response.json();
}

const getMarkup = async (path: string) => {
    const response = await fetch(`${baseUrl}/markup/${path}`, {
        credentials: 'include',
        method: 'GET', headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    return response.text();
}

// >>> Move to Query

const bindInverseRelationships = (query: Query, rootEntity: any) => {
    return rootEntity;

    // let previousEntityTypeName = query.entityQuery.type.name;
    // let previousEntity = rootEntity;

    // query.linkedEntityQueries.forEach((linkedEntityQuery: LinkedEntityQuery) => {
    //     if (!linkedEntityQuery.relationship) {
    //         return;
    //     }

    //     logDebug('Retrieving relationship ', previousEntityTypeName, linkedEntityQuery.relationship);

    //     const linkedEntity = record.get(`${linkedEntityQuery.name || previousEntityTypeName /* hack */}`).properties;

    //     // Convert big numbers

    //     this.convertBigNums(linkedEntity);

    //     // @ts-ignore
    //     // logDebug('Linked entity ', linkedEntityQuery.type.name);
    //     logDebug(linkedEntity);
    //     // logDebug(previousEntityTypeName);
    //     // logDebug(previousEntity);

    //     let relationship;

    //     if (linkedEntityQuery.direction === RelationshipDirection.out) {
    //         relationship = getRelationship(previousEntityTypeName, linkedEntityQuery.relationship);

    //         // logDebug(relationship);
    //         // logDebug(`-[:${linkedEntityQuery.relationship}]->`);

    //         if (relationship.cardinality === Cardinality.ManyToMany ||
    //             relationship.cardinality === Cardinality.OneToMany) {
    //             previousEntity[relationship.name] = previousEntity[relationship.name] || [];

    //             previousEntity[relationship.name].push(linkedEntity)
    //         } else {
    //             previousEntity[relationship.name] = linkedEntity;
    //         }

    //         // We only set the forward references here. Setting both sides would lead to circular references during marshalling.
    //         // If need be, we can easily bind the reverse relationship on the client

    //         // if (relationship.directionality === Directionality.Bidirectional && relationship.inverseName) {
    //         //     if (relationship.cardinality === Cardinality.ManyToMany ||
    //         //         relationship.cardinality === Cardinality.ManyToOne) {
    //         //         linkedEntity[relationship.inverseName] = linkedEntity[relationship.name] || [];

    //         //         linkedEntity[relationship.inverseName].push(previousEntity)
    //         //     } else {
    //         //         linkedEntity[relationship.inverseName] = previousEntity;
    //         //     }
    //         // }
    //     } else {
    //         // TODO Ugly
    //         // @ts-ignore
    //         relationship = getRelationship(linkedEntityQuery.type.name, linkedEntityQuery.relationship);

    //         // logDebug(relationship);
    //         // logDebug(`<-[:${linkedEntityQuery.relationship}]-`);

    //         // We only set the forward references here. Setting both sides would lead to circular references during marshalling.
    //         // If need be, we can easily bind the reverse relationship on the client

    //         // if (relationship.cardinality === Cardinality.ManyToMany ||
    //         //     relationship.cardinality === Cardinality.OneToMany) {
    //         //     linkedEntity[relationship.name] = linkedEntity[relationship.name] || [];

    //         //     linkedEntity[relationship.name].push(previousEntity)
    //         // } else {
    //         //     linkedEntity[relationship.name] = previousEntity;
    //         // }

    //         if (relationship.directionality === Directionality.Bidirectional && relationship.inverseName) {
    //             if (relationship.cardinality === Cardinality.ManyToMany ||
    //                 relationship.cardinality === Cardinality.ManyToOne) {
    //                 previousEntity[relationship.inverseName] = previousEntity[relationship.inverseName] || [];

    //                 previousEntity[relationship.inverseName].push(linkedEntity)
    //             } else {
    //                 previousEntity[relationship.inverseName] = linkedEntity;
    //             }
    //         }
    //     }

    //     previousEntityTypeName = relationship.getTypeName();
    //     previousEntity = linkedEntity;
    // });
}

// <<<

const query = async (query: Query): Promise<any[]> => {
    const response = await fetch(`${baseUrl}/query`, {
        credentials: 'include',
        method: 'POST', body: JSON.stringify(query.marshal()), headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    if (query.fieldReferences && query.fieldReferences.length > 0) {
        return response.json();
    } else {
        const entityType: any = getEntityType(query.entityQuery.type.name);
        const json: any = await response.json();

        return json.map((properties: any) => bindInverseRelationships(query, new entityType(properties)));
    }
}

const queryOne = async (q: Query): Promise<any[] | null> => {
    const result = await query(q);

    if (!!result) {
        if (result.length > 1) {
            throw new Error('Query yields too few or too many entities.');
        }

        return result[0];
    } else {
        return null;
    }
}

// @deprecated

const queryGraph = async (query: Query): Promise<any[]> => {
    const response = await fetch(`${baseUrl}/graphs/query`, {
        credentials: 'include',
        method: 'POST', body: JSON.stringify(query.marshal()), headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    if (query.fieldReferences) {
        return response.json();
    } else {
        const entityType: any = getEntityType(query.entityQuery.type.name);

        return (await response.json() as any).map((properties: any) => new entityType(properties));
    }
}

//  @deprecated

const queryNoSQL = async (entityTypeName: string, filter: any): Promise<any[]> => {
    const response = await fetch(`${baseUrl}/nosql/query/${entityTypeName}`, {
        credentials: 'include',
        method: 'POST', body: JSON.stringify(filter), headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    const entityType: any = getEntityType(entityTypeName);
    const result = await response.json();

    console.log(entityType, result);

    return result.map((properties: any) => new entityType(properties));
}

const queryTelemetry = async (filter: any) => {
    const response = await fetch(`${baseUrl}/telemetry/query`, {
        credentials: 'include',
        method: 'POST', body: JSON.stringify(filter), headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    return (await response.json() as any).map((properties: any) => new Measurement(properties));
}

const queryZip = async (query: Query): Promise<any> => {
    const response = await fetch(`${baseUrl}/query/zip`, {
        credentials: 'include',
        method: 'POST', body: JSON.stringify(query.marshal()), headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    const x = await response.blob();

    return x;
}

const getEntityByID = async (entityTypeName: string, id: string) => {
    const entityType: any = getEntityType(entityTypeName);
    const response = await fetch(`${baseUrl}/entities/${entityTypeName}/${id}`, {
        credentials: 'include',
        method: 'GET', headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    const properties = await response.json();

    console.log('Properties >>>', properties);

    const entity = new entityType(properties);

    console.log('Entity retrieved >>>', entity);

    return entity;
}

const getSkills = async () => {
    const response = await fetch(`${baseUrl}/skills`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    return response.json();
}

const getCityObjects = async (lat: number, lon: number, radius: number = 500) => {
    const response = await fetch(`${baseUrl}/city-objects?lat=${lat}&lon=${lon}&radius=${radius}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    return response.json();
}

const getCityObject = async (lat: number, lon: number) => {
    const response = await fetch(`${baseUrl}/city-objects/single?lat=${lat}&lon=${lon}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // "authorization": "Bearer " + authentication.access_token
        }
    });

    return response.json();
}

const getDeviceTypeCatalog = async () => {
    const response = await fetch(`${baseUrl}/iot/device-types`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
}

const submitSupportRequest = async (email: string, request: string) => {
    const response = await fetch(`${baseUrl}/support/requests`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ email, request }),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const getRoles = async (customerID: string) => {
    const response = await fetch(`${baseUrl}/${customerID}/roles`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const createSite = async (customerID: string, site: Site) => {
    const response = await fetch(`${baseUrl}/${customerID}/sites`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(site),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const updateSite = async (customerID: string, site: Site) => {
    const response = await fetch(`${baseUrl}/${customerID}/sites`, {
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify(site),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const deleteSite = async (customerID: string, site: Site) => {
    const response = await fetch(`${baseUrl}/${customerID}/sites`, {
        credentials: 'include',
        method: 'DELETE',
        body: JSON.stringify(site),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const createPartnership = async (customerID: string, partnership: Partnership) => {
    const response = await fetch(`${baseUrl}/${customerID}/partnerships`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(partnership),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const updatePartnership = async (customerID: string, partnership: Partnership) => {
    const response = await fetch(`${baseUrl}/${customerID}/partnerships`, {
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify(partnership),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const deletePartnership = async (customerID: string, partnership: Partnership) => {
    const response = await fetch(`${baseUrl}/${customerID}/partnerships`, {
        credentials: 'include',
        method: 'DELETE',
        body: JSON.stringify(partnership),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const createRole = async (customerID: string, role: Role) => {
    const response = await fetch(`${baseUrl}/${customerID}/roles`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(role),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const deleteRole = async (customerID: string, role: Role) => {
    const response = await fetch(`${baseUrl}/${customerID}/roles`, {
        credentials: 'include',
        method: 'DELETE',
        body: JSON.stringify(role),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const updateAsset = async (asset: Asset) => {
    const response = await fetch(`${baseUrl}/assets`, {
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify(asset),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const getProcessDefinitions = async (customerID: string) => {
    const response = await fetch(`${baseUrl}/${customerID}/process-definitions`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const getChecklists = async (customerID: string) => {
    const response = await fetch(`${baseUrl}/${customerID}/checklists`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const createChecklist = async (customerID: string, checklist: Checklist) => {
    const response = await fetch(`${baseUrl}/${customerID}/checklists`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(checklist),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const updateChecklist = async (customerID: string, checklist: Checklist) => {
    const response = await fetch(`${baseUrl}/${customerID}/checklists/${checklist._id}`, {
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify(checklist),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const deleteChecklist = async (customerID: string, checklist: Checklist) => {
    const response = await fetch(`${baseUrl}/${customerID}/checklists/${checklist._id}`, {
        credentials: 'include',
        method: 'DELETE',
        body: JSON.stringify(checklist),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const getTasks = async (customerID: string) => {
    const response = await fetch(`${baseUrl}/${customerID}/tasks`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return (await response.json()).map((task: any) => new Task(task));
};

const getOpenTasks = async (customerID: string) => {
    const response = await fetch(`${baseUrl}/${customerID}/tasks/open`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return (await response.json()).map((task: any) => new Task(task));
};

const activateTask = async (customerID: string, task: Task) => {
    const response = await fetch(`${baseUrl}/${customerID}/tasks/${task._id}/activate`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(task),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const deactivateTask = async (customerID: string, task: Task) => {
    const response = await fetch(`${baseUrl}/${customerID}/tasks/${task._id}/deactivate`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(task),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const deactivateAndSaveTask = async (customerID: string, task: Task) => {
    const response = await fetch(`${baseUrl}/${customerID}/tasks/${task._id}/deactivate-and-save`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(task),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const completeTask = async (customerID: string, task: Task) => {
    const response = await fetch(`${baseUrl}/${customerID}/tasks/${task._id}/complete`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(task),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const abortTask = async (customerID: string, task: Task) => {
    const response = await fetch(`${baseUrl}/${customerID}/tasks/${task._id}/abort`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(task),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

const sampleDXF = async () => {
    const response = await fetch(`${baseUrl}/sample-dxf`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response.json();
};

export const userAPI = {
    getDIN276Metadata,
    getPlans,
    getResourceURL,
    getCustomer,
    getUsers,
    getBridges,
    getBuildings,
    submitRequest,
    triggerEvent,
    checkEmail,
    checkUser,
    validateEmail,
    registerAndOrder,
    login,
    checkAuthentication,
    sendOTP,
    checkOTP,
    updateCredentials,
    logout,
    getCustomerSettings,
    getMarkup,
    query,
    queryOne,
    queryGraph,
    queryNoSQL,
    queryTelemetry,
    queryZip,
    getSkills,
    getEntityByID,
    getCityObjects,
    getCityObject,
    getDeviceTypeCatalog,
    submitSupportRequest,
    createSite,
    updateSite,
    deleteSite,
    createPartnership,
    updatePartnership,
    deletePartnership,
    getRoles,
    createRole,
    deleteRole,
    updateAsset,
    getProcessDefinitions,
    getChecklists,
    createChecklist,
    updateChecklist,
    deleteChecklist,
    getTasks,
    getOpenTasks,
    activateTask,
    deactivateTask,
    deactivateAndSaveTask,
    completeTask,
    abortTask,
    sampleDXF,
}