import '../App.sass';
import { useEffect, useState } from 'react';
import { ActionButton, DefaultButton, PrimaryButton, Text, TextField } from '@fluentui/react';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { userAPI } from '../UserAPI';
import Section from '../components/Section';
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import { Checkbox } from '@fluentui/react-components';
import { useAdministrationAPI } from '../AdministrationAPI';
import Dialog, { DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { EntityQuery, Query, Role, User } from '@the-real-insight/tri-model';
import { dataTableStyles } from '../styles';
import RoleList from '../components/RoleList';
import { useDrop } from 'react-dnd';

interface Properties {
    processError: (error: any) => void;
}

export default function UserManagementPanel(properties: Properties) {
    const { t } = useTranslation();
    const { updateUser, createUser } = useAdministrationAPI();
    const [authentication] = useAtom(authenticationAtom) as any;
    const [users, setUsers] = useState([]);
    const [userDialogOpen, setUserDialogOpen] = useState(false);
    const [roleDialogOpen, setRoleDialogOpen] = useState(false);
    const [editedUser, setEditedUser] = useState({}) as any;
    const [roles, setRoles] = useState([]) as any;

    interface DroppableProperties {
        accept?: any;
        children?: any;
        theme?: any;
        onDrop: (payload: any) => void
    }

    const Droppable = (properties: DroppableProperties) => {
        const [{ isOver }, drop] = useDrop(() => ({
            accept: properties.accept || 'BOX',
            drop: properties.onDrop,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }));

        return <div
            ref={drop}
            style={{ backgroundColor: isOver ? 'rgba(255, 0, 0, 0.05)' : 'transparent' }}
        >
            {properties && properties.children
                ?
                (properties.children)
                :
                <></>
            }
        </div>;
    }

    const toggleUserDialogOpen = () => {
        setUserDialogOpen(!userDialogOpen);
    }

    const columns = [
        {
            name: t('settingsPanel.userManagementPanel.user'),
            selector: (user: User) => user.lastName,
            width: '300px',
            cell: (user: any) => (
                <Persona {...{
                    text: `${user.firstName} ${user.lastName}`,
                    secondaryText: user.email,
                    imageInitials: `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`,
                }} text={`${user.firstName} ${user.lastName}`} size={PersonaSize.size40} />
            ),
            sortable: true,
        }, {
            name: 'Status',
        }, {
            name: t('settingsPanel.userManagementPanel.roles'),
            cell: (user: any) => (
                <Droppable accept={['role']} onDrop={(item: any) => {
                    console.log('Dropping >>>', item);
                }}>
                    <div className="displayFlex justifyContentCenter">
                        {/* <Checkbox checked={user.roles && user.roles.indexOf('administrator') >= 0}
                            disabled={authentication.id === user.id}
                            onChange={async (e: any, value: any) => {
                                try {
                                    user.roles = user.roles || [];

                                    if (value && user.roles.indexOf('administrator') < 0) {
                                        user.roles.push('administrator')
                                    } else {
                                        user.roles = user.roles.filter((role: string) => role !== 'administrator')
                                    }

                                    await updateUser(authentication.customer, user);
                                } catch (error) {
                                    properties.processError(error);
                                }
                            }} /> */}
                    </div>
                </Droppable>
            )
        }, {
            name: t('settingsPanel.userManagementPanel.invalidate'),
            cell: (user: any) => (user.id !== authentication.id
                ?
                <div className="displayFlex justifyContentCenter">
                    <ActionButton iconProps={{ iconName: 'Delete' }} >
                    </ActionButton>
                </div>
                :
                <></>
            ),
        },
    ];

    useEffect(() => {
        const call = async () => {
            try {
                setUsers(await userAPI.getUsers());
            } catch (error) {
                properties.processError(error);
            }
        };

        call();
    }, []);

    useEffect(() => {
        const call = async () => {
            console.log('Roles Hierarchy >>>', await userAPI.getRoles(authentication.customer));

            const newRoles = await userAPI.query(new Query(new EntityQuery(Role.type, [], undefined)));

            setRoles(newRoles);
        };

        call();
    }, []);

    return <><div className="displayFlex gapM">
        <Section title={t('settingsPanel.userManagementPanel.teamMembers')}>
            <div className="width600 displayFlex alignItemsCenter">
                <ActionButton iconProps={{ iconName: 'AddFriend' }}
                    onClick={toggleUserDialogOpen}>
                    {t('settingsPanel.userManagementPanel.inviteUser')}
                </ActionButton>
                <div className="flexGrow1 displayFlex alignItemsCenter justifyContentEnd">
                    <ActionButton iconProps={{ iconName: 'AddFriend' }}
                        onClick={() => setRoleDialogOpen(true)}>
                        {t('settingsPanel.userManagementPanel.assignRoles')}
                    </ActionButton>
                </div>
            </div>
            <div className='borderNeutral'>
                <DataTable
                    data={users}
                    columns={columns}
                    customStyles={dataTableStyles}
                    selectableRows
                    pagination
                />
            </div>
        </Section>
        <Section title={t('settingsPanel.userManagementPanel.roles')}>
            <RoleList className='borderNeutral width400' showParameterizedRoles showDelegatedRoles></RoleList>
        </Section>
    </div>
        <Dialog
            hidden={!userDialogOpen}
            onDismiss={toggleUserDialogOpen}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.userManagementPanel.inviteUser'),
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <TextField label={t('settingsPanel.userManagementPanel.email')} value={editedUser.email} onChange={(e: any, email: any) => setEditedUser({ ...editedUser, email })}
                onGetErrorMessage={(value: string): string => {
                    return value && value.length > 0 ? '' : t('settingsPanel.userManagementPanel.emailNotValid');
                }}
                validateOnFocusOut />
            <TextField label={t('settingsPanel.userManagementPanel.firstName')} value={editedUser.firstName} onChange={(e: any, firstName: any) => setEditedUser({ ...editedUser, firstName })}
                onGetErrorMessage={(value: string): string => {
                    return value && value.length > 0 ? '' : t('settingsPanel.userManagementPanel.firstNameNotValid');
                }}
                validateOnFocusOut />
            <TextField label={t('settingsPanel.userManagementPanel.lastName')} value={editedUser.lastName} onChange={(e: any, lastName: any) => setEditedUser({ ...editedUser, lastName })}
                onGetErrorMessage={(value: string): string => {
                    return value && value.length > 0 ? '' : t('settingsPanel.userManagementPanel.lastNameNotValid');
                }}
                validateOnFocusOut />
            <DialogFooter className="marginTopXXL">
                <PrimaryButton text={t('settingsPanel.userManagementPanel.invite')} onClick={async () => {
                    try {
                        await createUser(authentication.customer, editedUser);

                        setUsers(await userAPI.getUsers());
                        setEditedUser({});
                        toggleUserDialogOpen();
                    } catch (error) {
                        properties.processError(error);
                    }
                }} />
                <DefaultButton onClick={() => {
                    toggleUserDialogOpen();
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
        <Dialog
            hidden={!roleDialogOpen}
            onDismiss={() => setRoleDialogOpen(false)}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.userManagementPanel.assignRole'),
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <div>
                <div className='displayFlex gapM alignItemsCenter'>
                    <Checkbox checked={false}
                        onChange={async (e: any, value: any) => {
                        }} />
                    <div>
                        <div className='textM fontWeightBold'>Data Manager</div>
                        <div className="textM">Can commission and maintain device assets</div>
                    </div>
                </div>
                <div className='marginTopS displayFlex gapM alignItemsCenter'>
                    <Checkbox checked={false}
                        onChange={async (e: any, value: any) => {
                        }} />
                    <div>
                        <div className='textM fontWeightBold'>Data Analyst</div>
                        <div className="textM">Can commission and maintain device assets</div>
                    </div>
                </div>
                <div className='marginTopS displayFlex gapM alignItemsCenter'>
                    <Checkbox checked={false}
                        onChange={async (e: any, value: any) => {
                        }} />
                    <div>
                        <div className='textM fontWeightBold'>Asset Manager</div>
                        <div className="textM">Can commission and maintain device assets</div>
                    </div>

                </div>
                <div className='marginTopS displayFlex gapM alignItemsCenter'>
                    <Checkbox checked={false}
                        onChange={async (e: any, value: any) => {
                        }} />
                    <div>
                        <div className='textM fontWeightBold'>Administrator</div>
                        <div className="textM">Can do all operations on a customer</div>
                    </div>
                </div>

            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton text={t('settingsPanel.userManagementPanel.invite')} onClick={async () => {
                    try {
                        setRoleDialogOpen(false);
                    } catch (error) {
                        properties.processError(error);
                    }
                }} />
                <DefaultButton onClick={() => {
                    setRoleDialogOpen(false);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
    </>;
}