import '../App.sass';
import { useEffect, useState } from 'react';
import { userAPI } from '../UserAPI';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

class Properties {
    building: any;
}

export default function UtilizationProperties(properties: Properties) {
    const { t } = useTranslation();
    const [chartOptions, setChartOptions] = useState() as any;
    const [series, setSeries] = useState() as any;

    useEffect(() => {
        const call = async () => {
            let peopleCountSeries = await userAPI.queryTelemetry({ metric: 'peopleCount' });
            let outsideTemperatureSeries = await userAPI.queryTelemetry({ metric: 'outsideTemperature' });

            // TODO Hack
            
            peopleCountSeries = peopleCountSeries.filter((obj1: any, i: number) => peopleCountSeries.findIndex((obj2: any) => (new Date(obj2.timestamp).getTime() === new Date(obj1.timestamp).getTime())) === i);
            outsideTemperatureSeries = outsideTemperatureSeries.filter((obj1: any, i: number) => outsideTemperatureSeries.findIndex((obj2: any) => (new Date(obj2.timestamp).getTime() === new Date(obj1.timestamp).getTime())) === i);

            setSeries([{
                name: 'People Count',
                data: peopleCountSeries.map((measurement: any) => measurement.value),
            }, {
                name: 'Outside Temperature',
                data: outsideTemperatureSeries.map((measurement: any) => measurement.value),
            }]);
            setChartOptions({
                chart: {
                    height: 350,
                    type: 'area'
                },
                dataLabels: {
                    enabled: false
                },
                colors: ['#649aaa', '#576071'],
                stroke: {
                    curve: 'smooth',
                    width: [1, 1],
                },
                xaxis: {
                    type: 'string',
                    categories: peopleCountSeries.map((measurement: any) => `${new Date(measurement.timestamp).toLocaleDateString()}`)
                },
                yaxis: [{
                    seriesName: 'People Count',
                    min: 0,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#00288E'
                    },
                    labels: {
                        style: {
                            colors: '#00288E',
                        },
                        formatter: (val: number) => {
                            return val.toFixed(1)
                        },
                    },
                    title: {
                        text: "People Count",
                        style: {
                            color: '#00288E',
                        }
                    }
                },
                {
                    seriesName: 'Outside Temperature',
                    opposite: true,
                    min: 0,
                    max: 50,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#576071'
                    },
                    labels: {
                        style: {
                            colors: '#576071',
                        },
                        formatter: (val: number) => {
                            return val.toFixed(0)
                        },
                    },
                    title: {
                        text: "Outside Temperature (°C)",
                        style: {
                            color: '#576071',
                        }
                    },
                },
                ],
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            })
        };

        call();
    }, []);

    return <div>
        <div className="marginTopM headerL">{t('buildingProperties.utilizationPropertiesPanel.peopleCount')}</div>
        {
            chartOptions && series && series.length && (series[0].data.length > 0)
                ?
                <>
                    <Chart options={chartOptions} series={series} type="area" height={400}/>
                </>
                :
                <div className='marginTopM'>{t('global.noInformationAvailable')}</div>
        }
    </div>;
}