import '../App.sass';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ActionButton, ChoiceGroup, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, elementContains, FontIcon, Icon, IconButton, IDropdownOption, Label, Pivot, PivotItem, PrimaryButton, Rating, TextField } from '@fluentui/react';
import { userAPI } from '../UserAPI';
import { useTranslation } from 'react-i18next';
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import DataTable from 'react-data-table-component';
import { Address, LegalPerson, EntityQuery, Project, Query, LinkedEntityQuery, RelationshipDirection, is, Building, Floor, Role, RoleType, or, Customer, Site, and, Partnership, from } from '@the-real-insight/tri-model';
import { dataTableStyles } from '../styles';
import { useAdministrationAPI } from '../AdministrationAPI';
import { FileUpload } from '../components/FileUpload';
import AddressPicker from '../components/AddressPicker';
import LegalPersonPicker from '../components/LegalPersonPicker';
import LoadingIndicator from '../components/LoadingIndicator';
import AssetList from '../components/AssetList';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import TreeView, { flattenTree } from 'react-accessible-treeview';
import { IoMdArrowDropright } from "react-icons/io";
import cx from "classnames";
import RoleList from '../components/RoleList';
import { useCustomerTheme } from '../Theme';

const iconStyle = {
    fontSize: 30,
    height: 30,
    width: 30,
    margin: '0 15px',
};

interface Properties {
    processError: (error: any) => void;
}

const numberFormat = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 });

enum BuildingPart {
    wholeBuilding = 'wholeBuilding',
    partOfBuilding = 'partOfBuilding'
}

export default function ProjectPanel(properties: Properties) {
    const { t } = useTranslation();
    const { theme } = useCustomerTheme();
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const [authentication] = useAtom(authenticationAtom) as any;
    const { getProjects, createProject, updateProject, deleteProjectEntitlement } = useAdministrationAPI();
    const [projects, setProjects] = useState([]) as any;
    const [selectedProject, setSelectedProject] = useState() as any;
    const [partnerships, setPartnerships] = useState([]) as any;
    const [emailPrimaryContact, setEmailPrimaryContact] = useState('') as any;
    const [documents, setDocuments] = useState([]) as any;
    const [loadingProjects, setLoadingProjects] = useState(false) as any;
    const [loadingEntitlements, setLoadingEntitlements] = useState(false) as any;
    const [tab, setTab] = useState(searchParams.get("tab") || 'project') as any;
    const [documentType, setDocumentType] = useState('unknown');
    const [documentVisibility, setDocumentVisibility] = useState(['_self']);
    const [objectSelectDialogOpen, setObjectSelectDialogOpen] = useState(false);
    const [partnerSelectDialogOpen, setPartnerSelectDialogOpen] = useState(false);
    const [addDocumentDialogOpen, setAddDocumentDialogOpen] = useState(false);
    const [address, setAddress] = useState() as any;
    const [client, setClient] = useState() as any;
    const [selectedEntitlement, setSelectedEntitlement] = useState() as any;
    const [newSupplier, setNewSupplier] = useState() as any;
    const [ownEntitlement, setOwnEntitlement] = useState() as any;
    const [buildingPart, setBuildingPart] = useState(BuildingPart.wholeBuilding) as any;
    const [selectedFile, setSelectedFile] = useState() as any;
    const [building, setBuilding] = useState([]) as any;
    const [floors, setFloors] = useState([]) as any;
    const [selectedFloors, setSelectedFloors] = useState([]) as any;
    const [siteName, setSiteName] = useState('') as any;
    const [floorName, setFloorName] = useState('') as any;
    const [selectedSite, setSelectedSite] = useState() as any;
    const [expandedIds, setExpandedIds] = useState([]) as any;
    const [roles, setRoles] = useState([]) as any;
    const [sites, setSites] = useState([]) as any;
    const [siteTreeData, setSiteTreeData] = useState(flattenTree({ name: '', children: [] })) as any;
    const [partnerTreeData, setPartnerTreeData] = useState(flattenTree({ name: '', children: [] })) as any;

    const refreshRoles = async (sites: Site[]) => {
        const newRoles = await userAPI.query(from(Role.type));

        setRoles(newRoles);
        setSiteTreeData(buildSiteTree(sites, newRoles));
    };

    const buildSiteTree = (sites: any, roles: Role[]) => {
        const generalNode = {
            name: 'Standortübergreifend',
            children: roles.filter((role: Role) => role.type === RoleType.role)
                .map((role: Role) => ({
                    name: role.name,
                    children: [],
                    metadata: role,
                })),
            metadata: {
                nodeType: 'global',
                name: 'Standortübergreifend',
            }
        }
        const siteMap = sites.reduce((map: any, site: any) => {
            map[site._id] = {
                name: `${site.name}`,
                children: [],
                metadata: { ...site, nodeType: 'site' }
            }

            return map;
        }, {});

        const roleMap: any = {};

        roles.filter((role: Role) => role.type === RoleType.parameterizedRole)
            .forEach((role: Role) => {
                if (!!role._id && !!siteMap[role.parameterizedBy['Site']]) {
                    const roleNode = {
                        name: `${role.name}`,
                        children: [],
                        metadata: { ...role, nodeType: 'role' }
                    };
                    roleMap[role._id] = roleNode;
                    siteMap[role.parameterizedBy['Site']].children.push(roleNode);
                }
            });

        roles.filter((role: Role) => role.type === RoleType.partnerDelegation)
            .forEach((role: Role) => {
                if (!!role._id && !!roleMap[role.principal]) {
                    roleMap[role.principal].children.push({
                        name: `${role.name}`,
                        children: [],
                        metadata: { ...role, nodeType: 'partner' }
                    });
                }
            });

        return flattenTree({
            name: '',
            children: [generalNode, ...Object.values(siteMap) as any]
        });
    }

    const buildPartnerTree = (partnerships: Partnership[]) => {
        return flattenTree({
            name: "",
            children: partnerships.map((partnership: Partnership) => ({
                // @ts-ignore
                name: partnership.supplier.name,
                metadata: {
                    customer: partnership.customer,
                    supplier: partnership.supplier,
                } as any
            })),
        });
    };

    const addRoleToSite = async (role: any, site: any) => {
        console.log(role, site);

        const result = await userAPI.createRole(authentication.customer, Role.fromTemplate(role, 'Site', site._id, site.name));

        console.log(result);

        refreshRoles(sites);
    };

    const addPartnerToRole = async (partner: Partnership, role: any) => {
        const result = await userAPI.createRole(authentication.customer, Role.partnerDelegation(role,
            new Customer(partner.customer),
            new Customer(partner.supplier)));

        console.log(result);

        refreshRoles(sites);
    };

    const removeRole = async (role: any) => {
        await userAPI.deleteRole(authentication.customer, role);

        refreshRoles(sites);
    };

    // TODO Needed?

    useEffect(() => {
        const call = async () => {
            refreshRoles(sites);
        };

        call();
    }, []);

    interface DraggableProperties {
        children?: any;
        item?: any;
        type?: any;
    }

    const Draggable = (properties: DraggableProperties) => {
        const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
            type: properties.type || 'BOX',
            item: properties.item,
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            })
        }))

        return <>
            <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
                <div ref={drag} >
                    {properties && properties.children
                        ?
                        (properties.children)
                        :
                        <></>
                    }
                </div>
            </div>
        </>;
    }

    interface DroppableProperties {
        accept?: any;
        children?: any;
        theme?: any;
        onDrop: (payload: any) => void
    }

    const Droppable = (properties: DroppableProperties) => {
        const [{ isOver }, drop] = useDrop(() => ({
            accept: properties.accept || 'BOX',
            drop: properties.onDrop,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }));

        return <div
            ref={drop}
            style={{ backgroundColor: isOver ? 'rgba(255, 0, 0, 0.05)' : 'transparent' }}
        >
            {properties && properties.children
                ?
                (properties.children)
                :
                <></>
            }
        </div>;
    }

    const columns = [{
        name: t('settingsPanel.projectsPanel.name'),
        selector: (project: Project) => project.name,
        width: '300px',
        cell: (product: any) => <span>{product.name}</span>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.ownership'),
        selector: (project: Project) => project.name,
        width: '120px',
        cell: (project: any) => (authentication.customer === project._tenant ?
            <span className='textS colorPrimary fontWeightBold'>EIGENTÜMER</span>
            :
            <span className='textS colorContrast fontWeightBold'>PARTNER</span>),
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.customer'),
        width: '300px',
        cell: (project: any) => project._company ? <div>
            <div>{project._company.name}</div><div>{project._company.headquarters.city}</div></div> : <span></span>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.objects'),
        width: '150px',
        cell: (product: Project) => <span></span>,
        sortable: true,
    }];

    const partnerColumns = [{
        name: '',
        width: '50px',
        cell: (partnership: Partnership) => <div className='displayFlex gapS'>
            <ActionButton disabled={!ownsSelectedProject()} iconProps={{ iconName: 'Delete' }}
                onClick={() => {
                    setPartnerships(partnerships.filter((entry: any) => entry._id !== partnership._id));
                }}>
            </ActionButton>
        </div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.partner'),
        width: '300px',
        cell: (partnership: any) => <span>{partnership.supplier.name}</span>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.address'),
        selector: (partnership: any) => partnership.supplier.headquarters.street,
        width: '300px',
        cell: (partnership: any) => <div>{partnership.supplier.headquarters.street} {partnership.supplier.headquarters.streetNumber}, {partnership.supplier.headquarters.postalCode} {partnership.supplier.headquarters.city}</div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.primaryContact'),
        selector: (partnership: any) => partnership.emailPrimaryContact,
        width: '150px',
        cell: (partnership: any) => <div><a href={`mailto:${partnership.emailPrimaryContact}`}>{partnership.emailPrimaryContact}</a></div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.addedBy'),
        width: '200px',
        cell: (partner: any) => <div></div>,
        sortable: true,
    }];

    const siteColumns = [{
        name: '',
        width: '50px',
        cell: (site: any) => <div>
            <ActionButton disabled={!ownsSelectedProject()} iconProps={{ iconName: 'Delete' }}
                onClick={() => {
                    setSites(sites.filter((checkSite: Site) => checkSite._id !== site._id));
                }}>
            </ActionButton>
        </div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.address'),
        selector: (site: any) => site.street,
        width: '400px',
        cell: (site: Site) => <div>{site.street} {site.streetNumber}, {site.postalCode} {site.city}</div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.space'),
        width: '200px',
        cell: (site: any) => <div><>{site.buildings.map((building: Building) => <div className='displayFlex alignItemsCenter'>
            <span>{t('settingsPanel.projectsPanel.wholeBuilding')}</span>
            <IconButton iconProps={{ iconName: 'OpenInNewWindow' }} onClick={() => navigate(`/entity?selectedEntityType=Building&selectedEntity=${building._id}`)} />
        </div>
        )}
        </>
            {/* <>{site.floors.map((floor: Floor) => <div className='displayFlex alignItemsCenter'>
                <span>{floor.name}</span>
                <IconButton iconProps={{ iconName: 'OpenInNewWindow' }} onClick={() => navigate(`/entity?selectedEntityType=Floor&selectedEntity=${floor._id}`)} />

            </div>
            )}</> */}
        </div>,
        sortable: true,
    }];

    const dummyDocument = {
        url: '/dummy',
        title: 'Bestellung',
        synopsis: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante ante, consequat a sagittis eu, sollicitudin ac neque. Nulla pulvinar libero non sem semper, sit amet dictum erat efficitur. Sed sollicitudin enim vitae velit accumsan vestibulum ac id ligula. Sed sed enim vel arcu mollis aliquet. Aenean et tellus nisl. Quisque vel lobortis sem, sed lacinia sapien. In non vulputate velit. Nulla eu interdum enim, ut faucibus sapien. Nullam vestibulum quam eu facilisis congue. Suspendisse sollicitudin vitae nisi id viverra.',
    };

    const documentColumns = [{
        name: '',
        width: '50px',
        cell: (document: any) => <div>
            <ActionButton disabled={!ownsSelectedProject()} iconProps={{ iconName: 'Document' }}
                onClick={() => {
                }}>
            </ActionButton>
        </div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.title'),
        selector: (object: any) => object.building.address.street,
        width: '150px',
        cell: (document: any) => <div>{document.title}</div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.received'),
        selector: (document: any) => new Date().getTime(),
        width: '150px',
        cell: (document: any) => <div>{new Date().toLocaleDateString()}</div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.summary'),
        width: '600px',
        cell: (document: any) => <div className='textXS'>{document.synopsis}</div>,
        sortable: true,
    }];

    const selectProject = async (project: any) => {
        console.log('Selected project >>>', project);

        if (!project) {
            setSelectedProject(null);

            return;
        }

        setSelectedProject(project);

        // TODO All graph traversal should be done server-side via mongoose

        const newSites = [];

        for (const id of project.sites) {
            const newSite: any = await userAPI.queryOne(from(Site.type).where(is('_id').equal(id)));

            newSite.buildings = await userAPI.query(from(Building.type).where(
                is('id').equal(`${newSite.street} ${newSite.streetNumber}, ${newSite.postalCode} ${newSite.city}, ${newSite.country}`)));

            console.log('Buildings >>>', newSite.buildings);

            newSites.push(newSite);
        }

        setSites(newSites);

        const newPartnerships = [];

        for (const id of project.partnerships) {
            const newPartnership: any = await userAPI.queryOne(from(Partnership.type).where(is('_id').equal(id)));

            newPartnership.customer = await userAPI.queryOne(from(LegalPerson.type).where(is('_id').equal(newPartnership.customer)).link('headquarters', RelationshipDirection.out, Address.type));
            newPartnership.supplier = await userAPI.queryOne(from(LegalPerson.type).where(is('_id').equal(newPartnership.supplier)).link('headquarters', RelationshipDirection.out, Address.type));

            console.log('New Partnership >>>', newPartnership);

            newPartnerships.push(newPartnership);
        }

        setPartnerships(newPartnerships);
        setPartnerTreeData(buildPartnerTree(newPartnerships));

        if (project._id) {
            setProjectParam(project._id);
        }
    };

    // TODO Hack

    useEffect(() => {
        refreshRoles(sites);
    }, [sites]);

    useEffect(() => {
        if (selectedProject) {
            if (selectedProject._id) {
                setLoadingEntitlements(true);

                const call = async () => {
                    try {
                        const documentMap: any = {};

                        for (const document of (selectedProject.documents || [])) {
                            documentMap[document.url] = { ...document };
                        }

                        // Dummy document

                        if (Object.values(documentMap).length === 0) {
                            documentMap[dummyDocument.url] = dummyDocument;
                        }

                        setDocuments(Object.values(documentMap));
                        refreshRoles(sites);

                        // RESTfulness

                        setSelectedSite(sites.find((site: any) => site._id === searchParams.get("site")));
                    } catch (error) {
                        properties.processError(error);
                    } finally {
                        setLoadingEntitlements(false);
                    }
                };

                call();
            }
        } else {
            setLoadingProjects(true);

            const call = async () => {
                try {
                    const projects = await getProjects();

                    for (const project of projects) {
                        const companies = await userAPI.query(new Query(new EntityQuery(LegalPerson.type, [], undefined,
                            is('_id').equal(project.customer)), [new LinkedEntityQuery('headquarters', RelationshipDirection.out, Address.type)]));
                        project._company = companies[0];
                    }

                    setProjects(projects);

                    const projectId = searchParams.get("project");

                    if (!!projectId) {
                        selectProject(projects.find((project: any) => project._id === projectId));
                    }
                } catch (error) {
                    properties.processError(error);
                } finally {
                    setLoadingProjects(false);
                }
            };

            call();
        }
    }, [selectedProject]);

    const documentTypeOptions: IDropdownOption[] = [
        { key: 'unknown', text: t('settingsPanel.projectsPanel.unknown') },
        { key: 'contract', text: t('settingsPanel.projectsPanel.contract') },
        { key: 'order', text: t('settingsPanel.projectsPanel.order') },
        { key: 'invoice', text: t('settingsPanel.projectsPanel.invoice') },
        { key: 'productManual', text: t('settingsPanel.projectsPanel.productManual') },
    ];

    const onDocumentsUpload = async (files: FileList) => {
        if (!files || files.length === 0) {
            return;
        }

        console.log('Files >>>', files);

        setSelectedFile(files[0]);
        setAddDocumentDialogOpen(true);

        try {
            const formData = new FormData();

            for (let i = 0; i < files.length; ++i) {
                if (files.item(i) !== null) {
                    // @ts-ignore
                    formData.append(files.item(i).name, files.item(i));
                }
            }

            // const result = await ingestDocuments(authentication.customer, documentType, formData);

            // console.log('Result >>>', result);
        } catch (error) {
            properties.processError(error);
        }
    }

    const setTabParam = (value: any) => {
        const url = new URL(window.location.href);

        url.searchParams.set('tab', value.key.substring(1));
        window.history.pushState(null, '', url.toString());

        setTab(value.key.substring(1));
    }

    const setProjectParam = (id: any) => {
        const url = new URL(window.location.href);

        url.searchParams.set('project', id);
        window.history.pushState(null, '', url.toString());
    }

    const setSiteParam = (id: any) => {
        const url = new URL(window.location.href);

        url.searchParams.set('site', id);
        window.history.pushState(null, '', url.toString());
    }

    const isValidEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const isValid = () => {
        return selectedProject.name && selectedProject.name.trim().length > 0 &&
            selectedProject.description && selectedProject.description.trim().length > 0 &&
            selectedProject.startDate &&
            selectedProject.customer &&
            client;
    }

    const isNewSupplierValid = () => {
        return newSupplier &&
            emailPrimaryContact && emailPrimaryContact.length > 0 && isValidEmail(emailPrimaryContact);
    }

    const ownsSelectedProject = () => {
        return !selectedProject._tenant || selectedProject._tenant === authentication.customer;
    }

    const canAdministrateSelectedProject = () => {
        return ownEntitlement && ownEntitlement.roles.indexOf('administration') >= 0;
    }

    const isBuildingPartComplete = () => {
        return !!address && (buildingPart === BuildingPart.partOfBuilding ? floorName || selectedFloors.length > 0 : true);
    }

    return <><div>
        {selectedProject
            ?
            <div>
                <div className='displayFlex alignItemsCenter'>
                    <div className='flexGrow1 displayFlex textL'>
                        {selectedProject.name}
                    </div>
                    <div className='displayFlex gapM'>
                        {ownsSelectedProject() || canAdministrateSelectedProject()
                            ?
                            (selectedProject && selectedProject._id
                                ?
                                <PrimaryButton disabled={!isValid()} onClick={async () => {
                                    try {
                                        selectedProject.documents = [...documents]
                                            .filter((document: any) => document.url !== dummyDocument.url);
                                        selectedProject.sites = sites.map((site: Site) => site._id);
                                        selectedProject.partnerships = partnerships.map((partnership: Partnership) => partnership._id);

                                        console.log('Update project >>>', selectedProject);

                                        await updateProject(selectedProject);

                                        setSelectedProject(null);
                                    } catch (error) {
                                        // TODO Process error
                                        console.log('process error', error);
                                    }
                                }}>
                                    {t('global.save')}
                                </PrimaryButton>
                                :
                                <PrimaryButton disabled={!isValid()} onClick={async () => {
                                    try {
                                        selectedProject.documents = [...documents]
                                            .filter((document: any) => document.url !== dummyDocument.url);
                                        selectedProject.sites = sites.map((site: Site) => site._id);
                                        selectedProject.partnerships = partnerships.map((partnership: Partnership) => partnership._id);

                                        await createProject(selectedProject);

                                        setSelectedProject(null);
                                    } catch (error) {
                                        // TODO Process error
                                        console.log('process error', error);
                                    }
                                }}>
                                    {t('global.create')}
                                </PrimaryButton>
                            )
                            :
                            <></>
                        }
                        <DefaultButton
                            onClick={() => {
                                setSelectedProject(null);
                            }}>
                            {t('global.close')}
                        </DefaultButton>
                    </div>
                </div>
                <div className="marginTopM ">
                    <Pivot aria-label="" onLinkClick={setTabParam} selectedKey={tab}>
                        <PivotItem id="project" headerText={t('settingsPanel.projectsPanel.general')}>
                            <div className='width1200'>
                                <div className="width500">
                                    <TextField required disabled={!ownsSelectedProject()} className="marginTopL" label={t('settingsPanel.projectsPanel.name')} value={selectedProject.name} onChange={(e: any, name: any) => setSelectedProject({ ...selectedProject, name })}
                                        style={{ width: '300px' }} />
                                    <TextField required disabled={!ownsSelectedProject()} label={t('settingsPanel.projectsPanel.description')} value={selectedProject.description} onChange={(e: any, description: any) => setSelectedProject({ ...selectedProject, description })}
                                        style={{ width: '300px' }} multiline />
                                    <div className="displayFlex gapM">
                                        <DatePicker disabled={!ownsSelectedProject()} label={t('settingsPanel.projectsPanel.startDate')}
                                            styles={{ root: { width: 300 } }}
                                            value={new Date(selectedProject.startDate)}
                                            onSelectDate={(date: any) => setSelectedProject({ ...selectedProject, startDate: new Date(date) })}
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        //strings={defaultDatePickerStrings}
                                        />
                                        <DatePicker disabled={!ownsSelectedProject()} label={t('settingsPanel.projectsPanel.endDate')}
                                            styles={{ root: { width: 300 } }}
                                            value={selectedProject.endDate}
                                            onSelectDate={(date: any) => setSelectedProject({ ...selectedProject, endDate: new Date(date) })}
                                        />
                                    </div>
                                    <div>
                                        <div className='marginTopL textL'>{t('settingsPanel.projectsPanel.customer')}</div>
                                        {client
                                            ?
                                            <div className='marginTopM'>
                                                <div className='textM fontWeightBold'>{client.name}</div>
                                                <div className='textM'>{client.headquarters.street} {client.headquarters.streetNumber}</div>
                                                <div className='textM'>{client.headquarters.postalCode} {client.headquarters.city}, {client.headquarters.country}</div>
                                                {client.tradeRegisterId && client.tradeRegisterCity
                                                    ?
                                                    <div className='textM'>{client.tradeRegisterId}, Handelsregister {client.tradeRegisterCity}</div>
                                                    :
                                                    <></>
                                                }
                                                <ActionButton disabled={!ownsSelectedProject()} iconProps={{ iconName: 'Edit' }} onClick={() => {
                                                    setClient(null);
                                                    setSelectedProject({ ...selectedProject, customer: undefined });
                                                }}>
                                                    {t('global.change')}
                                                </ActionButton>
                                            </div>
                                            :
                                            <LegalPersonPicker className='marginTopM' setLegalPerson={(legalPerson: any) => {
                                                console.log(legalPerson);
                                                setClient(legalPerson);

                                                if (legalPerson) {
                                                    setSelectedProject({ ...selectedProject, customer: legalPerson._id });
                                                } else {
                                                    setSelectedProject({ ...selectedProject, customer: undefined });
                                                }
                                            }} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem id="objects" headerText={t('settingsPanel.projectsPanel.sites')}>
                            <div>
                                <div className='marginTopL'>{t('settingsPanel.projectsPanel.buildingsSpacesDetails')}:</div>
                                <div className='marginTopL'>
                                    {<ActionButton disabled={!ownsSelectedProject()} iconProps={{ iconName: 'Add' }}
                                        onClick={() => {
                                            setBuilding(null);
                                            setFloorName('');
                                            setSelectedFloors([]);
                                            setObjectSelectDialogOpen(true);
                                        }}>
                                        {t('settingsPanel.projectsPanel.addSite')}
                                    </ActionButton>}
                                </div>
                                <div className='borderNeutral'>
                                    <DataTable
                                        data={sites || []}
                                        columns={siteColumns}
                                        customStyles={dataTableStyles}
                                        selectableRows
                                        selectableRowsSingle
                                        onSelectedRowsChange={({ selectedRows }) => {
                                            if (selectedRows && selectedRows.length > 0) {
                                                setSelectedSite(selectedRows[0]);
                                                setSiteParam(selectedRows[0]._id);
                                            } else {
                                                setSelectedSite(null);
                                            }
                                        }}
                                        pagination
                                        paginationPerPage={5}
                                        noDataComponent={<LoadingIndicator loading={false} text={t('settingsPanel.projectsPanel.noObjectsDefined')}></LoadingIndicator>}
                                    />
                                </div>
                                {selectedSite
                                    ?
                                    <div>
                                        <div className="marginTopL textL">{t('settingsPanel.projectsPanel.assets')}</div>
                                        <AssetList className="marginTopM" scopeBuildings={selectedSite.building ? [selectedSite.building] : []} scopeFloors={selectedSite.floors}></AssetList>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </PivotItem>
                        <PivotItem id="documents" headerText={t('settingsPanel.projectsPanel.documents')}>
                            <div>
                                <div className="marginTopXL">
                                    <FileUpload onUpload={onDocumentsUpload}></FileUpload>
                                </div>
                                <div className='marginTopL borderNeutral'>
                                    <DataTable
                                        data={documents}
                                        columns={documentColumns}
                                        customStyles={dataTableStyles}
                                        pagination
                                        paginationPerPage={5}
                                        noDataComponent={<LoadingIndicator loading={true}></LoadingIndicator>}
                                    />
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem id="partners" headerText={t('settingsPanel.projectsPanel.partners')}>
                            <div className='marginTopL textHeader'>Partners</div>
                            <div>
                                <div className='marginTopM'>
                                    {<ActionButton disabled={!ownsSelectedProject() && !canAdministrateSelectedProject()} iconProps={{ iconName: 'Add' }}
                                        onClick={() => {
                                            setSelectedEntitlement(null);
                                            setPartnerSelectDialogOpen(true);
                                        }}>
                                        {t('settingsPanel.projectsPanel.addPartner')}
                                    </ActionButton>}
                                </div>
                                <div className='borderNeutral'>
                                    <DataTable
                                        data={partnerships}
                                        columns={partnerColumns}
                                        customStyles={dataTableStyles}
                                        pagination
                                        paginationPerPage={5}
                                        noDataComponent={<LoadingIndicator loading={loadingEntitlements} text={'Keine Berechtigungen verfügbar.'}></LoadingIndicator>}
                                    />
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem id="entitlements" headerText={t('settingsPanel.projectsPanel.entitlements')}>
                            <div className='marginTopM displayFlex gapM'>
                                <div>
                                    <div className='textSubHeading'>Rollen/Gewerke</div>
                                    <div className='marginTopS width300 height600 borderNeutral'>
                                        <RoleList></RoleList>
                                    </div>
                                </div>
                                <div>
                                    <div className='textSubHeading'>Niederlassungen</div>
                                    <div className='marginTopS width400 height600 borderNeutral'>
                                        <TreeView
                                            data={siteTreeData}
                                            aria-label="Controlled expanded node tree"
                                            expandedIds={expandedIds}
                                            nodeRenderer={({
                                                element,
                                                isBranch,
                                                isExpanded,
                                                isDisabled,
                                                getNodeProps,
                                                level,
                                                handleExpand,
                                            }) => {
                                                return (
                                                    <div
                                                        {...getNodeProps({ onClick: handleExpand })}
                                                        className='displayFlex alignItemsCenter'
                                                        style={{
                                                            marginLeft: 40 * (level - 1),
                                                            opacity: isDisabled ? 0.5 : 1,
                                                        }}
                                                    >
                                                        {(() => {
                                                            switch (element.metadata?.nodeType) {
                                                                case 'global': return <>
                                                                    <div className='width10'>
                                                                        {isBranch ?
                                                                            <ArrowIcon isOpen={isExpanded} className='' />
                                                                            :
                                                                            <></>}
                                                                    </div>
                                                                    <div className="displayFlex gapS alignItemsCenter">
                                                                        <Droppable accept={'role'} onDrop={(role: any) => {
                                                                            console.log('Dropping >>>', role, element);

                                                                            // if (expandedIds.indexOf(element.id) < 0) {
                                                                            //     setExpandedIds([...expandedIds, element.id]);
                                                                            // }

                                                                            addRoleToSite(role.metadata, element.metadata);
                                                                        }}>
                                                                            <div className="paddingXS displayFlex gapS alignItemsCenter">
                                                                                <FontIcon iconName="Globe" />
                                                                                <div className='textSubHeading'>{element.metadata.name}</div>
                                                                            </div>
                                                                        </Droppable>
                                                                    </div>
                                                                </>;
                                                                case 'site': return <>
                                                                    <div className='width10'>
                                                                        {isBranch ?
                                                                            <ArrowIcon isOpen={isExpanded} className='' />
                                                                            :
                                                                            <></>}
                                                                    </div>
                                                                    <div className="displayFlex gapS alignItemsCenter">
                                                                        <Droppable accept={'role'} onDrop={(role: any) => {
                                                                            console.log('Dropping >>>', role, element);

                                                                            // if (expandedIds.indexOf(element.id) < 0) {
                                                                            //     setExpandedIds([...expandedIds, element.id]);
                                                                            // }

                                                                            addRoleToSite(role.metadata, element.metadata);
                                                                        }}>
                                                                            <div className="paddingXS displayFlex gapS alignItemsCenter">
                                                                                <FontIcon iconName="MapPin" />
                                                                                <div className='textSubHeading'>{element.metadata.name}</div>
                                                                            </div>
                                                                        </Droppable>
                                                                    </div>
                                                                </>;
                                                                case 'partner': return <>
                                                                    <div className='width10'>
                                                                        {isBranch ?
                                                                            <ArrowIcon isOpen={isExpanded} className='' />
                                                                            :
                                                                            <></>}
                                                                    </div>
                                                                    <div className="displayFlex alignItemsCenter">
                                                                        <div className="paddingXS displayFlex gapS alignItemsCenter">
                                                                            <FontIcon iconName="Group" />
                                                                            <div>{element.metadata.name}</div>
                                                                        </div>
                                                                        <ActionButton iconProps={{ iconName: 'Delete' }}
                                                                            onClick={() => {
                                                                                removeRole(element.metadata);
                                                                            }}>
                                                                        </ActionButton>
                                                                    </div>
                                                                </>
                                                                default: return <>
                                                                    <div className='width10'>
                                                                        {isBranch ?
                                                                            <ArrowIcon isOpen={isExpanded} className='' />
                                                                            :
                                                                            <></>}
                                                                    </div>
                                                                    <div className="displayFlex alignItemsCenter">
                                                                        <Droppable accept={'partner'} onDrop={(item: any) => {
                                                                            console.log('Dropping >>>', item, element);

                                                                            // if (expandedIds.indexOf(element.id) < 0) {
                                                                            //     setExpandedIds([...expandedIds, element.id]);
                                                                            // }

                                                                            addPartnerToRole(item, element.metadata);
                                                                        }}>
                                                                            <div className="paddingXS displayFlex gapS alignItemsCenter">
                                                                                <FontIcon iconName="FollowUser" />
                                                                                <div className='colorContrast fontWeightBold'>{element.metadata?.name}</div>
                                                                            </div>
                                                                        </Droppable>
                                                                        {element.metadata?.type !== RoleType.role
                                                                            ?
                                                                            <ActionButton iconProps={{ iconName: 'Delete' }}
                                                                                onClick={() => {
                                                                                    removeRole(element.metadata);
                                                                                }}>
                                                                            </ActionButton>
                                                                            :
                                                                            <></>
                                                                        }

                                                                    </div>
                                                                </>;
                                                            }
                                                        })()
                                                        }
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className='textSubHeading'>Partner</div>
                                    <div className='marginTopS width400 height600 borderNeutral'>
                                        <TreeView
                                            data={partnerTreeData}
                                            aria-label="Controlled expanded node tree"
                                            expandedIds={expandedIds}
                                            defaultExpandedIds={[1]}
                                            nodeRenderer={({
                                                element,
                                                isBranch,
                                                isExpanded,
                                                isDisabled,
                                                getNodeProps,
                                                level,
                                                handleExpand,
                                            }) => {
                                                return (
                                                    <div
                                                        {...getNodeProps({ onClick: handleExpand })}
                                                        className="displayFlex"
                                                        style={{
                                                            marginLeft: 40 * (level - 1),
                                                            opacity: isDisabled ? 0.5 : 1,
                                                        }}
                                                    >
                                                        {isBranch && <ArrowIcon isOpen={isExpanded} className='' />}
                                                        <Draggable type={'partner'} item={element.metadata}>
                                                            <div className="paddingS displayFlex gapM alignItemsCenter">
                                                                <FontIcon aria-label="Compass" style={{ ...iconStyle, color: theme.secondary }}iconName="Group" />
                                                                <div>
                                                                    <div>{element.name}</div>
                                                                    <div className='marginTopXXS textXXS colorContrast'>{(element.metadata as any).supplier.purpose}</div>
                                                                    <div className='marginTopXS textXS'>{(element.metadata as any).supplier.headquarters.street} {(element.metadata as any).supplier.headquarters.streetNumber}, {(element.metadata as any).supplier.headquarters.city}</div>
                                                                    <div>
                                                                    <Rating defaultRating={1} max={5} rating={new Date().getTime() % 4} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Draggable>
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                    </Pivot>
                </div>
            </div>
            :
            <div>
                <div className='textHeader'>
                    {t('settingsPanel.projectsPanel.projects')}
                </div>
                <div className='marginTopM'>
                    <ActionButton iconProps={{ iconName: 'AddToShoppingList' }}
                        onClick={() => {
                            setSelectedProject(new Project({ name: `Project ${new Date().toLocaleDateString()}`, startDate: new Date() }));
                            setPartnerships([]);
                            setSites([]);
                            setClient(null);
                        }}>
                        {t('settingsPanel.projectsPanel.createProject')}
                    </ActionButton>
                    <div className='marginTopL borderNeutral'>
                        <DataTable
                            data={projects}
                            columns={columns}
                            customStyles={dataTableStyles}
                            selectableRows
                            selectableRowsSingle
                            onSelectedRowsChange={({ selectedRows }) => {
                                if (selectedRows && selectedRows.length > 0) {
                                    setClient((selectedRows[0] as any)._company);
                                    selectProject(selectedRows[0]);
                                } else {
                                    selectProject(null);
                                }
                            }}
                            pagination
                            paginationPerPage={5}
                            noDataComponent={<LoadingIndicator loading={loadingProjects} text='Keine Projekte verfügbar.'></LoadingIndicator>}
                        />
                    </div>
                </div>
            </div>
        }
    </div >
        <Dialog
            hidden={!objectSelectDialogOpen}
            onDismiss={() => setObjectSelectDialogOpen(false)}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.projectsPanel.objectSelection'),
                //subText: 'Do you want to send this message without a subject?',
                //maxWidth: 800,
                //width: 800
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <div>
                <div>
                    <Label>{t('settingsPanel.projectsPanel.addressBuilding')}</Label>
                    <AddressPicker setAddress={async (address: any) => {
                        console.log('Set address >>>', address);

                        setAddress(address);

                        if (!address) {
                            return;
                        }

                        const buildings = await userAPI.query(new Query(new EntityQuery(Building.type, [], undefined,
                            is('id').equal(`${address.street} ${address.streetNumber}, ${address.postalCode} ${address.city}, ${address.country}`))));

                        setSiteName(`${address.street}, ${address.city}`);

                        if (buildings.length) {
                            const newBuilding = buildings[0];

                            newBuilding.address = address;

                            setBuilding(newBuilding);

                            const buildingsWithFloors = await userAPI.query(new Query(new EntityQuery(Building.type, [], undefined,
                                is('id').equal(`${address.street} ${address.streetNumber}, ${address.postalCode} ${address.city}, ${address.country}`)), [new LinkedEntityQuery('floors', RelationshipDirection.out, Floor.type)]));

                            if (buildingsWithFloors.length > 0 && buildingsWithFloors[0].floors && buildingsWithFloors[0].floors.length) {
                                setFloors(buildingsWithFloors[0].floors);
                            } else {
                                setFloors([]);
                            }
                        }
                    }} />
                </div>
                <div className='marginTopS'>
                    <TextField label="Site name" value={siteName} onChange={(_: any, siteName: any) => setSiteName(siteName)}
                        onGetErrorMessage={(siteName: any) => {
                            return siteName ? '' : t('settingsPanel.projectsPanel.enterSiteName');
                        }}
                        validateOnFocusOut></TextField>
                </div>
                <div className='marginTopS'>
                    <ChoiceGroup disabled={!address} selectedKey={buildingPart} options={[{ key: BuildingPart.wholeBuilding, text: t('settingsPanel.projectsPanel.wholeBuilding') }, { key: BuildingPart.partOfBuilding, text: t('settingsPanel.projectsPanel.partOfBuilding') }]}
                        onChange={(_: any, option: any) => setBuildingPart(option.key)} label={t('settingsPanel.projectsPanel.space')} />
                </div>
                <div className="marginTopM height100">
                    {buildingPart === BuildingPart.wholeBuilding
                        ?
                        <></>
                        :
                        <>
                            {floors.length > 0
                                ?
                                <Dropdown disabled={!address}
                                    label="Floor"
                                    options={floors.map((floor: any) => {
                                        return {
                                            key: floor._id,
                                            text: floor.name
                                        }
                                    })}
                                    multiSelect
                                    selectedKeys={selectedFloors.map((floor: any) => floor._id)}
                                    onChange={(e: any, item: any) => {
                                        if (selectedFloors.find((floor: any) => floor._id === item.key)) {
                                            setSelectedFloors(selectedFloors.filter((floor: any) => floor._id !== item.key));
                                        } else {
                                            setSelectedFloors([...selectedFloors, floors.find((floor: any) => floor._id === item.key)]);
                                        }

                                        console.log('Selected floors >>>', selectedFloors);
                                    }}
                                    styles={{
                                        dropdown: { width: 300 },
                                    }}
                                />
                                :
                                <TextField label="Floor name" value={floorName} onChange={(_: any, floorName: any) => setFloorName(floorName)}
                                    onGetErrorMessage={(floorName: any) => {
                                        return floorName ? '' : t('settingsPanel.projectsPanel.enterFloorName');
                                    }}
                                    validateOnFocusOut></TextField>
                            }

                        </>
                    }
                </div>
            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton disabled={!isBuildingPartComplete()} text={t('global.add')} onClick={async () => {
                    let newSites: Site[] = [];

                    if (buildingPart === BuildingPart.wholeBuilding) {
                        let newSite: any = await userAPI.queryOne(new Query(new EntityQuery(Site.type, [], undefined,
                            and(is('street').equal(address.street), is('streetNumber').equal(address.streetNumber), is('postalCode').equal(address.postalCode), is('city').equal(address.city), is('country').equal(address.country)))));

                        if (!newSite) {
                            newSite = await userAPI.createSite(authentication.customer, new Site({ name: siteName, street: address.street, streetNumber: address.streetNumber, postalCode: address.postalCode, city: address.city, country: address.country }));
                        }

                        newSite.buildings = await userAPI.query(from(Building.type).where(is('id').equal(`${newSite.street} ${newSite.streetNumber}, ${newSite.postalCode} ${newSite.city}, ${newSite.country}`)));

                        newSites = [...sites, newSite];
                    } else {
                        // TODO Create floor from floor name
                        // const floors = floorName ? [{ name: floorName }] : selectedFloors;
                        //object = { buildingPart, building, floors, address };
                    }

                    // TODO Check duplicates

                    setSites(newSites);
                    refreshRoles(newSites);
                    setObjectSelectDialogOpen(!objectSelectDialogOpen);
                }} />
                <DefaultButton onClick={() => {
                    setObjectSelectDialogOpen(!objectSelectDialogOpen);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
        <Dialog
            hidden={!partnerSelectDialogOpen}
            onDismiss={() => setPartnerSelectDialogOpen(!partnerSelectDialogOpen)}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.projectsPanel.selectPartner'),
                //subText: 'Do you want to send this message without a subject?',
                //maxWidth: 800,
                //width: 800
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <div>
                <div>
                    <Label>{t('settingsPanel.projectsPanel.company')}</Label>
                    <LegalPersonPicker setLegalPerson={(legalPerson: any) => setNewSupplier(legalPerson)} />
                </div>
                <div className='marginTopM textL'>
                    {t('settingsPanel.projectsPanel.primaryContact')}
                </div>
                <div className='marginTopM textS'>
                    {t('settingsPanel.projectsPanel.primaryContactDetails')}
                </div>
                <div className='marginTopM'>
                    <TextField required label={t('settingsPanel.projectsPanel.primaryContactEmail')} value={emailPrimaryContact} onChange={(_: any, emailPrimaryContact: any) => {
                        setEmailPrimaryContact(emailPrimaryContact);
                    }} onGetErrorMessage={(emailPrimaryContact: any) => {
                        return emailPrimaryContact && isValidEmail(emailPrimaryContact) ? '' : t('settingsPanel.projectsPanel.enterValidEmail');
                    }}
                        validateOnFocusOut></TextField>
                </div>
            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton disabled={!newSupplier || !isNewSupplierValid()} text={t('global.add')} onClick={async () => {
                    const customer: any = await userAPI.queryOne(from(Customer.type).where(is('id').equal(authentication.
                        customer)));
                    const legalPerson: any = await userAPI.queryOne(from(LegalPerson.type).where(is('_id').equal(customer.legalPerson)));
                    let newPartnership = await userAPI.queryOne(from(Partnership.type).where(and(is('customer').equal(legalPerson._id), is('supplier').equal(newSupplier._id))));

                    if (!newPartnership) {
                        newPartnership = await userAPI.createPartnership(authentication.customer, new Partnership({
                            customer: customer._id,
                            supplier: newSupplier._id,
                            emailPrimaryContact,
                        }));
                    }

                    // TODO Patch should be done via relationships on server

                    // @ts-ignore
                    newPartnership.customer = customer;
                    // @ts-ignore
                    newPartnership.supplier = newSupplier;

                    setPartnerships([...partnerships, newPartnership]);
                    setNewSupplier(null);
                    setPartnerSelectDialogOpen(false);
                }} />
                <DefaultButton onClick={() => {
                    setPartnerSelectDialogOpen(false);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
        <Dialog
            hidden={!addDocumentDialogOpen}
            onDismiss={() => setAddDocumentDialogOpen(false)}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.projectsPanel.addDocument'),
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <div>
                {selectedFile ?
                    <div>
                        <div className="displayFlex gapL">
                            <div><FontIcon iconName="PDF" className="colorPrimary" style={{ fontSize: 50, height: 50, width: 50, }} /></div>
                            <div>
                                <div className='textS fontWeightBold'>{selectedFile.name}</div>
                                <div className='textS colorGrey'>{selectedFile.lastModifiedDate.toLocaleDateString()}</div>
                                <div className='textS colorPrimary'>{numberFormat.format(selectedFile.size / 1000000)} MB</div>
                            </div>
                        </div>
                        <div className='marginTopL'>
                            <Dropdown
                                label={t('settingsPanel.projectsPanel.documentType')}
                                options={documentTypeOptions}
                                selectedKey={documentType}
                                onChange={(e: any, item: any) => setDocumentType(item.key)}
                                styles={{
                                    dropdown: { width: 300 },
                                }}
                            />
                        </div>
                        <div className='marginTopM'>
                            <Dropdown
                                options={[{ key: '_self', text: 'Nur eigenes Unternehmen' }, { key: '_all', text: 'All' }, ...partnerships.map((entitlement: any) => { return { key: entitlement.partner._id, text: entitlement.partner.name } })]}
                                selectedKeys={documentVisibility}
                                multiSelect
                                onChange={(e: any, item: any) => {
                                    if (documentVisibility.indexOf(item.key) >= 0) {
                                        if (item.key !== '_self' && item.key !== '_all') {
                                            setDocumentVisibility(documentVisibility.filter((key: string) => key !== item.key));
                                        }
                                    } else {
                                        if (item.key === '_self' || item.key === '_all') {
                                            setDocumentVisibility([item.key]);
                                        } else {
                                            setDocumentVisibility([...documentVisibility.filter((key: string) => key !== '_self' && key !== '_all'), item.key]);
                                        }
                                    }
                                }}
                                styles={{
                                    dropdown: { width: 300 },
                                }}
                            /></div>
                    </div>
                    :
                    <></>
                }
            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton text={t('global.add')} onClick={() => {
                    setAddDocumentDialogOpen(false);
                }} />
                <DefaultButton onClick={() => {
                    setAddDocumentDialogOpen(false);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
    </>;
}

const ArrowIcon = ({ isOpen, className }: { isOpen: any, className: string }) => {
    const baseClass = "arrow";
    const classes = cx(
        baseClass,
        { [`${baseClass}--closed`]: !isOpen },
        { [`${baseClass}--open`]: isOpen },
        className
    );

    return <IoMdArrowDropright className={classes} />;
};