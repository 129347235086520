import Header from "../Header";
import { Pivot, PivotItem } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import CustomerAndUserManagementPanel from "./CustomerAndUserManagementPanel";
import BillingPanel from "./BillingPanel";
import InvoicingPanel from "./InvoicingPanel";
import { MessageBox, useMessagBox } from "../components/MessageBox";
import SideBar, { SideBarItem } from "../components/SideBar";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GatewayPanel from "./GatewayPanel";
import ContentPanel from "../components/ContentPanel";

export default function AdministrationPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const { processError, messageBox } = useMessagBox();
    const [selectedPanel, setSelectedPanel] = useState(searchParams.get("panel") || 'customerManagement');

    const sideBarItems: SideBarItem[] = [
        { key: 'customerManagement', text: t('administrationPage.customerManagement'), iconName: "CompanyDirectory" },
        { key: 'email', text: t('administrationPage.email'), iconName: "EditMail" },
        { key: 'billing', text: t('administrationPage.billing'), iconName: "Money" },
        { key: 'teamManagement', text: t('settingsPanel.teamManagement'), iconName: "People" },
        { key: 'invoicing', text: t('administrationPage.invoicing'), iconName: "PaymentCard" },
        { key: 'telemetry', text: t('administrationPage.telemetry'), iconName: "ProjectCollection" },
    ];

    const selectPanel = (panel: string) => {
        setSelectedPanel(panel);
        searchParams.set("panel", panel);
        navigate(`/administration?${searchParams.toString()}`)
    }

    return (
        <div>
            <Header showHome={true} title="Administration"></Header>
            <div className="displayFlex">
                <SideBar items={sideBarItems} setItem={selectPanel}></SideBar>
                <ContentPanel>
                    <MessageBox messageBox={messageBox} ></MessageBox>
                    {(() => {
                        switch (selectedPanel) {
                            case 'customerManagement': return <CustomerAndUserManagementPanel processError={processError}></CustomerAndUserManagementPanel>;
                            case 'email': return <CustomerAndUserManagementPanel processError={processError}></CustomerAndUserManagementPanel>;
                            case 'billing': return <BillingPanel processError={processError}></BillingPanel>;
                            case 'teamManagement': return <CustomerAndUserManagementPanel processError={processError}></CustomerAndUserManagementPanel>;
                            case 'invoicing': return <InvoicingPanel processError={processError}></InvoicingPanel>
                            case 'telemetry': return <GatewayPanel processError={processError}></GatewayPanel>;
                        }
                    })()}
                </ContentPanel>
            </div>
        </div>
    );
}