import { Checkbox } from '@fluentui/react-components';
import '../../App.sass';
import { Text } from '@fluentui/react/lib/Text';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import dayjs from 'dayjs';

class Properties {
    object: any;
}

const customStyles = {
    cells: {
        style: {
            minHeight: '50px',
            maxHeight: '50px',
            '&:last-of-type': {
                // borderTopStyle: 'solid',
                // borderTopWidth: '1px',
                // borderTopColor: 'black',
            }
        },
    },
};
const currencyFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 });
const numberFormat = new Intl.NumberFormat('de-DE');

const calculateEmissions = (selectedMeasures: any) => {
    let startDate = new Date();
    let date = new Date(startDate);
    const endDate = new Date('1/1/2050');

    const startProperty = 33;
    const startGoal = 60;
    const b = 1000 * 60 * 60 * 24 * 30 * 100;
    let property;
    let goal;
    let stranded;

    const series = [];

    while (date.getTime() < endDate.getTime()) {
        goal = startGoal * Math.exp(-(date.getTime() - startDate.getTime()) / b);
        property = startProperty;

        for (const measure of selectedMeasures) {
            if (date.getTime() > dayjs(startDate).add(measure.projectDuration, 'month').toDate().getTime()) {
                property -= (startProperty * measure.co2Reduction / 100);

                property = Math.max(property, 0);
            }
        }

        series.push({ time: date.getTime(), property, goal });

        if (!stranded && property > goal) {
            stranded = { time: date.getTime(), emission: property };
        }

        date = dayjs(date).add(1, 'month').toDate();
    }

    return { series, stranded };
}

const suggestedMeasures = [{
    measure: 'LED-Leuchten',
    synopsis: 'LED-Leuchten',
    investment: 43000,
    projectDuration: 8,
    energySavings: 11,
    co2Reduction: 4,
}, {
    measure: 'Anbindung Fernwärme',
    synopsis: 'Anbindung Fernwärme',
    investment: 1000,
    projectDuration: 12,
    energySavings: 0,
    co2Reduction: 10,
}, {
    measure: 'Energetische Sanierung der thermischen Hülle',
    synopsis: 'Energetische Sanierung',
    investment: 1234000,
    projectDuration: 36,
    energySavings: 128,
    co2Reduction: 28,
}, {
    measure: 'Einbau Wärmepumpe',
    synopsis: 'Einbau Wärmepumpe',
    investment: 192000,
    energySavings: 81,
    projectDuration: 12,
    co2Reduction: 1,
}, {
    measure: 'PV-Anlage',
    synopsis: 'PV-Anlage',
    investment: 150000,
    energySavings: 35,
    co2Reduction: 36,
    projectDuration: 24,
}, {
    investment: 1576000,
    energySavings: 224,
    co2Reduction: 66,
}];

export default function DecarbonizationProperties(properties: Properties) {
    const { t } = useTranslation();
    const [ageProfileChartOptions, setAgeProfileChartOptions] = useState() as any;
    const [emissionSeries, setEmissionSeries] = useState() as any;
    const [measures, setMeasures] = useState(suggestedMeasures) as any;
    const [selectedMeasures, setSelectedMeasures] = useState([]) as any;

    const columns = [{
        name: '',
        width: '10px',
        cell: (item: any) => (
            <div className='flexGrow1 displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter'>
                {item.measure ? <Checkbox checked={selectedMeasures.indexOf(item) >= 0}
                    onChange={() => {
                        if (selectedMeasures.indexOf(item) >= 0) {
                            setSelectedMeasures(selectedMeasures.filter((measure: any) => measure !== item));
                        } else {
                            setSelectedMeasures([...selectedMeasures, item]);
                        }
                    }}></Checkbox> : <></>}
            </div>
        ),
    },
    {
        name: 'Maßnahme',
        width: '150px',
        cell: (item: any) => (
            <div className="displayFlex flexDirectionColumn justifyContentCenter textOverflowEllipsis height100">
                {item.measure ? <span>{item.measure}</span> : <span className='fontWeightBold'>Gesamt</span>}
            </div>
        ),
    },
    {
        name: 'Investitionen',
        width: '110px',
        right: true,
        cell: (item: any) => (
            <>
                {item.measure
                    ?
                    <span>{currencyFormat.format(item.investment)}</span>
                    :
                    <span className='fontWeightBold'>{currencyFormat.format(item.investment)}</span>}
            </>
        ),
    }, {
        name: 'Dauer (Mt.)',
        width: '100px',
        right: true,
        cell: (item: any) => (
            <>
                {item.measure
                    ?
                    <span>{item.projectDuration}</span>
                    :
                    <span className='fontWeightBold'></span>}
            </>
        ),
    }, {
        name: 'Einsparung',
        width: '100px',
        right: true,
        cell: (item: any) => (
            <>
                {item.measure
                    ?
                    <span>{numberFormat.format(item.energySavings)}kWh</span>
                    :
                    <span className='fontWeightBold'>{numberFormat.format(item.energySavings)}kWh</span>}
            </>
        ),
    },
    {
        name: 'CO2-Reduktion',
        width: '100px',
        right: true,
        cell: (item: any) => (
            <>
                {item.measure
                    ?
                    <span>{item.co2Reduction}%</span>
                    :
                    <span className='fontWeightBold'>-{item.co2Reduction}%</span>}
            </>
        ),
    }];

    useEffect(() => {
        console.log('Rebuild chart >>>', selectedMeasures);

        const calculations: any = calculateEmissions(selectedMeasures);

        console.log('Series >>>', calculations);

        setEmissionSeries([{
            name: 'Immobilie',
            data: calculations.series.map((entry: any) => entry.property),
        }, {
            name: 'Klimaziel',
            data: calculations.series.map((entry: any) => entry.goal),
        }]);
        setAgeProfileChartOptions({
            chart: {
                height: 200,
                type: 'line'
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#649aaa', '#576071'],
            stroke: {
                //curve: 'smooth',
                width: [2, 2],
            },
            // fill: {
            //     type: 'gradient',
            //     gradient: {
            //         shadeIntensity: 1,
            //         inverseColors: false,
            //         opacityFrom: 0.45,
            //         opacityTo: 0.05,
            //         stops: [20, 100, 100, 100]
            //     },
            // },
            xaxis: {
                type: 'datetime',
                categories: calculations.series.map((entry: any) => entry.time)
            },
            yaxis: {
                min: 0,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    //color: '#00288E'
                },
                labels: {
                    style: {
                        //colors: '#00288E',
                    },
                    formatter: (val: number) => {
                        return val.toFixed(1)
                    },
                },
                title: {
                    text: 'kg CO₂',
                    style: {
                        color: '#00288E',
                    }
                }
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
            annotations: {
                xaxis: selectedMeasures.map((measure: any) => {
                    return {
                        // in a datetime series, the x value should be a timestamp, just like it is generated below
                        x: dayjs(new Date()).add(measure.projectDuration, 'month').toDate().getTime(),
                        strokeDashArray: 0,
                        borderColor: "#775DD0",
                        label: {
                            borderColor: "#775DD0",
                            style: {
                                color: "#fff",
                                background: "#775DD0"
                            },
                            text: measure.synopsis
                        }
                    };
                }),
                points: calculations.stranded ?
                    [{
                        x: calculations.stranded.time,
                        y: calculations.stranded.emission,
                        marker: {
                            size: 8,
                            borderColor: '#FF4560',
                        },
                        label: {
                            //borderColor: '#FF4560',
                            borderColor: null,
                            textColor: '#FF4560',
                            text: 'Stranded'
                        }
                    }] : []
            }
        })
    }, [selectedMeasures]);

    return <div>
        <div className="marginTopS headerL">Effekte</div>
        {ageProfileChartOptions && emissionSeries && emissionSeries.length && emissionSeries[0].data.length > 0
            ?
            <>
                <Chart options={ageProfileChartOptions} series={emissionSeries} type="line" height="230" />
            </>
            :
            <div className='marginTopM'>{t('global.noInformationAvailable')}</div>
        }
        <div className="marginTopS headerL">Empfohlene Massnahmen</div>
        {measures && measures.length > 0
            ?
            <div className="marginTopM borderNeutral">
                <DataTable
                    data={measures}
                    columns={columns}
                    customStyles={customStyles}
                    dense
                />
            </div>
            :
            <div className='marginTopM'><Text variant="medium">{t('global.noInformationAvailable')}</Text></div>
        }
    </div >;
}