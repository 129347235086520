import { Pivot, PivotItem } from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import LeaseBuildingProperties from "./LeaseBuildingProperties";
import GeneralBuildingProperties from "./GeneralBuildingProperties";
import EnergyManagementProperties from "./EnergyManagementProperties";
import UtilizationProperties from "./UtilizationProperties";
import { useTranslation } from 'react-i18next';
import TechnicalEquipmentProperties from "./TechnicalEquipmentProperties";
import DecarbonizationProperties from "./plot-of-land/DecarbonisationProperties";
import SocioDemographicsProperties from "./plot-of-land/SocioDemographicsProperties";
import PoIProperties from "./plot-of-land/PoIProperties";
import Environment3DProperties from "./building/Environment3DProperties";
import PVPotentialProperties from "./building/PVPotentialProperties";
import FinancialProperties from "./building/FinancialProperties";
import EnergyAndCO2Properties from "./building/EnergyAndCO2Properties";
import { userAPI } from "../UserAPI";
import { Address, Building, EntityQuery, is, LinkedEntityQuery, Query, RelationshipDirection } from "@the-real-insight/tri-model";
import { useLocation } from "react-router-dom";

class Properties {
  building: any;
  poisChanged?: (pois: any[]) => void;
  processError?: (error: any) => void;
}

export default function BuildingProperties(properties: Properties) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [tab, setTab] = useState(searchParams.get("tab") || 'general');;
  const [building, setBuilding] = useState() as any;

  useEffect(() => {
    if (properties.poisChanged) {
      properties.poisChanged([]);
    }
  }, [tab]);

  useEffect(() => {
    const call = async () => {
      const buildings = await userAPI.query(new Query(new EntityQuery(Building.type, [], undefined,
        is('_id').equal(`${properties.building._id}`)), [new LinkedEntityQuery('address', RelationshipDirection.out, Address.type)]));

      console.log('Building >>>', buildings);

      if (buildings.length > 0) {
        setBuilding(buildings[0]);
      }
    };

    call();
  }, [properties]);

  const setParams = (value: any) => {
    console.log('Value >>>', value);

    const url = new URL(window.location.href);

    url.searchParams.set('tab', value.props.itemKey);
    window.history.replaceState(null, '', url.toString());

    setTab(value.props.itemKey);
  }

  return (building
    ?
    <div>
      <div>
        <div className="textM">{building ? (() => {
          switch ((building as any).type) {
            case "apartments": return t('buildingProperties.apartments');
            case "hotel": return t('buildingProperties.hotel');
            case "school": return t('buildingProperties.school');
            case "church": return t('buildingProperties.church');
            case "yes": return t('buildingProperties.building');
            default: return t('buildingProperties.building');
          }
        })()
          :
          ""}</div>
        <div className="headerXL">{building && building.address ? `${building.address.street} ${building.address.streetNumber}` : ''}</div>
        <div className="textM">{building && building.address ? `${building.address.postalCode} ${building.address.city}` : ''}</div>
      </div>
      <Pivot className="marginTopM" onLinkClick={setParams} selectedKey={String(tab)} overflowBehavior={'menu'}>
        <PivotItem itemKey="general" headerText={t('buildingProperties.general')} itemIcon="Info">
          <GeneralBuildingProperties building={building}></GeneralBuildingProperties>
        </PivotItem>
        <PivotItem itemKey="neighborhood" headerText={'3D-View'} itemIcon="SchoolDataSyncLogo">
          <div className='marginTopL'><Environment3DProperties object={building}></Environment3DProperties></div>
        </PivotItem>
        <PivotItem itemKey="poi" headerText={'In der Nähe'} itemIcon="MapPin">
          <PoIProperties object={building} poisChanged={properties.poisChanged}></PoIProperties>
        </PivotItem>
        <PivotItem itemKey="sociodemographics" headerText={'Soziodemographie'} itemIcon="SchoolDataSyncLogo">
          <SocioDemographicsProperties object={building}></SocioDemographicsProperties>
        </PivotItem>
        <PivotItem itemKey="crime" headerText={'Kriminalität'} itemIcon="EndPointSolid">
          <div>
            <div className="marginTopL">
              <div className="headerL">Kriminalität</div>
            </div>
            {building.address
              ?
              <>
                <div className="marginTopL displayFlex alignItemsBaseline gapS">
                  <div className="divXXL fontWeightBold colorPrimaryLight">7.200</div><div className="divXL colorPrimaryLight">Straftaten/100.000 Einwohner</div>
                </div>
                <div className="marginTopM displayFlex alignItemsBaseline gapS">
                  <div className="divXL fontWeightBold colorPrimary">3%</div><div className="divL colorPrimary">über Bundesdurchschnitt</div>
                </div>
                <div className="marginTopS displayFlex alignItemsBaseline gapS">
                  <div className="divXL fontWeightBold colorPrimary">5%</div><div className="divL colorPrimary">über Durchschnitt {building.address.city}</div>
                </div></>
              :
              <></>}
          </div>
        </PivotItem>
        <PivotItem itemKey="legal" headerText={t('buildingProperties.legal')} itemIcon="Compare">
          <div>
            <div className="">
              <div className="headerL">Eigentümer</div>
              <div className='marginTopM'><div className="textM">{t('global.noInformationAvailable')}</div></div>
            </div>
            <div className="">
              <div className="marginTopM headerL">Eigentümergemeinschaften</div>
              <div className='marginTopM'><div className="textM">{t('global.noInformationAvailable')}</div></div>
            </div>
          </div>
        </PivotItem>
        <PivotItem itemKey="financial" headerText={t('buildingProperties.financial')} itemIcon="Money">
          <FinancialProperties building={building}></FinancialProperties>
        </PivotItem>
        <PivotItem itemKey="lease" headerText={t('buildingProperties.lease')} itemIcon="Page">
          <LeaseBuildingProperties></LeaseBuildingProperties>
        </PivotItem>
        <PivotItem itemKey="technicalEquipment" headerText={t('buildingProperties.technicalEquipment')} itemIcon="ConstructionCone">
          <TechnicalEquipmentProperties building={building}></TechnicalEquipmentProperties>
        </PivotItem>
        <PivotItem itemKey="maintenance" headerText={t('buildingProperties.maintenance')} itemIcon="Repair">
          <>
            <div className='marginTopM'>{t('global.noInformationAvailable')}</div>
          </>
        </PivotItem>
        <PivotItem itemKey="utilization" headerText={t('buildingProperties.utilization')} itemIcon="PeopleRepeat">
          <UtilizationProperties building={building}></UtilizationProperties>
        </PivotItem>
        <PivotItem itemKey="energyManagement" headerText={t('buildingProperties.energyManagement')} itemIcon="PlugDisconnected">
          <EnergyManagementProperties building={building}></EnergyManagementProperties>
        </PivotItem>
        <PivotItem itemKey="energyAndCO2Balance" headerText={'Energie- und CO2-Bilanz'} itemIcon="PlugDisconnected">
          <EnergyAndCO2Properties building={building}></EnergyAndCO2Properties>
        </PivotItem>
        <PivotItem itemKey="pvPotential" headerText={'PV Potenzial'} itemIcon="PlugDisconnected">
          <PVPotentialProperties building={building}></PVPotentialProperties>
        </PivotItem>
        <PivotItem itemKey="decarbonization" headerText={'Dekarbonisierung'} itemIcon="LightBulb">
          <DecarbonizationProperties object={building}></DecarbonizationProperties>
        </PivotItem>
      </Pivot>
    </div >
    :
    <></>
  );
}
