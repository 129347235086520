import '../App.sass';
import { useEffect, useState } from 'react';
import { userAPI } from '../UserAPI';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { ActionButton } from '@fluentui/react/lib/Button';
import DataTable from 'react-data-table-component';
import { dataTableStyles } from '../styles';
import { Asset, EntityQuery, LinkedEntityQuery, Product, Query, RelationshipDirection, Document } from '@the-real-insight/tri-model';
import { configuration } from '../Configuration';

class Properties {
    building: any;
}

export default function TechnicalEquipmentProperties(properties: Properties) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [assets, setAssets] = useState([] as Asset[]);
    const [products, setProducts] = useState([] as Product[]);

    const assetColumns = [{
        name: t('buildingProperties.technicalEquipmentPropertiesPanel.id'),
        width: '50px',
        selector: (asset: any) => asset.id,
        cell: (asset: any) => (
            <div className='height20 displayFlex flexDirectionColumn justifyContentCenter'>
                <ActionButton iconProps={{ iconName: 'IncreaseIndentArrow' }} onClick={() => {
                    navigate(`/entity?selectedEntityType=Asset&selectedEntity=${asset._id}`);
                }}></ActionButton>
            </div>
        ),
        sortable: false,

    }, {
        name: t('buildingProperties.technicalEquipmentPropertiesPanel.type'),
        width: '200px',
        selector: (asset: any) => asset.schemaCategoryName,
        cell: (asset: any) => (
            <div className='height20 displayFlex flexDirectionColumn justifyContentCenter overflowWrapWordWrap'>
                <div className='textM'>{asset.schemaCategoryName}</div>
            </div>
        ),
        sortable: true,
    }, {
        name: t('buildingProperties.technicalEquipmentPropertiesPanel.product'),
        width: '150px',
        selector: (asset: any) => asset.productName,
        cell: (asset: any) => (
            <div className='height20 displayFlex flexDirectionColumn justifyContentCenter overflowWrapWordWrap'>
                <div className='textM'>{asset.productName}</div>
            </div>
        ),
        sortable: true,
    }, {
        name: t('buildingProperties.technicalEquipmentPropertiesPanel.installed'),
        selector: (asset: any) => asset.installed ? new Date(asset.installed).getTime() : 0,
        cell: (asset: any) => (
            <div className='height20 displayFlex flexDirectionColumn justifyContentCenter'>
                <div className='textM'>{asset.installed ? new Date(asset.installed).toLocaleDateString() : '-'}</div>
            </div>
        ),
        sortable: true,
    }, {
        name: t('buildingProperties.technicalEquipmentPropertiesPanel.lastMaintained'),
        selector: (asset: any) => asset.lastMaintained ? new Date(asset.lastMaintained).getTime() : 0,
        cell: (asset: any) => (
            <div className='height20 displayFlex flexDirectionColumn justifyContentCenter'>
                <div className='textM'>{asset.lastMaintained ? new Date(asset.lastMaintained).toLocaleDateString() : '-'}</div>
            </div>
        ),
        sortable: true,
    }];
    const productColumns = [{
        name: t('buildingProperties.technicalEquipmentPropertiesPanel.document'),
        selector: (product: any) => product._id,
        cell: (product: any) => (
            <div className='height20 displayFlex flexDirectionColumn justifyContentCenter'>
                <a href={`${configuration.serverUrl}${product.manual.url}`} target="_blank">
                    <ActionButton iconProps={{ iconName: 'PDF' }}></ActionButton>
                </a>
            </div>
        ),
    }, {
        name: t('buildingProperties.technicalEquipmentPropertiesPanel.product'),
        selector: (product: any) => product.name,
        cell: (product: any) => (
            <div className='height20 displayFlex flexDirectionColumn justifyContentCenter overflowWrapWordWrap'>
                <div className='textM'>{product.name}</div>
            </div>
        ),
        sortable: true,
    }];

    useEffect(() => {
        const call = async () => {
            try {
                console.log('Retrieving metadata');

                const metadata = (await userAPI.getDIN276Metadata()).reduce((map: any, category: any) => {
                    map[category.code] = category;

                    return map;
                }, {});

                console.log('Retrieving assets');

                const result = (await userAPI.query(new Query(new EntityQuery(Asset.type)))).map((asset: any) => {
                    console.log(asset);

                    return asset.schemaCategory ? { ...asset, schemaCategoryName: metadata[asset.schemaCategory].name } : '-';
                });

                console.log(result);

                setAssets(result as Asset[]);

            } catch (error) {
            }
        };

        call();
    }, [properties]);

    useEffect(() => {
        const call = async () => {
            try {
                const result = await userAPI.query(new Query(new EntityQuery(Product.type),
                    [new LinkedEntityQuery('manual', RelationshipDirection.out, Document.type)]));

                console.log(result);

                setProducts(result as Product[]);
            } catch (error) {
            }
        };

        call();
    }, [properties]);

    return <div>
        <div>
            {assets && assets.length > 0
                ?
                <>
                    <div className="marginTopM">
                        <div className='headerL'>{t('buildingProperties.technicalEquipmentPropertiesPanel.assets')}</div>
                    </div>
                    <div className="marginTopM borderNeutral">
                        <DataTable
                            data={assets}
                            columns={assetColumns}
                            customStyles={dataTableStyles}
                            pagination
                        />
                    </div>
                    <div className="marginTopM">
                        <div className='headerL'>{t('buildingProperties.technicalEquipmentPropertiesPanel.documentation')}</div>
                    </div>
                    <div className="marginTopM borderNeutral">
                        <DataTable
                            data={products}
                            columns={productColumns}
                            customStyles={dataTableStyles}
                            pagination
                        />
                    </div>
                </>
                :
                <></>
            }
        </div>
    </div>;
}