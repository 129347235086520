import { Address, AndFilter, Comparison, EntityQuery, Query, or, is, and, from } from "@the-real-insight/tri-model";
import { userAPI } from "../UserAPI";
import { useEffect, useRef, useState } from "react";
import { TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps } from '@fluentui/react/lib/Pickers';
import { ContextualMenu, SearchBox } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { Label } from "@fluentui/react-components";

interface Properties {
    label?: string;
    setAddress?: (address: any) => void
}

class AddressGroup {
    addresses: any;
    street: string;
    streetNumber?: string;
    city?: string;
    postalCode?: string;

    constructor(addresses: any[], street: string, streetNumber?: string, city?: string, postalCode?: string,) {
        this.addresses = addresses;
        this.street = street;
        this.streetNumber = streetNumber;
        this.city = city;
        this.postalCode = postalCode;
    }

    label() {
        return `${this.street} ${this.streetNumber || ''}${this.postalCode ? ', ' + this.postalCode + ' ' + this.city : ''}`;
    }

    filterString() {
        return `${this.street} ${this.streetNumber || ''}`;
    }
}

export default function AddressPicker(properties: Properties) {
    const { t } = useTranslation();
    const linkRef = useRef(null);
    const [filter, setFilter] = useState('');
    const [addressGroups, setAddressGroups] = useState([]) as any;
    const [addressGroup, setAddressGroup] = useState() as any;
    const [menuOpen, setMenuOpen] = useState([]) as any;
    const [searching, setSearching] = useState(false) as any;

    const groupBy = (addresses: any, withStreetNumbers: boolean = false) => {
        if (withStreetNumbers) {
            const map = addresses.reduce(function (map: any, address: any) {
                (map[`${address.street} ${address.streetNumber}, ${address.postalCode}`] = map[`${address.street} ${address.streetNumber}, ${address.postalCode}`] || []).push(address);
                return map;
            }, {});

            return Object.values(map).map((group: any) => new AddressGroup(group, group[0].street, group[0].streetNumber, group[0].city,
                group[0].postalCode));

        } else {
            const map = addresses.reduce(function (map: any, address: any) {
                (map[`${address.street}`] = map[`${address.street}`] || []).push(address);
                return map;
            }, {});

            return Object.values(map).map((group: any) => new AddressGroup(group, group[0].street, group.length === 1 ? group[0].streetNumber : undefined,
                group.length === 1 ? group[0].city : undefined, group.length === 1 ? group[0].postalCode : undefined));
        }
    };

    const containsAnyDigit = (value: string) => {
        value = value.trim();

        for (let i = 0; i < value.length; ++i) {
            // @ts-ignore
            if (!isNaN(`${value.charAt(i)}`)) {
                return true;
            }
        }

        return false;
    }

    const splitStreetAndNumber = (streetAndNumber: string) => {
        const parts: any = streetAndNumber.trim().split(' ');

        let index = 0;

        for (const part of parts) {
            if (containsAnyDigit(part)) {
                break;
            }

            ++index;
        }

        if (index === parts.length) {
            return { street: streetAndNumber.trim() };
        } else {
            return { street: parts.slice(0, index).join(' '), streetNumber: parts.slice(index).join(' ') };
        }
    }

    useEffect(() => {
        const debounce = setTimeout(async () => {
            console.log(filter);

            if (filter && filter.trim().length > 3) {
                const { street, streetNumber } = splitStreetAndNumber(filter);
                let filterExpression;

                if (streetNumber) {
                    filterExpression = and(is('street_lower').startsWith(street.toLowerCase()),
                        is('streetNumber').equalRegExp(`(?i)${streetNumber}.*`));
                } else {
                    filterExpression = is('street_lower').startsWith(street.toLowerCase());
                }

                setSearching(true);
                setAddressGroups([]);

                try {
                    const addresses = await userAPI.query(from(Address.type).where(filterExpression));

                    setAddressGroups(groupBy(addresses, streetNumber !== undefined));
                } catch (error) {
                    console.log(error);
                } finally {
                    setSearching(false);
                }

                setMenuOpen(true);
            }
        }, 500);

        return () => clearTimeout(debounce)
    }, [filter]);

    const filterAddresses = async (filter: string) => {
        setFilter(filter);
        setAddressGroup(null);
        setAddressGroups([]);

        if (properties.setAddress) {
            properties.setAddress(null);
        }
    };

    return <div>
        <div className="displayFlex gapS alignItemsCenter">
            {properties.label
                ?
                <Label>Address/Building</Label>
                :
                <></>}
            <SearchBox autoFocus placeholder={t('addressPicker.enterStreetAndStreetNumber')} value={addressGroup ? `${addressGroup.filterString()}` : filter} ref={linkRef} onChange={(event: any, filter: any) => filterAddresses(filter)}
                styles={{ root: { width: 450 } }}></SearchBox>
        </div>
        <ContextualMenu
            items={addressGroups.map((group: AddressGroup) => {
                return {
                    key: group.label(),
                    text: group.label(),
                    onClick: () => {
                        setAddressGroup(group);

                        console.log('Address Group >>>', group);

                        if (group.addresses.length === 1 && properties.setAddress) {
                            properties.setAddress(group.addresses[0]);
                        }

                        setMenuOpen(false);
                    }
                };
            })}
            hidden={!menuOpen}
            target={linkRef}
            onItemClick={(_: any, item: any) => setMenuOpen(false)}
            onDismiss={() => setMenuOpen(false)}
        />
        {searching
            ?
            <div className="marginTopS textS colorPrimary">{t('global.searching')}...</div>
            :
            (addressGroup && addressGroup.addresses.length === 1
                ?
                <div className="marginTopS textS colorPrimary">{addressGroup.label()}</div>
                :
                <div className="marginTopS textS">&nbsp;</div>
            )}
    </div>
}