import { Dropdown, DropdownMenuItemType, IDropdownOption, Slider } from '@fluentui/react';
import '../../App.sass';
import { Text } from '@fluentui/react/lib/Text';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userAPI } from '../../UserAPI';
import { AdvertisementColumn, Billboard, Building, ChargingStation, EntityQuery, Filter, PoI, Query, University, calculateBoundingBox } from '@the-real-insight/tri-model';

class Properties {
    object: any;
    poisChanged?: (pois: any[]) => void
}

const poiTypes = [
    { key: 'Mobilität', text: 'Infrastructur', itemType: DropdownMenuItemType.Header },
    { key: 'ChargingStation', text: 'Ladestationen' },
    { key: 'BusStop', text: 'Bushaltestelle' },
    { key: 'supply', text: 'Versorgung', itemType: DropdownMenuItemType.Header },
    { key: 'supermarket', text: 'Supermarkt' },
    { key: 'education', text: 'Bildung', itemType: DropdownMenuItemType.Header },
    { key: 'Kindergarten', text: 'Kindergärten' },
    { key: 'School', text: 'Schulen' },
    { key: 'University', text: 'Universitäten' },
    { key: 'advertisement', text: 'Werbung', itemType: DropdownMenuItemType.Header },
    { key: 'AdvertisementColumn', text: 'Litfaßsäulen' },
    { key: 'Billboard', text: 'Anzeigetafeln' },
];
export default function PoIProperties(properties: Properties) {
    const { t } = useTranslation();
    const [lowerDistance, setLowerDistance] = useState(0);
    const [higherDistance, setHigherDistance] = useState(1000);
    const [finalHigherDistance, setFinalHigherDistance] = useState(1000);
    const [chargingStations, setChargingStations] = useState([]);
    const [universities, setUniversities] = useState([]);
    const [billboards, setBillboards] = useState([]);
    const [advertisementColumns, setAdvertisementColumns] = useState([]);
    const [selectedPOITypes, setSelectedPOITypes] = useState<string[]>([]);

    const onPOITypesSelectionChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        if (item) {
            setSelectedPOITypes(
                item.selected ? [...selectedPOITypes, item.key as string] : selectedPOITypes.filter(key => key !== item.key),
            );
        }
    };

    useEffect(() => {
        const call = async () => {
            const building = properties.object as Building;

            const boundingBox = calculateBoundingBox(building.shape);

            console.log(boundingBox);
            console.log(`${boundingBox.centerLat} ${boundingBox.centerLon} ${finalHigherDistance}`);

            const newChargingStations: any = await userAPI.query(new Query(new EntityQuery(ChargingStation.type, [], undefined,
                Filter.getContainsPointFilter(boundingBox.centerLat, boundingBox.centerLon, finalHigherDistance)
            )));

            console.log(newChargingStations);

            setChargingStations(newChargingStations);

            const newUniversities: any = await userAPI.query(new Query(new EntityQuery(University.type, [], undefined,
                Filter.getContainsPointFilter(boundingBox.centerLat, boundingBox.centerLon, finalHigherDistance)
            )));

            console.log(newUniversities);

            setUniversities(newUniversities);

            const newBillboards: any = await userAPI.query(new Query(new EntityQuery(Billboard.type, [], undefined,
                Filter.getContainsPointFilter(boundingBox.centerLat, boundingBox.centerLon, finalHigherDistance)
            )));

            console.log(newBillboards);

            setBillboards(newBillboards);

            const newAdvertismentColumns: any = await userAPI.query(new Query(new EntityQuery(AdvertisementColumn.type, [], undefined,
                Filter.getContainsPointFilter(boundingBox.centerLat, boundingBox.centerLon, finalHigherDistance)
            )));

            console.log(newAdvertismentColumns);

            setAdvertisementColumns(newAdvertismentColumns);

            if (properties.poisChanged) {
                let newPOIs: PoI[] = [];

                if (selectedPOITypes.indexOf('ChargingStation') >= 0) {
                    newPOIs = [...newPOIs, ...newChargingStations];
                }

                if (selectedPOITypes.indexOf('University') >= 0) {
                    newPOIs = [...newPOIs, ...newUniversities];
                }

                if (selectedPOITypes.indexOf('Billboard') >= 0) {
                    newPOIs = [...newPOIs, ...newBillboards];
                }

                if (selectedPOITypes.indexOf('AdvertisementColumn') >= 0) {
                    newPOIs = [...newPOIs, ...newAdvertismentColumns];
                }

                properties.poisChanged(newPOIs);
            }
        };

        call();
    }, [finalHigherDistance, selectedPOITypes]);

    return <div>
        <div>
            <Text variant="large">Dienste und Orte in der Nähe</Text>
        </div>
        <div className='marginTopL'>
            <Dropdown
                placeholder='Bitte auswählen'
                selectedKeys={selectedPOITypes}
                // @ts-ignore
                onChange={onPOITypesSelectionChange}
                multiSelect
                options={poiTypes}
                styles={{ dropdown: { width: 300 } }}
            />
        </div>
        <div className="marginTopL">
            <Slider
                max={10000}
                step={100}
                ranged
                value={higherDistance}
                lowerValue={lowerDistance}
                valueFormat={(value: number) => `${value}m`}
                showValue
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(_: any, range: any) => {
                    setLowerDistance(range[0]);
                    setHigherDistance(range[1]);
                }}
                onChanged={(event: any, _: any, range: any) => {
                    setFinalHigherDistance(range[1]);
                }}></Slider>
        </div>
        {selectedPOITypes.indexOf('Kindergarten') >= 0
            ?
            <div className="marginTopS displayFlex alignItemsBaseline gapS">
                <div className="textXL fontWeightBold colorPrimary">4</div><div className="textL colorPrimary">Kindergärten</div>
            </div>
            :
            <></>
        }
        {selectedPOITypes.indexOf('BusStop') >= 0
            ?
            <div className="marginTopS displayFlex alignItemsBaseline gapS">
                <div className="textXL fontWeightBold colorSecondary">2</div><div className="textL colorSecondary">Bushaltestellen</div>
            </div>
            :
            <></>
        }
        {selectedPOITypes.indexOf('Supermarket') >= 0
            ?
            <div className="marginTopS displayFlex alignItemsBaseline gapS">
                <div className="textXL fontWeightBold colorPrimaryLight">3</div><div className="textL colorPrimaryLight">Supermärkte</div>
            </div>
            :
            <></>
        }
        {selectedPOITypes.indexOf('ChargingStation') >= 0
            ?
            <div className="marginTopS displayFlex alignItemsBaseline gapS">
                <div className="textXL fontWeightBold colorPrimaryDark">{chargingStations.length}</div><div className="textL colorPrimaryDark">Ladesäulen</div>
            </div>
            :
            <></>
        }
        {selectedPOITypes.indexOf('University') >= 0
            ?
            <div className="marginTopS displayFlex alignItemsBaseline gapS">
                <div className="textXL fontWeightBold colorSecondaryDark">{universities.length}</div><div className="textL colorSecondaryDark">Universitäten und Hochschulen</div>
            </div>
            :
            <></>
        }
        {selectedPOITypes.indexOf('Billboard') >= 0
            ?
            <div className="marginTopS displayFlex alignItemsBaseline gapS">
                <div className="textXL fontWeightBold colorPrimaryLight">{billboards.length}</div><div className="textL colorPrimaryLight">Anzeigetafeln</div>
            </div>
            :
            <></>
        }
        {selectedPOITypes.indexOf('AdvertisementColumn') >= 0
            ?
            <div className="marginTopS displayFlex alignItemsBaseline gapS">
                <div className="textXL fontWeightBold colorTernaryLight">{advertisementColumns.length}</div><div className="textL colorTernaryLight">Litfaßsäulen</div>
            </div> :
            <></>
        }
    </div >;
}