import { ActionButton, DatePicker, DefaultButton, Dropdown, ICommandBarItemProps, IconButton, IDropdownOption, Pivot, PivotItem, PrimaryButton, Text, TextField } from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { userAPI } from "../UserAPI";
import InfoList from "./InfoList";
import DIN276AssetProperties from "./DIN276AssetProperties";
import Dialog, { DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { EntityQuery, LinkedEntityQuery, Product, Query, RelationshipDirection, Document, is, ProductApproval, MeasurementTypeI18N, Floor, Building } from "@the-real-insight/tri-model";
import DataTable from "react-data-table-component";
import { configuration } from '../Configuration';
import { dataTableStyles } from "../styles";
import AssetList from "../components/AssetList";
import ReactApexChart from "react-apexcharts";
import i18next from "i18next";
import dayjs from "dayjs";
import { useCustomerTheme } from "../Theme";
import { useLocation, useNavigate } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import { useAdministrationAPI } from "../AdministrationAPI";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

class Properties {
  asset: any;
  processError?: (error: any) => void;
}

export default function AssetProperties(properties: Properties) {
  const { t } = useTranslation();
  const { theme } = useCustomerTheme();
  const navigate = useNavigate();
  const { updateAsset, updateAssetPhotos } = useAdministrationAPI();
  const [deviceType, setDeviceType] = useState() as any;
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [tab, setTab] = useState(searchParams.get("tab") || 'general');;
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [asset, setAsset] = useState(properties.asset) as any;
  const [editedAsset, setEditedAsset] = useState({}) as any;
  const [product, setProduct] = useState() as any;
  const [documents, setDocuments] = useState([]) as any;
  const [address, setAddress] = useState() as any;
  const [building, setBuilding] = useState() as any;
  const [seriesList, setSeriesList] = useState({}) as any;
  const [optionsList, setOptionsList] = useState({}) as any;
  const [floor, setFloor] = useState() as any;

  // TODO Move to component

  const [interval, setInterval] = useState('today') as any;
  const [intervalStart, setIntervalStart] = useState(dayjs().startOf('d').toDate()) as any;
  const [intervalEnd, setIntervalEnd] = useState(dayjs().endOf('d').toDate()) as any;
  const [assetImages, setAssetImages] = useState([]);

  console.log('Asset >>>', asset);

  const toggleEditDialogOpen = () => {
    setEditDialogOpen(!editDialogOpen);

    const newEditedAsset = { ...asset };

    if (!newEditedAsset.properties) {
      newEditedAsset.properties = {};
    }

    setEditedAsset(newEditedAsset);
  }

  const productColumns = [{
    name: t('buildingProperties.technicalEquipmentPropertiesPanel.document'),
    width: '100px',
    selector: (document: any) => document.url,
    cell: (document: any) => (
      <div className='height20 displayFlex flexDirectionColumn justifyContentCenter'>
        <a href={`${configuration.serverUrl}${document.url}`} target="_blank">
          <ActionButton iconProps={{ iconName: 'PDF' }}></ActionButton>
        </a>
      </div>
    ),
  }, {
    name: 'Type',
    width: '200px',
    selector: (document: any) => document.type,
    cell: (document: any) => (
      <div className='height20 displayFlex flexDirectionColumn justifyContentCenter overflowWrapWordWrap'>
        <Text variant='medium'>{document.type}</Text>
      </div>
    ),
    sortable: true,
  }, {
    name: 'Synopsis',
    width: '800px',
    selector: (document: any) => document.synopsis,
    cell: (document: any) => (
      <div className='height20 displayFlex flexDirectionColumn justifyContentCenter overflowWrapWordWrap'>
        <Text variant='medium'>{document.synopsis}</Text>
      </div>
    ),
    sortable: true,
  }];

  const intervalOptions: IDropdownOption[] = [
    { key: 'today', text: 'Today' },
    { key: 'last5d', text: 'Last 5 days' },
    { key: 'last30d', text: 'Last 30 days' },
    { key: 'last90d', text: 'Last 90 days' },
    { key: 'userDefined', text: 'User-defined' },
  ];

  const selectInterval = (key: string) => {
    setInterval(key);

    const now = dayjs(new Date());

    switch (key) {
      case 'last5d':
        setIntervalStart(now.subtract(5, 'd').startOf('d').toDate());
        setIntervalEnd(now.endOf('d').toDate());

        return;
      case 'last30d':
        setIntervalStart(now.subtract(30, 'd').startOf('d').toDate());
        setIntervalEnd(now.endOf('d').toDate());

        return;
      case 'last90d':
        setIntervalStart(now.subtract(90, 'd').startOf('d').toDate());
        setIntervalEnd(now.endOf('d').toDate());

        return;
      case 'other':
        return;
      case 'today':
      default:
        setIntervalStart(now.startOf('d').toDate());
        setIntervalEnd(now.endOf('d').toDate());

        return;
    }
  }

  const onAssetImagesChange = async (imageList: any, addUpdateIndex: any) => {
    try {
      console.log(imageList, addUpdateIndex);

      const formData = new FormData();

      formData.append("assetPhoto", imageList[0].file);

      console.log('Image >>>', imageList[0].file);

      setAsset(await updateAssetPhotos(asset, formData));
      setAssetImages([]);
    } catch (error: any) {

      if (properties.processError) {
        properties.processError(error);
      }
    }
  };

  useEffect(() => {
    if (!deviceType) {
      return;
    }

    const call = async () => {
      // Metrics

      const newSeriesList: any = {};
      const newOptionsList: any = {};
      const chartColors = [theme.secondary, theme.tertiary, theme.primary];
      let index = 0;

      for (const field of deviceType.state) {
        const metricValueSeries = await userAPI.queryTelemetry({ metric: field.id, asset: asset._id });

        newSeriesList[field.id] = {
          name: field.id,
          data: metricValueSeries.map((measurement: any) => [new Date(measurement.timestamp).getTime(), measurement.value]),
        }

        const events = [];
        let annotations: any = {
          xaxis: [],
          points: [],
        }

        for (const event of deviceType.events) {
          if (event.relatedState.indexOf(field.id) >= 0) {
            events.push(event.id);

            const occurrences = await userAPI.queryTelemetry({ metric: event.id, asset: asset._id });

            console.log('Occurrences >>>', event.id, occurrences);

            for (const occurrence of occurrences) {
              annotations.xaxis.push({
                x: dayjs(occurrence.timestamp).toDate().getTime(),
                strokeDashArray: 0,
                borderColor: '#AA0000',
                label: {
                  borderColor: '#AA0000',
                  style: {
                    color: '#fff',
                    background: '#AA0000',
                  },
                  text: MeasurementTypeI18N[i18next.language][occurrence.metric],
                }
              });
              // annotations.points.push({
              //   x: dayjs(occurrence.timestamp).toDate().getTime(),
              //   y: 1,
              //   marker: {
              //     size: 3,
              //     fillColor: '#fff',
              //     strokeColor: '#AA0000',
              //     radius: 1,
              //   },
              // });
            }
          }
        }

        if (field.id === 'pressure') {
          annotations.yaxis = [{
            y: 0.8,
            borderColor: '#AA0000',
            label: {
              borderColor: '#AA0000',
              style: {
                color: '#fff',
                background: '#AA0000',
              },
              text: 'Low Pressure',
            }
          }];
        }

        newOptionsList[field.id] = {
          chart: {
            height: 350,
            type: 'area'
          },
          dataLabels: {
            enabled: false
          },
          colors: [chartColors[index % 3]],
          stroke: {
            //curve: 'smooth',
            width: [1, 1],
          },
          xaxis: {
            type: 'datetime',
            min: intervalStart.getTime(),
            max: intervalEnd.getTime(),
          },
          yaxis: [{
            seriesName: field.id,
            min: 0,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            labels: {
              formatter: (val: number) => {
                return val.toFixed(1)
              },
            },
            title: {
              text: field.unit ? ` ${field.unit}` : '',
            }
          },
          ],
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm'
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 100]
            }
          },
          annotations,
        }

        ++index;
      }

      setSeriesList(newSeriesList);
      setOptionsList(newOptionsList);
    };

    call();
  }, [deviceType, theme, intervalStart, intervalEnd]);

  useEffect(() => {
    if (!asset) {
      return;
    }

    const call = async () => {
      try {
        if (asset.product) {
          let result = await userAPI.query(new Query(new EntityQuery(Product.type, [], undefined, is('_id').equal(asset.product))));

          if (result && result.length === 1) {
            setProduct(result[0]);
          }

          result = await userAPI.query(new Query(new EntityQuery(Product.type, [], undefined, is('_id').equal(asset.product)),
            [new LinkedEntityQuery('approvals', RelationshipDirection.out, ProductApproval.type),
            new LinkedEntityQuery('notification', RelationshipDirection.out, Document.type)]));

          if (result && result.length === 1) {
            setDocuments(result[0].approvals.map((approval: any) => {
              return {
                type: 'Device Approval',
                synopsis: approval.notification.synopsis,
                url: approval.notification.url,
              }
            }))
          }
        }

        if (asset.iotDeviceType) {
          const newCatalog = await userAPI.getDeviceTypeCatalog();

          const deviceTypePath = asset.iotDeviceType.split('/');

          setDeviceType(newCatalog[deviceTypePath[0]][deviceTypePath[1]]);
        }

        const buildings = await userAPI.query(new Query(new EntityQuery(Building.type, [], undefined, is('id').equal(`${asset.street} ${asset.streetNumber}, ${asset.postalCode} ${asset.city}, ${asset.country}`))));

        console.log('Buildings >>>', buildings);

        if (buildings && buildings.length === 1) {
          setBuilding(buildings[0]);
        }

        if (asset.floor) {
          const floors = await userAPI.query(new Query(new EntityQuery(Floor.type, [], undefined, is('_id').equal(asset.floor))));

          if (floors && floors.length === 1) {
            setFloor(floors[0]);
          }

        }
      } catch (error) {
        // TODO Process error

        console.log(error);
      }
    };

    call();
  }, [asset]);

  const selectTab = (value: any) => {
    const url = new URL(window.location.href);

    url.searchParams.set('tab', value.props.itemKey);
    window.history.replaceState(null, '', url.toString());

    setTab(value.props.itemKey);
  }

  return (asset
    ?
    <>
      <div>
        <div>
          <Text variant="medium">{t('assetPropertiesPanel.asset')}</Text>
        </div>
        <div>
          <div>
            <Text variant="xxLarge">{asset.id}/{asset.name}</Text>
          </div>
          <div className="displayFlex alignItemsCenter">
            <Text variant="medium">{asset ? `${asset.street} ${asset.streetNumber}, ${asset.postalCode} ${asset.city}` : ''}</Text>
            {building
              ?
              <IconButton iconProps={{ iconName: 'OpenInNewWindow' }} onClick={() => navigate(`/entity?selectedEntityType=Building&selectedEntity=${building._id}`)}></IconButton>
              :
              <></>}
            {floor
              ?
              <div className="displayFlex alignItemsCenter">
                <span>{floor.name}</span>
                <IconButton iconProps={{ iconName: 'OpenInNewWindow' }} onClick={() => navigate(`/entity?selectedEntityType=Floor&selectedEntity=${floor._id}`)}></IconButton>
              </div> :
              <></>}
          </div>
        </div>
        <div className="marginTopL">
          <Pivot onLinkClick={selectTab} selectedKey={String(tab)}>
            <PivotItem itemKey="general" headerText={t('assetPropertiesPanel.general')} itemIcon="Info">
              <div className="marginTopL">
                <div className="displayFlex justifyContentEnd">
                  <PrimaryButton text={t('global.edit')} onClick={() => {
                    toggleEditDialogOpen();
                  }} />
                </div>
                <InfoList list={[
                  {
                    label: t('assetPropertiesPanel.schema'),
                    value: asset.schema,
                  },
                  {
                    label: t('assetPropertiesPanel.schemaCategory'),
                    value: asset.schemaCategory,
                  },
                  {
                    label: 'Registered',
                    value: asset.registered ? `${asset.registered.toLocaleDateString()} ${asset.registered.toLocaleTimeString()}` : '-',
                  },
                  {
                    label: 'Registered by',
                    value: asset.registeredBy || '-',
                  },
                  {
                    label: 'Last modified',
                    value: asset.lastModified ? `${asset.lastModified.toLocaleDateString()} ${asset.lastModified.toLocaleTimeString()}` : '-',
                  },
                  {
                    label: 'Last modified by',
                    value: asset.lastModifiedBy || '-',
                  },
                  {
                    label: 'Details',
                    value: asset.details || '-',
                  },
                ]}></InfoList>
                {asset.schema === 'din276'
                  ?
                  <div className="marginTopL">
                    <Text variant="large">DIN 276 Eigenschaften</Text>
                    <div className="marginTopL">
                      <DIN276AssetProperties readonly type={asset.schemaCategory} properties={asset.properties || {}} setProperties={() => { }}></DIN276AssetProperties>
                    </div>
                  </div>
                  :
                  <></>
                }
              </div>
            </PivotItem>
            <PivotItem itemKey="measurements" headerText={t('assetPropertiesPanel.measurements')} itemIcon="LineChart">
              <div className="marginTopL">
                <div>
                  {deviceType
                    ?
                    <div>
                      <div className="displayFlex alignItemsCenter gapM">
                        <Dropdown
                          options={intervalOptions}
                          selectedKey={interval}
                          onChange={(e: any, item: any) => selectInterval(item.key)}
                          styles={{
                            dropdown: { width: 300 },
                          }}
                        />
                        <DatePicker disabled={interval !== 'userDefined'} style={{ width: 150 }} formatDate={(date: Date | undefined) => date ? dayjs(date).format('MM/DD hh:mm') : ''}
                          value={intervalStart} onSelectDate={(date: Date | null | undefined) => {
                            if (date) {
                              setIntervalStart(date);
                            }
                          }} />
                        <DatePicker disabled={interval !== 'userDefined'} style={{ width: 150 }} formatDate={(date: Date | undefined) => date ? dayjs(date).format('MM/DD hh:mm') : ''}
                          value={intervalEnd} onSelectDate={(date: Date | null | undefined) => {
                            if (date) {
                              setIntervalEnd(date);
                            }
                          }} />
                      </div>
                      <div className="marginTopM displayFlex gapL flexWrap">
                        {Object.keys(seriesList)
                          .filter((key: string) => {
                            return optionsList[key] && seriesList[key];
                          })
                          .map((key: string, index: number) =>
                            <div className="displayFlex flexDirectionColumn">
                              <div className="textM fontWeightBold">{MeasurementTypeI18N[i18next.language][key]}</div>
                              <ReactApexChart options={optionsList[key]} series={[seriesList[key]]} type="area" width={400} height={250} />
                            </div>
                          )}
                      </div>
                    </div>
                    :
                    <div className="displayFlex justifyContentCenter alignItemsCenter height200">
                      No IoT device type set for asset or asset product.
                    </div>
                  }
                </div>
              </div>
            </PivotItem>
            <PivotItem itemKey="product" headerText={'Product'} itemIcon="Document">
              <div className="marginTopL">
                {product
                  ?
                  <div>
                    <div className="textHeader">{product.name}</div>
                  </div>
                  :
                  <div>No product associated with asset.</div>
                }
                <div className="marginTopM">
                  <Text variant='large'>{t('buildingProperties.technicalEquipmentPropertiesPanel.documentation')}</Text>
                </div>
                <div className="marginTopM borderNeutral">
                  <DataTable
                    data={documents}
                    columns={productColumns}
                    customStyles={dataTableStyles}
                    pagination
                  />
                </div>
              </div>
            </PivotItem>
            <PivotItem itemKey="photos" headerText={'Photos'} itemIcon="Photo">
              <div className="marginTopM">
                <ImageUploading
                  value={assetImages}
                  onChange={onAssetImagesChange}
                  maxNumber={10}
                  maxFileSize={8000000}
                // dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="displayFlex gapM">
                      <div className="width200 height300 displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter borderNeutralDashed"
                        style={isDragging ? { borderColor: "red" } : undefined}
                        {...dragProps}>
                        <ActionButton iconProps={{ iconName: 'Photo2Add' }} onClick={onImageUpload}>
                          Drop file or select
                        </ActionButton>
                      </div>
                      {asset.photos ? asset.photos.map((uri: string, index: number) => <div key={index} className="displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter">
                        <Zoom>
                          <div role="img" className="width200 height300 borderNeutral"
                            style={{ width: 200, height: 300, backgroundImage: `url(${userAPI.getResourceURL(uri)})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} />
                        </Zoom>
                        <ActionButton iconProps={{ iconName: 'Delete' }} onClick={async () => {
                          try {
                            const updatedAsset = { ...asset };

                            updatedAsset.photos = updatedAsset.photos.filter((_: any, i: number) => i !== index);

                            setAsset(await updateAsset(updatedAsset));
                          } catch (error: any) {
                            console.log(error);

                            if (properties.processError) {
                              properties.processError(error);
                            }
                          }
                        }}>
                          Delete
                        </ActionButton>
                      </div>)
                        :
                        <></>}
                    </div>
                  )}
                </ImageUploading>
                {asset.typePlateImage
                  ?
                  <div className="displayFlex flexDirectionColumn alignItemsStart">
                    <div className="textSubHeading">Typenschild</div>
                    <div className="marginTopM displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter">
                      <Zoom>
                        <div role="img" className="width200 height300 borderNeutral" style={{ width: 200, height: 300, backgroundImage: `url(${userAPI.getResourceURL(asset.typePlateImage)})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} />
                      </Zoom>
                      <ActionButton iconProps={{ iconName: 'Delete' }} onClick={async () => {
                        try {
                          const updatedAsset = { ...asset };

                          delete updatedAsset.typePlateImage;

                          setAsset(await updateAsset(updatedAsset));
                        } catch (error: any) {
                          console.log(error);

                          if (properties.processError) {
                            properties.processError(error);
                          }
                        }
                      }}>
                        Delete
                      </ActionButton>
                    </div>
                  </div>
                  :
                  <></>
                }
              </div>
            </PivotItem>
          </Pivot >
        </div >
      </div >
      <Dialog hidden={!editDialogOpen}
        onDismiss={toggleEditDialogOpen}
        maxWidth={700}
        minWidth={700}
        dialogContentProps={{
          type: DialogType.normal,
          title: properties.asset.name,
          //subText: 'Do you want to send this message without a subject?',
        }}
        modalProps={{
          isBlocking: true,
        }}
      >
        <div className="width600">
          <TextField label="Details" multiline value={editedAsset.details} onChange={(_: any, details: any) => setEditedAsset({ ...editedAsset, details })}></TextField>
        </div>
        <DIN276AssetProperties type={editedAsset.schemaCategory} properties={editedAsset.properties} setProperties={(properties: any) => setEditedAsset({ ...editedAsset, properties })}></DIN276AssetProperties>
        <DialogFooter className="marginTopXXL">
          <PrimaryButton text={t('global.apply')} onClick={() => {
            userAPI.updateAsset(editedAsset);
            setAsset(editedAsset);
            toggleEditDialogOpen();
          }} />
          <DefaultButton onClick={toggleEditDialogOpen} text={t('global.cancel')} />
        </DialogFooter>
      </Dialog>
    </>
    :
    <div>
      <div className="textL">Assets</div>
      <div className="marginTopM">
        <AssetList></AssetList>
      </div>
    </div>);
}
