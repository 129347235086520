import '../App.sass';
import { useEffect, useState } from 'react';
import { FontIcon, IconButton } from '@fluentui/react';
import { userAPI } from '../UserAPI';
import { useTranslation } from "react-i18next";
import DataTable from 'react-data-table-component';
import { configuration } from '../Configuration';
import { EntityQuery, Query, LinkedEntityQuery, RelationshipDirection, Lease, NaturalPerson } from '@the-real-insight/tri-model';

const columns = [{
    name: '',
    width: '40px',
    cell: (item: any) => (
        <a href={`${configuration.serverUrl}${item.url}`} target="_blank">
            <FontIcon iconName='PDF'></FontIcon>
        </a>
    ),
},
{
    name: 'Mieter',
    width: '250px',
    cell: (item: any) => (
        <div>{item.tenant.firstName} {item.tenant.lastName}</div>
    ),
},
{
    name: 'Dauer',
    width: '100px',
    cell: (item: any) => (
        <div>{item.duration} Jahre</div>
    ),
},
{
    name: 'Datum',
    width: '100px',
    cell: (item: any) => (
        <span>{(item.startDate as Date).toLocaleDateString()}</span>
    ),
},
];

export default function LeaseBuildingProperties(props: any) {
    const { t } = useTranslation();
    const [items, setItems] = useState([] as any);
    const [contract, setContract] = useState(undefined as any);

    useEffect(() => {
        const call = async () => {
            const result = await userAPI.queryGraph(new Query(new EntityQuery(Lease.type),
                [/*new LinkedEntityQuery('contract', RelationshipDirection.in, Document.type, [], 'c'),*/
                    new LinkedEntityQuery('tenant', RelationshipDirection.out, NaturalPerson.type, [], 't')]));

            console.log(result);

            setItems(result.map((entry: any) => {
                entry.duration = entry.duration.low ? entry.duration.low : entry.duration
                entry.startDate = new Date(entry.startDate.year.low, entry.startDate.month.low, entry.startDate.day.low)

                return entry;
            }));
        };

        call();
    }, []);

    return <div>
        {contract
            ?
            <div>
                <div className="marginTopL">
                    <div className="displayFlex">
                        <div className='marginTopL headerL'>Mietvertrag 27.12.2022</div>
                        <div>
                            <IconButton iconProps={{ iconName: 'Cancel' }} title="Close" ariaLabel="Close" onClick={() => setContract(undefined)} />
                        </div>
                    </div>
                    <img src={`${configuration.serverUrl}${contract.url}`} alt={contract.url}></img>
                </div>
            </div>
            :
            <div>
                <div className='marginTopL headerL'>{t('leases')}</div>
                {items && items.length > 0
                    ?
                    <div className="marginTopM borderNeutral">
                        <DataTable
                            data={items}
                            columns={columns}
                        />
                    </div>
                    :
                    <div className='marginTopM'><div className="textM">{t('global.noInformationAvailable')}</div></div>
                }
            </div>
        }
    </div>;
}