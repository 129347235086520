import { imageClassNames } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import Carousel from 'react-multi-carousel';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export default function ProductPanel() {
  const { t } = useTranslation();

  return <>
    <div className="centeredContentSection">
      <div className="marginTopL titleText">The Real Insight</div>
      <div className='marginTopL largeBodyText textAlignCenter'>Cloud-Plattform und App für bessere Entscheidungen und Prozesse im Real Estate.</div>
      <div className="marginTopM largeBodyText textAlignCenter">Mit Daten aus über 300 Datenquellen.</div>
      <div className="marginTopM largeBodyText textAlignCenter">Ergänzt durch eure eigenen Daten und Dokumente.</div>
      <div className="marginTopM largeBodyText textAlignCenter">Durch AI aggregiert, angereichert und präsentiert.</div>
    </div>
    <div className="objectPictures">
      <div className='objectDetails'>
        <div className='headerText'>{t('buildings')}</div>
        <img className='marginTopM objectPicture' src="/building.png" alt="building"></img>
      </div>
      <div className='objectDetails'>
        <div className='headerText'>{t('land')}</div>
        <img className='marginTopM objectPicture' src="/land.png" alt="building"></img>
      </div>
      <div className='objectDetails'>
        <div className='headerText'>Infrastruktur</div>
        <img className='marginTopM objectPicture' src="/bridges.png" alt="building"></img>
      </div>
    </div>
    <div className="marginTopXXL centeredContentSection animate__animated animate__fadeIn animate__slow">
      <a className="hoverHand onboarding textAlignCenter" href="mailto:info@the-real-insight.com">{t('onboarding')}</a>
    </div>
    <div className="marginTopXXL centeredContentSection animate__animated animate__fadeIn animate__slow">
      <div className="headerText">{t('productPage.trustedBy')}</div>
    </div>
    <div className='marginTopM animate__animated animate__fadeIn animate__slow'>
      <div className="marginTopXL displayFlex justifyContentCenter alignItemsCenter scrollingBand">
        <div className="group">
          {/* <img className='customerLogo' src="/customers/ece.png" alt="ECE"></img>
          <img className='customerLogo' src="/customers/prologis.png" alt="prologis"></img> */}
          <img className='customerLogo' src="/customers/fire-iq.png" alt="Fire IQ"></img>
          <img className='customerLogo' src="/customers/mondrian.png" alt="Mondrian"></img>
          {/* <img className='customerLogo' src="/customers/wuerth.png" alt="Wuerth"></img> */}
          <img className='customerLogo' src="/customers/pastor.png" alt="Pastor"></img>
        </div>
        <div className="group">
          {/* <img className='customerLogo' src="/customers/ece.png" alt="ECE"></img>
          <img className='customerLogo' src="/customers/prologis.png" alt="prologis"></img> */}
          <img className='customerLogo' src="/customers/fire-iq.png" alt="Fire IQ"></img>
          <img className='customerLogo' src="/customers/mondrian.png" alt="Mondrian"></img>
          {/* <img className='customerLogo' src="/customers/wuerth.png" alt="Wuerth"></img> */}
          <img className='customerLogo' src="/customers/pastor.png" alt="Pastor"></img>
        </div>
      </div>
    </div>
    <div className="marginTopXXL centeredContentSection animate__animated animate__fadeIn animate__slow">
      <div className="headerText">Multi-modale Antworten</div>
      <div className="marginTopL bodyText textAlignCenter">
        Antworten auf Anfragen in Text, Tabellen, Diagrammen, interaktiven Kartendarstellung und ganzen Berichten - oder über ein einfach zu nutzendes API.
      </div>
    </div>
    <div className="marginTopXL centeredContentSection animate__animated animate__fadeIn animate__slow">
      <img src='./product/chatbot.png' className="img100Pct imgBorder" alt=""></img>
    </div>
    <div className="marginTopXXL centeredContentSection animate__animated animate__fadeIn animate__slow">
      <div className="headerText">Alles in einer App</div>
    </div>
    <div className="marginTopXL flow animate__animated animate__fadeIn animate__slow">
      <div className="storeBadgeContainer">
        <img src='./product/appStoreBadge_de.svg' className="storeBadge" alt="" onClick={() => window.open('https://apps.apple.com/app/the-real-insight/id6738629660', '_blank')}></img>
        <img src='./product/playStoreBadge_de.png' className="storeBadge" alt="" onClick={() => window.alert('Coming January 2025!')}></img>
      </div>
    </div>
    <div className="marginTopXXL centeredContentSection animate__animated animate__fadeIn animate__slow">
      <div className="headerText">Geräte und Ausstattung</div>
      <div className="marginTopL bodyText textAlignCenter">
        Betreiberverantwortung leicht gemacht:
      </div>
      <div className="marginTopM bodyText textAlignCenter">
        Schnelle und akkurate Bestandserfassung als Basis für Compliance-Analysen (z.B. F-Gase-Verordnung/Kältemittelverordnung, EU-Löschmittelverordnung), Nachhaltigkeitsanalysen, Beauftragung FM und mehr.
      </div>
    </div>
    <div className="marginTopXL animate__animated animate__fadeIn animate__slow">
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={6000}
        keyBoardControl={true}
        transitionDuration={3000}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        partialVisbile={false}
      >
        <div className='flow'>
          <div className="block66">
            <img src='./product/bim.png' className="img100Pct" alt=""></img>
          </div>
          <div className="block33">
            <div className="subHeaderText">Einfache Erfassung wesentlicher BIM-Daten</div>
            <div className="marginTopM bodyText">
              AI-basierte Analyse und Optimierung bestehender Grundrisse. Einfache Nachbearbeitung im Portal.
            </div>
          </div>
        </div>
        <div className='flow'>
          <div className="block50">
            <img src='./product/asset-location.png' className="img100Pct" alt=""></img>
          </div>
          <div className="block50">
            <div className="subHeaderText">Präzise Gerätepositionierung</div>
            <div className="marginTopM bodyText">
              Schnelle Bestimmung des Objekts, der Etage und genaue Lage im Grundriss in der App.
            </div>
          </div>
        </div>
        <div className='flow'>
          <div className="block50">
            <img src='./product/photo-to-data.png' className="img100Pct" alt=""></img>
          </div>
          <div className="block50">
            <div className="subHeaderText">Extraktion von Gerätedaten</div>
            <div className="marginTopM bodyText">
              Unsere AI extrahiert den Gerätetyp (DIN 276, FAC), Produkt und Hersteller und damit zugehörige Gerätedaten aus Fotos von Gerät und Typenschild.
            </div>
          </div>
        </div>
        <div className='flow'>
          <div className="block33">
            <img src='./product/equipment-registration.png' className="img100Pct" alt=""></img>
          </div>
          <div className="block66">
            <div className="subHeaderText">Inbetriebnahme und Überprüfung</div>
            <div className="marginTopM bodyText">
              Installation, Überprüfung des Betriebszustands und Wartung mit QR Code Scans aus der App.
            </div>
          </div>
        </div>
        <div className='flow'>
          <div className="block50">
            <img src='./product/iot.png' className="img100Pct imgBorder" alt=""></img>
          </div>
          <div className="block50">
            <div className="subHeaderText">Telemetrie</div>
            <div className="marginTopM bodyText">
              Einfache Verbindung zu IoT-Sensorik zur Optimierung des Betriebs und zur Unterstützung präventiver Wartung.
            </div>
          </div>
        </div>
      </Carousel>
    </div>
    <div className="marginTopXXL centeredContentSection animate__animated animate__fadeIn animate__slow">
      <div className="headerText">Was Kunden und Partner sagen</div>
    </div>
    <div className="marginTopXL animate__animated animate__fadeIn animate__slow">
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={8000}
        keyBoardControl={true}
        transitionDuration={3000}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        partialVisbile={false}
      >
        <div className='flow'>
          <div className="block33 quotePhoto">
            <div style={{ width: '300px', height: '450px', backgroundImage: 'url(./customers/carsten-dubberke.jpg)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} className="img100Pct"></div>
          </div>
          <div className="block66 quote">
            <div className="text">
              "Das anstehende Verbot von fluorhaltigen Löschmitteln (PFAS) und der aktue Fachkräftemangel hat uns angetrieben 'Brandschutz neu zu denken'.
              <br></br>
              <br></br>
              Unser Anspruch ist es, den tragbaren Feuerlöscher - nachhaltig sichtbar - sicherer zu machen.
              <br></br>
              <br></br>
              FireIQ und The Real Insight bilden gemeinsam das Fundament für den digitalen Brandschutz der Zukunft und werden diesen auf ein völlig neues Niveau heben.
            </div>
            <div className="author">Carsten Dubberke</div>
            <div className="role">Co-Founder FireIQ, Geschäftsführer Buschenhofen, Vorstandsmitglied des Bundesverbandes Brandschutz-Fachbetriebe e.V.</div>
          </div>
        </div>
        <div className='flow'>
          <div className="block33 quotePhoto">
            <div style={{ width: '300px', height: '450px', backgroundImage: 'url(./customers/domagoj-milosevic.jpg)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} className="img100Pct"></div>
          </div>
          <div className="block66 quote">
            <div className="text">
              "With my background, I stay closely attuned to regulatory changes. Naturally, we began planning our new generation of digital, online fire extinguishers early on, recognizing from the outset the need for a robust software platform to facilitate commissioning and operations.
              <br></br>
              <br></br>
              For this, we turned to the experts at The Real Insight to provide this platform.
              <br></br>
              <br></br>
              An excellent example of effective cross-EU collaboration."
              <br></br>
              <br></br>
            </div>
            <div className="author">Domagoj I. Milosevic</div>
            <div className="role">Founder & CEO ⁠Pastor Group, ehem. stellv. Premierminister Kroatien und Mitglied des kroatischen Parlaments</div>
          </div>
        </div>
        <div className='flow'>
          <div className="block33 quotePhoto">
            <div style={{ width: '300px', height: '450px', backgroundImage: 'url(./customers/dennis-diekmann.jpg)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} className="img100Pct"></div>
          </div>
          <div className="block66 quote">
            <div className="text">
              "The Real Insight verkürzt die Zeit zur Bestandserfassung enorm und schafft durch die effizienten Eingabemasken, sowie der AI Unterstützung eine akkurate Datenbasis mit der wir mit unseren Kunden die eigentlichen Aufgaben zur Erfüllung der Betreiberpflichten in Bereichen wie Nachhaltigkeit, Compliance und Beauftragung von Dienstleistern angehen können"
              <br></br>
              <br></br>
              Und auch da kann dann The Real Insight mit ihrer AI wieder ein hilfreiches Tool sein."
            </div>
            <div className="author">Dennis Diekmann</div>
            <div className="role">CEO Mondrian</div>
          </div>
        </div>
        <div className='flow'>
          <div className="block33 quotePhoto">
            <div style={{ width: '300px', height: '450px', backgroundImage: 'url(./customers/thomas-podstawski.jpg)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} className="img100Pct"></div>
          </div>
          <div className="block66 quote">
            <div className="text">
              "Ich kenne die Hindernisse und Kostentreiber im Betrieb meiner Immobilien genau. Der Umgang mit Fachkräftemangel oder einer unvollständigen und fehlerhaften Datenbasis für den Bestand gehören zu meinem Alltag.
              <br></br>
              <br></br>
              Der Nutzen von The Real Insight war mir daher vom ersten Moment an klar. Und ebenso, dass ich in dieses Unternehmen investieren würde."
            </div>
            <div className="author">Thomas Podstawski</div>
            <div className="role">CEO Podstawski Group & The Real Insight Investor</div>
          </div>
        </div>
      </Carousel>
    </div>
    <div className="marginTopXXL centeredContentSection animate__animated animate__fadeIn animate__slow">
      <div className="headerText">Energieeffizienz und Nachhaltigkeit</div>
      <div className="marginTopM bodyText textAlignCenter">
        Unsere AI kombiniert bauliche, geographische und Infrastrukturdaten mit Daten aus der Bestandserfassung. Zur unmittelbaren Bewertung von Energie- und Nachhaltigkeitsmaßnahmen.
      </div>
    </div>
    <div className="marginTopXL animate__animated animate__fadeIn animate__slow">
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={6000}
        keyBoardControl={true}
        transitionDuration={3000}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        partialVisbile={false}
      >
        <div className='flow'>
          <div>
            <div className="subHeaderText">CO<sub>2</sub>-Bilanz</div>
            <div className="marginTopM bodyText">
              Beiträge aller Komponenten der Technischen Gebäudeausstattung zu Energieverbrauch und CO<sub>2</sub>-Emissionen. Geschätzt, spezifiziert oder gemessen.
            </div>
            <img className="marginTopM img100Pct" src='./product/co2-balance.png' alt=""></img>
          </div>
        </div>
        <div className='flow'>
          <div>
            <div className="subHeaderText">Photovoltaik</div>
            <div className="marginTopM bodyText">
              Modellrechnungen zur Installation von Photovoltaik auf der Basis von Gebäudegeometrie, Lage und lokalem Dienstleistungsangebot.
            </div>
            <img className="marginTopM img100Pct" src='./product/pv.png' alt=""></img>
          </div>
        </div>
        <div className='flow'>
          <div>
            <div className="subHeaderText">Nachhaltigkeitsmaßnahmen</div>
            <div className="marginTopM bodyText">
              Abschätzung der Effektivität und der Kosten von Maßnahmen zur CO<sub>2</sub>-Reduktion.
            </div>
            <img className="marginTopM img100Pct" src='./product/esg.png' alt=""></img>
          </div>
        </div>
      </Carousel>
    </div>
    <div className="marginTopXXL flow animate__animated animate__fadeIn animate__slow">
      <div className="block33">
        <div className="headerText">Dokumente</div>
        <div className="marginTopM bodyText">
          Unsere AI erkennt und analysiert 32 verschiedene Dokumententypen (z.B. Mietverträge, Gerätehandbücher oder Wartungsprotokolle) von gescannten Dokumenten,
          assoziiert diese mit Objekten und Personen und stellt diese Daten zur Unterstützung von Auswertungen und Prozessen bereit.
        </div>
        <div className="marginTopM bodyText">
          Sicher abgelegt auf unserer Cloud-Plattform.
        </div>
      </div>
      <div className="block66">
        <img src='./product/devices.png' className="img100Pct imgBorder" alt=""></img>
      </div>
    </div>
    <div className="marginTopXXL flow animate__animated animate__fadeIn animate__slow">
      <div className="block66">
        <img src='./product/poi.png' className="img100Pct imgBorder" alt=""></img>
      </div>
      <div className="block33">
        <div className="headerText">Points of Interest</div>
        <div className="marginTopM bodyText">
          Schnelle Übersicht zu relevanten Orten und Diensten in der Umgebung - mit all' deren Details. Zur Bewertung von Projekten und Objekten.
        </div>
        <div className="marginTopM bodyText">
          Infrastruktur, Versorgung, Bildung, Unterhaltung, Sport und mehr - ständig aktualisiert und erweitert.
        </div>
      </div>
    </div>
    <div className="marginTopXXL marginBottomXXL flow animate__animated animate__fadeIn animate__slow">
      <div className="block33">
        <div className="headerText ">Flächendaten</div>
        <div className="marginTopM bodyText">
          Umfassende Daten auf Rasterflächen und Postleitzahlengebieten.
        </div>
        <div className="marginTopM bodyText">
          Soziodemographie, Mieten und Kaufpreise, Kriminalität, Mobilfunkabdeckung, Energie- und Versorgungsdaten - und mehr.
        </div>
      </div>
      <div className="block66">
        <img src='./product/tile.png' className="img100Pct imgBorder" alt=""></img>
      </div>
    </div>
  </>;
}
