import { Pivot, PivotItem, Text } from "@fluentui/react";
import { useEffect, useState } from "react";
import { EntityQuery, is, LegalPerson, LinkedEntityQuery, Query, RelationshipDirection, Subsidiary } from "@the-real-insight/tri-model";
import { userAPI } from "../UserAPI";
import InfoList from "./InfoList";

interface Properties {
  legalPerson?: LegalPerson;
  processError?: (error: any) => void;
}

export default function LegalPersonProperties(properties: Properties) {
  const [tab, setTab] = useState(0);
  const [legalPerson, setLegalPerson] = useState() as any;

  const setParams = (value: any) => {
    console.log('Value >>>', value);

    const url = new URL(window.location.href);

    url.searchParams.set('tab', value.props.itemKey);
    window.history.replaceState(null, '', url.toString());

    setTab(value.props.itemKey);
  };

  useEffect(() => {
    if (!properties.legalPerson) {
      return;
    }

    const call = async () => {
      const legalPersons = await userAPI.query(new Query(new EntityQuery(LegalPerson.type, [], undefined,
        // @ts-ignore
        is('_id').equal(`${properties.legalPerson._id}`)), [new LinkedEntityQuery('subsidiaries', RelationshipDirection.out, Subsidiary.type)]));

      console.log('Legal persons >>>', legalPersons);

      if (legalPersons.length > 0) {
        setLegalPerson(legalPersons[0]);
      }
    };

    call();
  }, [properties]);

  return (legalPerson
    ?
    <div>
      <div>
        <Text variant="medium">Company</Text>
      </div>
      <div>
        <Text variant="xxLarge">{legalPerson.name}</Text>
      </div>
      <div>
        <Pivot className="marginTopM" onLinkClick={setParams} selectedKey={String(tab)} overflowBehavior={'menu'}>
          <PivotItem itemKey="general" headerText={'General'} itemIcon="Info">
            <div className="marginTopM">
              <InfoList list={[
                {
                  label: 'Legal Form',
                  value: legalPerson.legalForm,
                }, {
                  label: 'Founded',
                  value: legalPerson.started ? new Date(legalPerson.started).toLocaleDateString() : '-',
                }, {
                  label: 'Trade Register ID',
                  value: legalPerson.tradeRegisterId,
                }, {
                  label: 'Trade Register City',
                  value: legalPerson.tradeRegisterCity,
                }, {
                  label: 'Website',
                  value: legalPerson.website ? legalPerson.website : '-',
                },
              ]}></InfoList>
            </div>
          </PivotItem>
          <PivotItem itemKey="subsidiaries" headerText={'Subsidiaries'} itemIcon="MapPin">
            {legalPerson.subsidiaries && legalPerson.subsidiaries.length > 0
            ?
            <></>
            :
            <div className="marginTopM">No subsidiaries</div>
            }
          </PivotItem>
        </Pivot>
      </div>
    </div>
    :
    <></>
  );
}
