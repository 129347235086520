import '../App.sass';

interface Properties {
  title: string;
  className?: string;
  color?: string;
  value: string;
  unit?: string;
}

export default function Metric(properties: Properties) {
  return <div className={`${properties.className ? properties.className + ' ' : ''}displayFlex flexDirectionColumn alignItemsCenter width150`}>
    <div className='textM' style={{ color: properties.color }}>{properties.title}</div>
    <div className='displayFlex alignItemsBaseline gapXS'>
      <span className='textXXL fontWeightBold' style={{ color: properties.color }}>{properties.value}</span>
      {properties.unit
        ?
        <span className='textL fontWeightBold' style={{ color: properties.color }}>{properties.unit}</span>
        :
        <></>}
    </div>
  </div>;
}
