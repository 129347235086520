import { CommandBar, ICommandBarItemProps, Slider, Text } from "@fluentui/react";
import { useEffect, useState } from "react";
import InfoList from "./InfoList";
import { Area, Building, EntityQuery, Filter, LandUse, LocalGovernment, PlotOfLand, PostalCode, Query, getGeoArea } from "@the-real-insight/tri-model";
import { userAPI } from "../UserAPI";
import SocioDemographicsProperties from "./plot-of-land/SocioDemographicsProperties";
import DecarbonizationProperties from "./plot-of-land/DecarbonisationProperties";
import PoIProperties from "./plot-of-land/PoIProperties";

class Properties {
  plotOfLand: any;
  poisChanged?: (pois: any[]) => void;
}

const numberFormat = new Intl.NumberFormat('de-DE', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default function PlotOfLandProperties(properties: Properties) {
  const [tab, setTab] = useState('usage');
  const [postalCode, setPostalCode] = useState() as any;
  const [area, setArea] = useState(0);

  const _items: ICommandBarItemProps[] = [{
    key: 'general',
    text: 'Allgemein',
    iconProps: { iconName: 'Location' },
    onClick: () => setTab('general'),
  }, {
    key: 'buildings',
    text: 'Bebauung',
    iconProps: { iconName: 'EMI' },
    onClick: () => setTab('buildings'),
  }, {
    key: 'sociodemographics',
    text: 'Soziodemographie',
    iconProps: { iconName: 'SchoolDataSyncLogo' },
    onClick: () => setTab('sociodemographics'),
  }, {
    key: 'crime',
    text: 'Kriminalität',
    iconProps: { iconName: 'EndPointSolid' },
    onClick: () => setTab('crime'),
  }, {
    key: 'poi',
    text: 'In der Nähe',
    iconProps: { iconName: 'SchoolDataSyncLogo' },
    onClick: () => setTab('poi'),
  }, {
    key: 'topology',
    text: 'Beschaffenheit',
    iconProps: { iconName: 'MountainClimbing' },
    onClick: () => setTab('topology'),
  }, {
    key: 'weather',
    text: 'Wetter',
    iconProps: { iconName: 'CloudWeather' },
    onClick: () => setTab('weather'),
  }, {
    key: 'project',
    text: 'Projekt',
    iconProps: { iconName: 'ExternalBuild' },
    onClick: () => setTab('project'),
  }, {
    key: 'ownership',
    text: 'Eigentumsverhältnisse',
    iconProps: { iconName: 'Compare' },
    onClick: () => setTab('ownership'),
  }, {
    key: 'energyManagement',
    text: 'Energiemanagment',
    iconProps: { iconName: 'Lightbulb' },
    onClick: () => setTab('energyManagement'),
  }, {
    key: 'decarbonisation',
    text: 'Dekarbonisierung',
    iconProps: { iconName: 'Lightbulb' },
    onClick: () => setTab('decarbonisation'),
  }];

  const title = () => {
    if (properties.plotOfLand.name) {
      return properties.plotOfLand.name;
    }

    switch (properties.plotOfLand.landUse) {
      case LandUse.greenfield: return 'Grünfläche';
      case LandUse.brownfield: return 'Industriebrache';
      case LandUse.water: return 'Wasserfläche';
      case LandUse.forest: return 'Waldgebiet';
      case LandUse.industrial: return 'Gewerbefläche';
      case LandUse.sportsAndEntertainment: return 'Sport & Erholung';
      case LandUse.cemetary: return 'Friedhof';
      case LandUse.dump: return 'Halde';
      case LandUse.residential: return 'Wohngebiet';
      default: return 'Unbekannte Nutzung';
    }
  }

  useEffect(() => {
    setArea(getGeoArea(properties.plotOfLand.shape));
  }, [properties]);

  useEffect(() => {
    const call = async () => {
      let minLat = Number.MAX_SAFE_INTEGER;
      let maxLat = Number.MIN_SAFE_INTEGER;
      let minLon = Number.MAX_SAFE_INTEGER;
      let maxLon = Number.MIN_SAFE_INTEGER;

      properties.plotOfLand.shape.forEach((point: any) => {
        minLat = Math.min(point.lat, minLat);
        maxLat = Math.max(point.lat, maxLat);
        minLon = Math.min(point.lon, minLon);
        maxLon = Math.max(point.lon, maxLon);
      });

      try {
        // const result = await userAPI.queryNoSQL(LocalGovernment.type.name, {
        //   $and: [{ minLat: { $lte: minLat } },
        //   { maxLat: { $gte: maxLat } },
        //   { minLon: { $lte: minLon } },
        //   { maxLon: { $gte: maxLon } },]
        // });
        const result = await userAPI.query(new Query(new EntityQuery(LocalGovernment.type, [], undefined, Filter.geoContainsBoundingBoxFilter(minLat, maxLat, minLon, maxLon))));

        console.log('Local Government', result);
      } catch (error) {
        console.error(error);
      }

      try {
        // const result = await userAPI.queryNoSQL(PostalCode.type.name, {
        //   $and: [{ minLat: { $lte: minLat } },
        //   { maxLat: { $gte: maxLat } },
        //   { minLon: { $lte: minLon } },
        //   { maxLon: { $gte: maxLon } },]
        // });
        const result = await userAPI.query(new Query(new EntityQuery(PostalCode.type, [], undefined, Filter.geoContainsBoundingBoxFilter(minLat, maxLat, minLon, maxLon))));

        setPostalCode(result.length > 0 ? result[0] : undefined);

        console.log('Postal Code', result);
      } catch (error) {
        console.error(error);
      }

      try {
        const result = await userAPI.query(new Query(new EntityQuery(Area.type, [], undefined, Filter.geoBoundingBoxOverlapFilter(minLat, maxLat, minLon, maxLon))));

        console.log('Areas', result);
      } catch (error) {
        console.error(error);
      }

      try {
        const result = await userAPI.query(new Query(new EntityQuery(Building.type, [], undefined,
          Filter.geoBoundingBoxOverlapFilter(minLat, maxLat, minLon, maxLon))));

        console.log('Buildings', result);
      } catch (error) {
        console.error(error);
      }
    };

    call();
  }, [properties]);

  return (
    <div>      
      <div>
        <CommandBar
          styles={{ root: { padding: '0px', } }}
          items={_items}
          ariaLabel=""
          farItemsGroupAriaLabel="More actions"
        />
      </div>
      <div className="marginTopL">
        {(() => {
          switch (tab) {
            case 'general': return <div>
              <div>
                <div className="textL">Postleitzahlengebiet</div>
              </div>
              <div className="displayFlex alignItemsBaseline gapS">
                <div className="textXXL colorSecondary">{postalCode ? postalCode.postalCode : ''}</div><div className="textXL colorSecondary">{postalCode ? postalCode.city : ''}</div>
              </div>
              <div className="marginTopL">
                <div className="textL colorPrimary">Fläche</div>
              </div>
              <div className="displayFlex alignItemsBaseline gapXS">
                <div className="textXXL fontWeightBold colorPrimary">{numberFormat.format(area)}</div><div className="textXL colorPrimary">&#13217;</div>
              </div>
            </div>
            case 'usage': return <div>
              <div>
                <Text variant="large">Gebäude</Text>
              </div>
              <div>
                <Text variant="large">Sport</Text>
              </div>
            </div>
            case 'topology': return <div>
              <div>
                <Text variant="large">Höhenprofil</Text>
              </div>
              <div>
                <Text variant="large">Boden</Text>
              </div>
              <div>
                <Text variant="large">Bewuchs</Text>
              </div>
            </div>
            case 'sociodemographics': return <SocioDemographicsProperties object={properties.plotOfLand}></SocioDemographicsProperties>
            case 'poi': return <PoIProperties object={properties.plotOfLand} poisChanged={properties.poisChanged}></PoIProperties>
            case 'weather': return <div>
              <div>
                <Text variant="large">Population</Text>
              </div>
            </div>
            case 'crime': return <div>
              <div className="marginTopL">
                <Text variant="large">Kriminalität</Text>
              </div>
              <div className="marginTopL displayFlex alignItemsBaseline gapS">
                <div className="textXXL fontWeightBold colorPrimaryLight">7.200</div><div className="textXL colorPrimaryLight">Straftaten/100.000 Einwohner</div>
              </div>
              <div className="marginTopM displayFlex alignItemsBaseline gapS">
                <div className="textXL fontWeightBold colorPrimary">3%</div><div className="textL colorPrimary">über Bundesdurchschnitt</div>
              </div>
              <div className="marginTopS displayFlex alignItemsBaseline gapS">
                <div className="textXL fontWeightBold colorPrimary">5%</div><div className="textL colorPrimary">über Durchschnitt Hamburg</div>
              </div>
            </div>
            case 'ownership': return <div>
              <div>
                <Text variant="large">Eigentümer</Text>
              </div>
            </div>
            case 'project': return <div>
              <div>
                <InfoList list={[
                  {
                    label: 'Webseite',
                    value: properties.plotOfLand.website,
                  },]}></InfoList>
              </div>
            </div>
            case 'decarbonisation': return <div>
              <DecarbonizationProperties object={properties.plotOfLand}></DecarbonizationProperties>
            </div>
          }
        })()}
      </div>
    </div>
  );
}
