import { CommandBar, ICommandBarItemProps, Text } from "@fluentui/react";
import { useState } from "react";
import InfoList from "./InfoList";
import { ChargingStation, ChargingType } from "@the-real-insight/tri-model";
import DataTable from "react-data-table-component";

interface Properties {
  chargingStation: ChargingStation;
}

export default function ChargingStationProperties(properties: Properties) {
  const [tab, setTab] = useState('general');

  const _items: ICommandBarItemProps[] = [{
    key: 'general',
    text: 'General',
    iconProps: { iconName: 'Info' },
    onClick: () => setTab('general'),
  }
  ];
  const columns = [{
    name: 'Steckertyp',
    width: '200px',
    cell: (plug: any) => (
      <span>{plug.plugType}</span>
    ),
  }, {
    name: 'Leistung',
    width: '100px',
    cell: (plug: any) => (
      <span>{plug.power} kW</span>
    ),
  }, {
    name: 'Öffentlicher Schlüssel',
    width: '300px',
    cell: (plug: any) => (
      <span>{plug.publicKey}</span>
    ),
  }
  ];

  return (
    <div>      
      <div>
        <CommandBar
          styles={{ root: { padding: '0px', } }}
          items={_items}
          ariaLabel=""
          farItemsGroupAriaLabel="More actions"
        />
      </div>
      {(() => {
        switch (tab) {
          case 'general': return <div>
            <div>
              <InfoList list={[
                {
                  label: 'Nennleistung',
                  value: properties.chargingStation.ratedPower,
                  suffix: 'kW',
                },
                {
                  label: 'Inbetriebnahme',
                  value: new Date(properties.chargingStation.commissioningDate).toLocaleDateString(),
                },
                {
                  label: 'Anzahl Ladepunkte',
                  value: properties.chargingStation.numberOfChargingPoints,
                },]}></InfoList>
            </div>
            {properties.chargingStation.street || properties.chargingStation.streetNumber
              ?
              <>
                <div className="marginTopL headerL">Adresse</div>
                <div>
                  <InfoList list={[
                    {
                      label: 'Straße',
                      value: `${properties.chargingStation.street} ${properties.chargingStation.streetNumber}`,
                    },
                    {
                      label: 'Ort',
                      value: `${properties.chargingStation.postalCode} ${properties.chargingStation.city}`,
                    }]}></InfoList>
                </div>
              </>
              :
              <></>
            }
            <div className="marginTopL headerL">Stecker</div>
            <div>
              <DataTable
                data={properties.chargingStation.plugTypes || []}
                columns={columns}
              />
            </div>
          </div>
        }
      })()}
    </div>
  );
}
