import "/node_modules/flag-icons/css/flag-icons.min.css";
import './App.sass';
import { useCallback, useRef, useState } from "react";
import { IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { makeStyles } from '@fluentui/react-components';
import { Persona, PersonaInitialsColor, PersonaSize } from '@fluentui/react/lib/Persona';
import { useNavigate } from "react-router-dom";
import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { Panel } from '@fluentui/react/lib/Panel';
import { useAtom } from "jotai";
import { authenticationAtom } from './State';
import { Text } from '@fluentui/react/lib/Text';
import { useTranslation } from "react-i18next";
import { Overlay } from "@fluentui/react";
import i18next from "i18next";
import { userAPI } from "./UserAPI";
import { useCustomerTheme } from "./Theme";

interface Properties {
  showBack?: boolean,
  showHome?: boolean,
  showSidePanel?: boolean,
  showMapItem?: boolean,
  showTabs?: boolean,
  toggleSidePanel?: any,
  title?: string,
  selectTab?: any,
}

const useStyles = makeStyles({
  settings: {
    marginBottom: '10px',
  },
  flex: {
    display: 'flex',
  },
  container: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  end: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'end',
    marginLeft: '20px',
  },
  buttons: {
    display: 'flex',
    gap: '10px',
  },
});

export enum Tabs {
  Product = 'Product',
  Company = 'Company',
  Security = 'Security',
}

function Header(properties: Properties) {
  const { t, i18n } = useTranslation();
  const styles = useStyles();
  const navigate = useNavigate();
  const [tab, setTab] = useState(Tabs.Product);
  const [showCallout, setShowCallout] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [locale, setLocale] = useState(i18next.resolvedLanguage);
  const [showLocalePopup, setShowLocalePopup] = useState(false);
  const onHideContextualMenu = useCallback(() => setShowCallout(false), []);
  const linkRef = useRef(null);
  const [authentication, setAuthentication] = useAtom(authenticationAtom) as any;
  const { logo } = useCustomerTheme();

  const menuItems: IContextualMenuItem[] = [{
    key: 'settings',
    iconProps: { iconName: 'Settings' },
    text: 'Settings',
    onClick: () => navigate('/settings'),
  }];

  if (authentication && (!authentication.customer || authentication.customer === '0')) {
    menuItems.push({
      key: 'settings',
      iconProps: { iconName: 'Repair' },
      text: 'Administration',
      onClick: () => navigate('/administration'),
    });
  }

  menuItems.push({
    key: 'divider_1',
    itemType: ContextualMenuItemType.Divider,
  });
  menuItems.push({
    key: 'logout',
    iconProps: { iconName: 'Leave' },
    text: 'Log out',
    onClick: () => {
      setAuthentication(null);
      userAPI.logout();
      navigate('/');
    },
  });

  const selectTab = (newTab: Tabs) => {
    setTab(newTab);

    if (properties.selectTab) {
      properties.selectTab(newTab);
    }
  }

  const mapLocaleToCountryCode = (locale: string | undefined) => {
    switch (locale) {
      case 'de': return 'de';
      case 'en': return 'gb';
    }
  }

  const back = () => {
    window.history.back()
  }

  return (<><div className="portalHeader borderBottomNeutral width100vw">
    {properties.showBack
      ?
      <IconButton iconProps={{ iconName: 'Back' }} ariaLabel="Back" onClick={() => back()} />
      :
      <></>

    }
    {properties.showHome && !logo
      ?
      <IconButton iconProps={{ iconName: 'OfficeChat' }} title="Chat" ariaLabel="Chat" onClick={() => navigate("/chatbot")} />
      :
      <></>}
    {logo
      ?
      <div onClick={() => { }}>
        <img className='logoImage' src={logo} alt="logo"></img>
      </div>
      :
      <></>
    }
    <div className='title'><Text variant='xxLarge'>{properties.title}</Text></div>
    <div className="marginRightXL hoverHand">
      <span className={`fi fi-${mapLocaleToCountryCode(locale)}`} onClick={() => { setShowLocalePopup(true) }}></span>
      <div className="positionRelative">
        {showLocalePopup && (
          <>
            <div className={`popupContent ${showLocalePopup ? '' : 'hidden'}`}>
              <div className={`flex hoverHand`} onClick={() => {
                setShowLocalePopup(false);
                setLocale('de');
                i18n.changeLanguage("de");
              }}>
                <span className="fi fi-de" ></span><span className="marginLeftS">Deutsch</span>
              </div>
              <div className="marginTopS flex hoverHand" onClick={() => {
                setShowLocalePopup(false)
                setLocale('en');
                i18n.changeLanguage("en");
              }}>
                <span className="fi fi-gb  marginRighS" ></span><span className="marginLeftS">English</span>
              </div>
            </div>
            <div
              className="popupOverlay"
              onClick={() => { setShowLocalePopup(false) }}
            >
              <Overlay />
            </div>
          </>
        )
        }
      </div>
    </div>
    <a ref={linkRef} onClick={() => setShowCallout(!showCallout)}>
      <Persona {...{
        imageInitials: `${(authentication as any).firstName.charAt(0)}${(authentication as any).lastName.charAt(0)}`
      }} initialsColor={PersonaInitialsColor.lightBlue} size={PersonaSize.size40} />
    </a>
    <ContextualMenu
      items={menuItems}
      hidden={!showCallout}
      target={linkRef}
      onItemClick={onHideContextualMenu}
      onDismiss={onHideContextualMenu}
    />
  </div>
    <Panel
      isLightDismiss
      isOpen={settingsOpen}
      onDismiss={() => {
        setSettingsOpen(false);
      }}
      closeButtonAriaLabel="Close"
      headerText="Settings"
    >
      <div className={styles.settings}>
        <div className={styles.flex}>
        </div>
        <div className={styles.flex}>
        </div>
        <div className={styles.flex}>
          <PrimaryButton text={'Suggestion'} onClick={() => { setSettingsOpen(false); }} ariaLabel="Trigger Suggestion" />
        </div>
      </div>
    </Panel>
  </>);
}

export default Header;
