import './css/website.sass';
import 'animate.css';
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return <div key={Math.random()} className='marginTopXL marginBottomXXL footer animate__animated animate__fadeIn animate__slow'>
    <div className='flow'>
      <div className="block50">
        <img className='logoImage' src="/logo.png" alt="logo"></img>
      </div>
      <div className="block50">
        <div className="bodyText">Copyright © 2024 The Real Insight UG. All rights reserved.</div>
      </div>
      {/* <div className="marginTopM displayFlex alignItemsCenter">
        <div className="textM">{t('footer.followUs')}</div>
        <div className="marginLeftS"><img className="socialMediaLogo" src="/social-media/x-logo.png" alt="x-logo" /></div>
        <div className="marginLeftS"><img className="socialMediaLogo" src="/social-media/li-logo.png" alt="li-logo" /></div>
      </div> */}
    </div>
    <div className="flow">
      <div className="block50">
        <div className="headerText">
          The Real Insight
        </div>
      </div>
      <div className="block50 displayFlex">
        <div className="displayFlex flexDirectionColumn alignItemsStart">
          <div className="bodyText fontWeightBold">{t('footer.company')}</div>
          <a href="/#product"><div className="marginTopS bodyText">{t('footer.product')}</div></a>
          <a href="/#security"><div className="marginTopS bodyText">{t('footer.security')}</div></a>
          <a href="/#company"><div className="marginTopS bodyText">{t('footer.team')}</div></a>
        </div>
        <div className='marginLeftXXL displayFlex marginBottomM displayFlex flexDirectionColumn alignItemsStart'>
          <div className="bodyText fontWeightBold">{t('footer.resources')}</div>
          <a href="/imprint"><div className="marginTopS bodyText">Impressum</div></a>
          <a href="/legal"><div className="marginTopS bodyText">{t('footer.legal')}</div></a>
          <a href="/press"><div className="marginTopS bodyText">{t('footer.press')}</div></a>
          <a href="/support"><div className="marginTopS bodyText">{t('footer.support')}</div></a>
        </div>
      </div>
    </div>
  </div>;
}
