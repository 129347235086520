import 'react-multi-carousel/lib/styles.css';
import 'animate.css';
import './css/website.sass';
import { useEffect, useState } from 'react';
import WebsiteHeader, { Tabs } from './WebsiteHeader';
import { useTranslation } from "react-i18next";
import Footer from './Footer';
import SecurityPanel from './SecurityPanel';
import CompanyPanel from './CompanyPanel';
import ProductPanel from './ProductPanel';
import CookieConsent from "react-cookie-consent";
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function HomePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  const [tab, setTab] = useState(hash.substring(1) || Tabs.product);

  const selectTab = (tab: any) => {
    navigate(`/#${tab}`);
  }

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname, hash, tab]);

  useEffect(() => {
    if (hash && hash.length) {
      setTab(hash.substring(1));
    } else {
      setTab(Tabs.product);
    }
  }, [hash]);

  return (
    <div className="website">
      <CookieConsent
        location="bottom"
        buttonText="Einverstanden"
        cookieName="theRealInsightCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Diese Webseite nutzt Cookies zur Verbesserung der Nutzung.{" "}
        <span style={{ fontSize: "10px" }}></span>
      </CookieConsent>
      <div className='wave'></div>
      <div className='wave'></div>
      <div className='wave'></div>
      <WebsiteHeader showSidePanel={true} showMapItem={true} showTabs={true} selectedTab={tab} selectTab={selectTab}></WebsiteHeader>
      <div className='body'>
        <div className="content">
          {(() => {
            switch (tab) {
              case Tabs.security: return <SecurityPanel></SecurityPanel>;
              case Tabs.company: return <CompanyPanel></CompanyPanel>;
              case Tabs.product: return <ProductPanel></ProductPanel>;
            }
          })()}
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}
