import '../App.sass';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AssetList from '../components/AssetList';
import { useAdministrationAPI } from '../AdministrationAPI';
import { ActionButton } from '@fluentui/react';
import { OperatingState } from '@the-real-insight/tri-model';

interface Properties {
    processError: (error: any) => void;
}

export default function OperationsDashboard(properties: Properties) {
    const { t } = useTranslation();
    const { getLatestOperationalState: getLatestOperatingState } = useAdministrationAPI();
    const [operatingStates, setOperatingStates] = useState({}) as any;
    const [nextRefresh, setNextRefresh] = useState(0);
    const [refreshing, setRefreshing] = useState(false);

    const fetch = async () => {
        setRefreshing(true);

        try {
            const result = await getLatestOperatingState();

            console.log('=====================> Latest op state', result);

            setOperatingStates(result.reduce((states: any, asset: any) => {
                if (asset.state === OperatingState.warning) {
                    states.critical++;
                } else if (asset.state === OperatingState.warning) {
                    states.warning++;
                } else if (asset.state === OperatingState.normal) {
                    states.normal++;
                } else if (asset.state === OperatingState.maintenance) {
                    states.maintenance++;
                }

                return states;
            }, { total: result.length, normal: 0, warning: 0, critical: 0, maintenance: 0, decommisioned: 0 }));
        } catch (error) {
            console.log(error);
        } finally {
            setRefreshing(false);
            setNextRefresh(60);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const newNextRefresh = nextRefresh - 5;

            if (newNextRefresh <= 0) {
                fetch();
            } else {
                setNextRefresh(newNextRefresh);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [nextRefresh]);

    return <><div>
        <div className='displayFlex alignItemsCenter justifyContentEnd gapS'>
            <div className='textXS'>{refreshing ? 'Refreshing ...' : `Next refresh in ${nextRefresh} seconds.`}</div>
            <ActionButton disabled={refreshing} iconProps={{ iconName: 'Refresh' }}
                onClick={() => {
                    fetch();
                }}>
            </ActionButton>
        </div>
        <div className='marginTopM displayFlex justifyContentCenter'>
            <div className='displayFlex gapL'>
                <div className='width200 displayFlex flexDirectionColumn alignItemsCenter'>
                    <div><span className='text4XL fontWeightBold colorRed'>{operatingStates.critical ? 100 * operatingStates.critical / operatingStates.total : '-'}</span><span className='textXL fontWeightBold colorRed'>%</span></div>
                    <div><span className='textM colorRed'>{operatingStates.critical || '-'} Device(s) with critical issues</span></div>
                </div>
                <div className='width200 displayFlex flexDirectionColumn alignItemsCenter'>
                    <div><span className='text4XL fontWeightBold colorAmber'>{operatingStates.warning ? 100 * operatingStates.warning / operatingStates.total : '-'}</span><span className='textXL fontWeightBold colorAmber'>%</span></div>
                    <div><span className='textM colorAmber'>{operatingStates.warning || '-'} Device(s) with warnings</span></div>
                </div>
                <div className='width200 displayFlex flexDirectionColumn alignItemsCenter'>
                    <div><span className='text4XL fontWeightBold colorGreen'>{operatingStates.normal ? 100 * operatingStates.normal / operatingStates.total : '-'}</span><span className='textXL fontWeightBold colorGreen'>%</span></div>
                    <div><span className='textM colorGreen'>{operatingStates.normal || '-'} Device(s) normal</span></div>
                </div>
                <div className='width200 displayFlex flexDirectionColumn alignItemsCenter'>
                    <div><span className='text4XL fontWeightBold colorPurple'>{operatingStates.decomissioned ? 100 * operatingStates.decomissioned / operatingStates.total : '-'}</span><span className='textXL fontWeightBold colorPurple'>%</span></div>
                    <div><span className='textM colorPurple'>{operatingStates.decommisioned || '-'} Device(s) decommissioned</span></div>
                </div>
                <div className='width200 displayFlex flexDirectionColumn alignItemsCenter'>
                    <div><span className='text4XL fontWeightBold colorBlue'>{operatingStates.maintenance ? 100 * operatingStates.maintenance / operatingStates.total : '-'}</span><span className='textXL fontWeightBold colorBlue'>%</span></div>
                    <div><span className='textM colorBlue'>{operatingStates.maintenance || '-'} Device(s) in maintenance</span></div>
                </div>
            </div>
        </div>
        <div className="marginTopXXXL displayFlex gapL">
            <AssetList></AssetList>
        </div>
    </div>
    </>;
}