import Header from "./Header";
import { Pivot, PivotItem } from "@fluentui/react";
import UserManagementPanel from "./settings/UserManagementPanel";
import UserProfilePanel from "./settings/UserProfilePanel";
import SecurityPanel from "./settings/SecurityPanel";
import BillingPanel from "./settings/BillingPanel";
import CompanyPanel from "./settings/CompanyPanel";
import { useTranslation } from "react-i18next";
import APIPanel from "./settings/APIPanel";
import { useAtom } from "jotai";
import { authenticationAtom } from './State';
import { useLocation } from "react-router-dom";
import { useMemo, useState } from "react";
import { MessageBox, useMessagBox } from "./components/MessageBox";
import DataManagementPanel from "./settings/DataManagementPanel";
import ProductManagementPanel from "./settings/ProductManagementPanel";
import AlertManagementPanel from "./settings/AlertManagementPanel";
import OperationsDashboard from "./dashboards/OperationsDashoard";
import ProjectPanel from "./settings/ProjectPanel";
import SideBar, { SideBarItem } from "./components/SideBar";
import ContentPanel from "./components/ContentPanel";
import TelemetryPanel from "./settings/TelemetryPanel";
import GatewayPanel from "./administration/GatewayPanel";
import ProcessManagementPanel from "./settings/ProcessPanel";

export default function SettingsPage() {
    const { t } = useTranslation();
    const [authentication] = useAtom(authenticationAtom) as any;
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const [selectedPanel, setSelectedPanel] = useState(searchParams.get("panel") || 'userProfile');
    const { processError, messageBox } = useMessagBox();

    const sideBarItems: SideBarItem[] = [
        { key: 'userProfile', text: t('settingsPanel.userProfile'), iconName: "UserOptional" },
        { key: 'securityProfile', text: t('settingsPanel.securityProfile'), iconName: "PageLock" },
        { key: 'companyProfile', text: t('settingsPanel.companyProfile'), iconName: "CompanyDirectory" },
        { key: 'teamManagement', text: t('settingsPanel.teamManagement'), iconName: "People" },
        { key: 'billing', text: t('settingsPanel.billing'), iconName: "Money" },
        { key: 'api', text: t('settingsPanel.api'), iconName: "AzureAPIManagement" },
        { key: 'projects', text: t('settingsPanel.projects'), iconName: "ProjectCollection" },
        { key: 'productManagement', text: t('settingsPanel.productManagement'), iconName: "Product" },
        { key: 'telemetry', text: t('settingsPanel.telemetry'), iconName: "InternetSharing" },
        { key: 'alerts', text: t('settingsPanel.alerts'), iconName: "AlertSettings" },
        { key: 'operationalState', text: t('settingsPanel.operatingState'), iconName: "SyncStatus" },
        { key: 'processManagement', text: t('settingsPanel.processManagement'), iconName: "CalendarSettings" },
        { key: 'dataManagement', text: t('settingsPanel.dataManagement'), iconName: "Globe" },
    ];

    const setParams = (key: string) => {
        console.log('Value >>>', key);

        const url = new URL(window.location.href);

        url.searchParams.set('panel', key);
        window.history.replaceState(null, '', url.toString());

        setSelectedPanel(key);
    }

    return <div className="settingsPage positionRelative">
        <Header showHome title={t('settingsPanel.settings')}></Header>
        <div className="bodyPanel">
            <SideBar items={sideBarItems} setItem={setParams}></SideBar>
            <ContentPanel>
                <MessageBox messageBox={messageBox} ></MessageBox>
                {authentication && authentication.roles && authentication.roles.indexOf('administrator') >= 0
                    ?
                    <div>
                        {(() => {
                            switch (selectedPanel) {
                                case 'userProfile': return <UserProfilePanel processError={processError}></UserProfilePanel>;
                                case 'securityProfile': return <SecurityPanel processError={processError}></SecurityPanel>;
                                case 'companyProfile': return <CompanyPanel processError={processError}></CompanyPanel>;
                                case 'teamManagement': return <UserManagementPanel processError={processError}></UserManagementPanel>;
                                case 'billing': return <BillingPanel processError={processError}></BillingPanel>;
                                case 'api': return <APIPanel processError={processError}></APIPanel>;
                                case 'projects': return <ProjectPanel processError={processError}></ProjectPanel>;
                                case 'productManagement': return <ProductManagementPanel processError={processError}></ProductManagementPanel>;
                                case 'telemetry': return <GatewayPanel processError={processError}></GatewayPanel>;
                                case 'alerts': return <AlertManagementPanel processError={processError}></AlertManagementPanel>;
                                case 'operationalState': return <OperationsDashboard processError={processError}></OperationsDashboard>;
                                case 'dataManagement': return <DataManagementPanel processError={processError}></DataManagementPanel>;
                                case 'processManagement': return <ProcessManagementPanel processError={processError}></ProcessManagementPanel>;
                            }
                        })()}
                    </div>
                    :
                    <>
                        <Pivot aria-label="">
                            <PivotItem id="userProfile" headerText={t('settingsPanel.userProfile')} itemIcon="UserOptional">
                                <div className="paddingM">
                                    <UserProfilePanel processError={processError}></UserProfilePanel>
                                </div>
                            </PivotItem>
                            <PivotItem id="securityProfile" headerText={t('settingsPanel.securityProfile')}>
                                <div className="paddingM">
                                    <SecurityPanel processError={processError}></SecurityPanel>
                                </div>
                            </PivotItem>
                        </Pivot>
                    </>
                }
            </ContentPanel>
        </div>
    </div>;
}